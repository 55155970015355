function fetchStateControllerName (element) {
  let controllerName = element.dataset.stateController
  if (controllerName === undefined || controllerName === null) {
    // This element didn't have the state controller set.
    // Search up the dom for an element that does.
    const parentWithStateController = element.closest('[data-state-controller]')
    if (parentWithStateController) {
      controllerName = parentWithStateController.dataset.stateController
    }
  }
  return controllerName
}

// Find out what state controller the element should be triggering updates on.
function fetchStateController (element) {
  global.Cog.stateControllers ||= {}
  return global.Cog.stateControllers[fetchStateControllerName(element)]
}

// Find the value that should be applied with a state change for this element.
// It can be either a data-state-value key, or if that isn't available the
// value of the field.
function fetchStateValue (element) {
  let value
  if (element.dataset.stateValue !== undefined) {
    value = element.dataset.stateValue
  } else {
    value = element.value
  }
  return value
}

// Update state from a key and value attributes.
// Use data-state-value if available, otherwise use value.
export function handleKeyValueStateUpdate (event, element) {
  const stateController = fetchStateController(element)
  const stateKey = element.dataset.stateKey
  const stateValue = fetchStateValue(element)

  if (stateController === undefined || stateController === null) {
    return console.error('Could not find a state controller to apply state change.', element)
  }

  const hash = {}
  hash[stateKey] = stateValue

  stateController.updateValues(hash)
}

// Update state from JSON attribute called data-state
export function handleJsonStateUpdate (event, element) {
  const stateController = fetchStateController(element)
  const state = JSON.parse(element.dataset.state)

  if (stateController === undefined || stateController === null) {
    return console.error('Could not find a state controller to apply state change.', element)
  }

  stateController.updateValues(state)
}

// Update state when a user checks or unchecks a checkbox.
export function handleCheckboxStateUpdate (event, element) {
  const stateController = fetchStateController(element)

  if (stateController === undefined || stateController === null) {
    return console.error('Could not find a state controller to apply state change.', element)
  }

  const stateKey = element.dataset.stateKey
  const stateControllerName = stateController.nameValue

  const checkboxQuery = `[data-state-controller="${stateControllerName}"] ` +
    `input[type='checkbox'][data-state-key="${stateKey}"]:checked`

  const checkedCheckboxes = document.querySelectorAll(checkboxQuery)
  const checkedValues = [...checkedCheckboxes].map((checkbox) => fetchStateValue(checkbox)).sort()

  const hash = {}

  // Single-value checkboxes don't need to be wrapped in quotes
  // This makes sure checkboxes that influence a single value like the tags toggle still work.
  hash[stateKey] = checkedValues.length === 1 ? checkedValues[0] : checkedValues

  stateController.updateValues(hash)
}

// check is the element is enabled or NOT disabled by attribute or class
export function isEnabled (element) {
  return element && !element.disabled && !element.classList.contains('disabled')
}
