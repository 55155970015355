import { merge, union, omit } from 'lodash'

// Create a stacked bar chart on the given HTML element
class StackedBarChart {
  svgContainer
  colors = [
    '#0033A0', '#0097A7', '#9C27B0', '#9FA8DA', '#C2185B', '#FFAB00',
    '#26C6DA', '#7E57C2', '#388D41', '#42A5F5', '#BA68C8', '#FF5252',
    '#81C784', '#F06292', '#DE463D', '#FF7043'
  ]

  colorScale
  options
  dataHash
  subgroups = []
  labels = []
  stackedBarChartLegend
  filteredItems = []
  currentDataHash = {}
  currentDataHashKey = []

  defaultOptions = {
    // put default options here
    groupLabel: 'label',
    drillDownEnabled: false,
    breadCrumbText: '',
    tooltipText: 'exposure in',
    subgroupUnits: '%',
    maxDataHeight: 100,
    width: 1100,
    maxBarWidth: 100,
    spaceBetweenBars: 24,
    height: 420,
    margin: {
      top: 15,
      // Have to leave a space for x_axis end point.
      right: 5,
      bottom: 60,
      // Have to leave a space for y_axis width, since y_axis is
      // 'text-anchor: end', so main chart is required to shift by y_axis width.
      left: 50
    },
    legendPadding: {
      left: 10,
      right: 10,
      height: 25
    }
  }

  /**
   * Either add or remove the given item from the filtered items list
   * @param {String} item - The key for the item that should be filtered or unfiltered
   */
  toggleFilteredItem (item) {
    const _this = this

    // If the item is not being filtered then filter it
    if (_this.filteredItems.indexOf(item) === -1) {
      _this.filteredItems.push(item)
    } else {
      // otherwise remove it
      _this.filteredItems.splice(_this.filteredItems.indexOf(item), 1)
    }
  }

  // Discern the labels (inner buckets for each bar)
  setupSubgroups () {
    const _this = this
    _this.subgroups = []

    const rawData = _this.currentDataHash.dataPoints

    rawData.forEach(function (dataHash) {
      const subgroupsForDataHash = Object.keys(dataHash).filter(key => key !== _this.options.groupLabel)

      _this.subgroups = union(_this.subgroups, subgroupsForDataHash)
    })
  }

  /**
   * Check if the dataset for key is in the dataHash and redraw the chart and legend
   * @param {String} key - the key to the data hash for the graph
   */
  updateCurrentDataHash (key) {
    const _this = this
    if (!(key in _this.dataHash)) { return }
    _this.currentDataHashKey = key
    _this.currentDataHash = _this.dataHash[key]

    _this.setupSubgroups()
    _this.draw()
  }

  // Main method to add bread crumbs to SBC
  drawBreadCrumbs () {
    const _this = this

    const breadCrumbContainer = _this.addBreadCrumbContainer()

    _this.addBreadCrumbIntro(breadCrumbContainer)

    _this.addBreadCrumbs(breadCrumbContainer)
  }

  /**  Add bread crumb container div
  * @return {Selection} Return container div
  * */
  addBreadCrumbContainer () {
    const _this = this

    return _this.svgContainer
      .append('div')
      .attr('class', 'bread-crumb-container flex mb-2')
  }

  /**  Add text that is displayed before the bread crumb items
  * @param {Selection} breadCrumbContainer Container div to append to
  * */
  addBreadCrumbIntro (breadCrumbContainer) {
    const _this = this

    breadCrumbContainer
      .append('div')
      .attr('class', 'flex flex-row')
      .text(_this.options.breadCrumbText)
  }

  /**  Find bread crumbs and add with formatting
  * @param {Selection} breadCrumbContainer Container div to append to
  * */
  addBreadCrumbs (breadCrumbContainer) {
    const _this = this
    // Array of data sets used to construst bread crumb hierarchy
    const keyArray = [_this.currentDataHashKey]

    // Loop through dataHash using hash key to find parent hash key, add to bread crumbs
    while (_this.dataHash[keyArray[0]].parent != null) {
      keyArray.unshift(_this.dataHash[keyArray[0]].parent)
    }

    breadCrumbContainer.selectAll('.bread-crumb-container')
      .data(keyArray)
      .enter()
      .append('div')
      .attr('class', function (d) {
        // Formatting condition for first keyArray element (d), do not add extra left space
        return (keyArray.length > 1 && keyArray.indexOf(d) > 0 ? 'flex flex-row ml-2' : 'flex flex-row')
      })
      .text(function (d) {
        // keyArray with one bread crumb should not display '>'
        return (keyArray.length > 1 && keyArray.indexOf(d) > 0 ? '>' : '')
      })
      .append('div')
      .attr('class', 'flex flex-row ml-2 label cursor-pointer link bold')
      .text(function (d) {
        return d
      })
      .on('click', function (d) {
        _this.updateCurrentDataHash(d)
      })
  }

  /**
   * Generate the tooltip for the given data
   * @param {Object} d - The data from the rectangle which was hovered over
   * @param {Object} gData - The data from the parent g which contains the key for the hovered rectangle
   * @returns {String} the HTML string for the tooltip display
   */
  sectionTooltip (d, gData) {
    const _this = this
    const rawValue = d.data[gData.key]
    const calculatedValue = Math.round(rawValue * 10) / 10
    const percentageText = rawValue >= 0.1 || rawValue === 0 ? calculatedValue : '< 0.1'

    return `
      <div class="bold">${gData.key}</div>
      <div>${percentageText}${_this.options.subgroupUnits}
           ${_this.options.tooltipText} ${d.data[_this.options.groupLabel] || 'Undefined'}</div>
    `
  }

  /**
   * Set the max data height based on the current data hash and current subgroups
   * @param {Array} dataPoints the data points to find the max of
   */
  maxDataHeight (dataPoints) {
    const _this = this
    let maxValue = 0

    dataPoints.forEach(function (dataItem) {
      let groupTotal = 0
      _this.subgroups.forEach(function (subgroup) {
        groupTotal += dataItem[subgroup] || 0
      })

      if (groupTotal > maxValue) { maxValue = groupTotal }
    })

    // Dont go over the _this.options.maxDataHeight option
    if (maxValue > _this.options.maxDataHeight) { maxValue = _this.options.maxDataHeight }

    // Round to the nearest ten
    return Math.ceil(maxValue / 10) * 10
  }

  // Setup the data that should be shown based on the filtered items
  setFilteredData () {
    const _this = this

    const filteredData = []
    const rawData = _this.currentDataHash.dataPoints
    _this.labels = []

    rawData.forEach(function (dataItem) {
      let groupTotal = 0
      _this.subgroups.forEach(function (subgroup) {
        groupTotal += dataItem[subgroup] || 0
      })
      // Only display data that is not filtered by the legend and has total bar value > 0
      if (groupTotal > 0) {
        filteredData.push(omit(dataItem, _this.filteredItems))
        // Only add x axis label if the bar has data
        _this.labels.push(dataItem[_this.options.groupLabel])
      }
    })
    return filteredData
  }

  // Draw the graph on the selector HTML element
  drawStackedBarChart () {
    const _this = this

    const filteredData = this.setFilteredData()

    // Setup the subgroups that should be shown based on the non filtered items
    const filteredSubgroups = _this.subgroups.filter(subgroup => !_this.filteredItems.includes(subgroup))

    _this.svgContainer.selectAll('*').remove()

    if (_this.options.drillDownEnabled) _this.drawBreadCrumbs()

    const svg = _this.svgContainer
      .append('div')
      .attr('class', 'graph-legend-container flex flex-center flex-column grey-box')
      .append('svg')
      .attr('width', _this.options.width + _this.options.margin.left + _this.options.margin.right)
      .attr('height', _this.options.height + _this.options.margin.top + _this.options.margin.bottom)
      .attr('class', 'mt-6')
      .append('g')
      .attr('transform', `translate(${_this.options.margin.left}, ${_this.options.margin.top})`)

    const stackedData = d3.stack() // eslint-disable-line no-undef
      .keys(filteredSubgroups)(filteredData)

    // If there is only one bar spacing should be 0
    const currentSpacing = filteredData.length > 1 ? _this.options.spaceBetweenBars : 0

    // Reference: https://observablehq.com/@hongtaoh/how-to-understand-d3s-paddinginner
    // Padding and bar calculations are unecessarily complicated from the d3.js library
    // This implementation allows the developer to specify the desired bar width and spacing
    // If the bars will not fit on the graph they will be scaled down
    const padding = currentSpacing / (currentSpacing + _this.options.maxBarWidth)
    const dataWidth = Math.min((filteredData.length - padding) * (currentSpacing + _this.options.maxBarWidth), _this.options.width)

    const xBand = d3.scaleBand() // eslint-disable-line no-undef
      .domain(_this.labels)
      .range([(_this.options.width / 2) - (dataWidth / 2),
        (_this.options.width / 2) + (dataWidth / 2)])
      .paddingInner([padding])

    const yBand = d3.scaleLinear() // eslint-disable-line no-undef
      .domain([0, _this.maxDataHeight(filteredData)])
      .range([_this.options.height, 0])

    _this.drawXAxis(svg, xBand)

    _this.drawYAxis(svg, yBand)

    _this.drawBars(svg, xBand, yBand, stackedData)
  }

  /**
   * Draw the bars for the graph
   * @param {HTMLElement} svg - The element that the graph will be appended to
   * @param {ScaleBand} xBand - the x axis scale band
   * @param {ScaleBand} yBand - the y axis scale band
   * @param {Array<Hash>} stackedData - data for the graph
   */
  drawBars (svg, xBand, yBand, stackedData) {
    const _this = this

    // Show the bars
    /** Options explained:
     * data(stackedData) -> set the data for the graph
     * data(function(d) { return d }) -> return data in a shorthand form
     * attr(x) -> set the x axis to group label
     * attr(y) -> set the y axis to the data hieght
     * attr(height) -> set the height of the bar by doing a final - initial calculation
     * attr(width) -> set the width according to the xBand options
     */
    svg.append('g')
      .selectAll('g')
      .data(stackedData)
      .enter()
      .append('g')
      .on('click', function (d) { _this.updateCurrentDataHash(d.key) })
      .attr('fill', function (d) { return _this.colorScale(d.key) })
      .attr('class', function (d) { return d.key in _this.dataHash ? 'cursor-pointer' : '' })
      .selectAll('rect')
      .data(function (d) { return d })
      .enter()
      .append('path')
      .attr('class', 'no-outline')
      .attr('stroke', 'white')
      .attr('stroke-width', 1)
      .attr('d', d => _this.rectPath(d, xBand, yBand, stackedData))
      .attr('data-tippy-content', function (d) {
        // Need to get the parentNode "g" to get the data.key for the hovered content
        const gData = d3.select(this.parentNode).datum() // eslint-disable-line no-undef
        return _this.sectionTooltip(d, gData)
      })
      .attr('data-controller', 'tippy-tooltip')
      .attr('data-tippy-tooltip-target', 'trigger')
      .attr('data-tippy-tooltip-duration-value', '[300, 0]')
      .attr('data-tippy-tooltip-interactive-value', 'false')
      .attr('data-tippy-tooltip-animation-value', 'false')
      .attr('data-tippy-tooltip-arrow-value', 'false')
      .attr('data-tippy-tooltip-follow-cursor-value', 'true')
      .attr('data-tippy-tooltip-placement-value', 'top')
      .append('div')
      .attr('data-tippy-tooltip-target', 'content')
  }

  /**
   * Draw the path for the subgroup rectangles with rounded corners for the top subgroup bar
   * @param {Object} dataPoint the current dataPoint for us to draw the subgroup bar for
   * @param {Object} xBand the xBand for the current bar
   * @param {Object} yBand the yBand for the current subgroup bar
   * @returns {String} the string path for the drawing of the bars
   */
  rectPath (dataPoint, xBand, yBand) {
    const _this = this
    let path = null
    let totalDataPoints = 0
    const roundedCornerValue = 5

    // Determine the top of the bars by summing the total amount of data
    _this.subgroups.forEach(function (subgroup) {
      totalDataPoints += dataPoint.data[subgroup] || 0
    })

    if (dataPoint[1] === dataPoint[0]) {
    // If the data points are the same we can not draw that
    // bar because the value for that subgroup would be 0
      path = ''
    } else if (dataPoint[1] === totalDataPoints) {
      /**
       * If the data point finish value is equal to the total then we round the edges
       * of the path to give it a fancy look.
       *
       * Note:
       *  M - Starting location
       *  h - horizontal line
       *  q - quadratic bezier line
       *  v - verticle line
       *  z - return to start point
       *  The positive direction for the x values is to the right and the
       *  positive direction for the y values in down
       */
      path = `
        M${xBand(dataPoint.data[_this.options.groupLabel])},${yBand(dataPoint[1])}
        h${xBand.bandwidth() - roundedCornerValue}
        q${roundedCornerValue},${0},${roundedCornerValue},${roundedCornerValue}
        v${yBand(dataPoint[0]) - yBand(dataPoint[1]) - roundedCornerValue}
        h${-xBand.bandwidth()}
        v${-1 * (yBand(dataPoint[0]) - yBand(dataPoint[1]) - roundedCornerValue)}
        q${0},${-roundedCornerValue},${roundedCornerValue},${-roundedCornerValue}
        z
      `
    } else {
      // Otherwise we can just draw a standard bar (rectangle)
      path = `
        M${xBand(dataPoint.data[_this.options.groupLabel])}, ${yBand(dataPoint[1])}
        h${xBand.bandwidth()}
        v${yBand(dataPoint[0]) - yBand(dataPoint[1])}
        h${-xBand.bandwidth()}
        v${-1 * (yBand(dataPoint[0]) - yBand(dataPoint[1]))}
        z
      `
    }

    return path
  }

  /**
   * Draw the x axis labels
   * @param {HTMLElement} svg - The element that the graph will be appended to
   * @param {ScaleBand} xBand - the x axis scale band
   */
  drawXAxis (svg, xBand) {
    const _this = this

    svg.append('g')
      .attr('transform', `translate(0, ${_this.options.height})`)
      .call(d3.axisBottom(xBand).tickSize(0)) // eslint-disable-line no-undef
      .selectAll('text')
      .attr('class', 'bold font-small')
      .attr('transform', (d, i) => `rotate(-45) translate(${-_this.options.legendPadding.height}, 10)`)
      .filter((t) => t === null)
      .text('Undefined')

    // Hide x-axis line from bottom of graph
    svg.selectAll('.domain').attr('stroke-width', 0)
  }

  /**
   * Draw the y axis labels
   * @param {HTMLElement} svg - The element that the graph will be appended to
   * @param {ScaleBand} yBand - the y axis scale band
   */
  drawYAxis (svg, yBand) {
    const _this = this
    svg.append('g')
      .call(d3.axisLeft(yBand) // eslint-disable-line no-undef
        .ticks(6)
        .tickFormat(function (d) { return d + _this.options.subgroupUnits })
        .tickSize(0)
        .tickSizeInner(-this.options.width))
      .call(g => g.select('.domain').attr('stroke-width', 0)) // Hide y-axis line from left side of graph
      .selectAll('text').attr('class', 'bold font-small')
      .attr('transform', (d, i) => 'translate(-10, 0)')

    svg.selectAll('.tick line').attr('opacity', 0.2)
  }

  // Draw the graph including bars, labels, etc
  draw () {
    const _this = this

    _this.drawStackedBarChart()

    const graphLegendContainer = _this.svgContainer.selectAll('.graph-legend-container')
    _this.stackedBarChartLegend.addLegend(graphLegendContainer)

    _this.stackedBarChartLegend.drawLegend()
  };

  /**
   * Default constructor for the stacked bar chart
   * @param {String} selector - The selector for the target html element (#targetID)
   * @param {String} rootDataHashKey - the key to the root data hash for the graph
   * @param {Hash} dataHash - The data for the chart, name to data for that hash
   * @param {Hash} optionsOverride - A hash of option overrides to target specific visual options
   */
  constructor (selector, rootDataHashKey, dataHash, optionsOverride) {
    this.dataHash = dataHash
    this.currentDataHashKey = rootDataHashKey
    this.currentDataHash = this.dataHash[rootDataHashKey]
    this.colorScale = d3.scaleOrdinal(this.colors) // eslint-disable-line no-undef
    this.svgContainer = d3.select(selector) // eslint-disable-line no-undef
    this.stackedBarChartLegend = new Cog.StackedBarChartLegend(this)
    this.options = merge(this.defaultOptions, optionsOverride)
    this.setupSubgroups()
  }
}

global.Cog.StackedBarChart ||= StackedBarChart
