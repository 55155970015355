import BaseController from '../base_controller'

export default class extends BaseController {
  static targets = ['headerRow', 'firstRow', 'lastRow', 'headerRowEqualToFirstRowErrorMessage', 'headerRowEqualToLastRowErrorMessage',
    'headerRowBetweenFirstAndLastRowErrorMessage']

  static classes = ['hidden']

  // validate the first and last data row, considering a valid attribute passed in by an event
  // @param [Object] Event object that contains the valid attribute from input-validation:input-updated
  validate ({ detail: { valid } }) {
    const validHeaderRowValue = this.validateConditionsAndToggleErrorMessages()
    const allValidationsPassed = valid && validHeaderRowValue

    this.toggleValidAttribute(allValidationsPassed)
  }

  // Validate the control without consideration of the valid param that might be passed in by an event
  validateWithoutPreviousValidation () {
    const validHeaderRowValue = this.validateConditionsAndToggleErrorMessages()

    this.toggleValidAttribute(validHeaderRowValue)
  }

  // Toggle the valid attribute
  // @param valid [Boolean] Is the first and last data rows valid compared to each other
  toggleValidAttribute (valid) {
    if (valid) {
      this.headerRowTarget.setAttribute('valid', 'true')
    } else {
      this.headerRowTarget.setAttribute('valid', 'false')
    }
  }

  // validate each condition for the header row and toggle appropriate error messages
  // @returns [Boolean] indicates if the header row is valid
  validateConditionsAndToggleErrorMessages () {
    const headerRowEqualToFirstRow = this.headerRowEqualToFirstRow()
    const headerRowEqualToLastRow = this.headerRowEqualToLastRow()
    const headerRowBetweenFirstAndLastRow = this.headerRowBetweenFirstAndLastRow()

    this.toggleEqualToFirstRowErrorMessage(!headerRowEqualToFirstRow)
    this.toggleEqualToLastRowErrorMessage(!headerRowEqualToLastRow)
    this.toggleBetweenFirstAndLastRowErrorMessage(!headerRowBetweenFirstAndLastRow)

    // Return false if any of the errors are true
    if (headerRowEqualToFirstRow || headerRowEqualToLastRow || headerRowBetweenFirstAndLastRow) {
      return false
    } else {
      return true
    }
  }

  // Check if the header row is equal to the first row
  // @return [Boolean] true if header row is equal to the first row
  headerRowEqualToFirstRow () {
    return this.headerRowInt() === this.firstRowInt()
  }

  // Check if the header row is equal to the last row
  // @return [Boolean] true if header row is equal to the last row
  headerRowEqualToLastRow () {
    return this.headerRowInt() === this.lastRowInt()
  }

  // Check if the header row is between the first and last row
  // @return [Boolean] true if header row is between the first and last row
  headerRowBetweenFirstAndLastRow () {
    return this.headerRowInt() > this.firstRowInt() && this.headerRowInt() < this.lastRowInt()
  }

  // Convert the header row value to an int. It is a string by default
  // @return [Integer] The header row value
  headerRowInt () {
    return parseInt(this.headerRowTarget.value)
  }

  // Convert the first row value to an int. It is a string by default
  // @return [Integer] The first row value
  firstRowInt () {
    return parseInt(this.firstRowTarget.value)
  }

  // Convert the last row value to an int. It is a string by default
  // @return [Integer] The last row value
  lastRowInt () {
    return parseInt(this.lastRowTarget.value)
  }

  // Toggle error message for if header row is equal to the first row
  // @param valid [Boolean] Indicates if the header row is equal to the first row
  toggleEqualToFirstRowErrorMessage (valid) {
    this.headerRowEqualToFirstRowErrorMessageTarget.classList.toggle(this.hiddenClass, valid)
  }

  // Toggle error message for if header row is equal to the last row
  // @param valid [Boolean] Indicates if the header row is equal to the last row
  toggleEqualToLastRowErrorMessage (valid) {
    this.headerRowEqualToLastRowErrorMessageTarget.classList.toggle(this.hiddenClass, valid)
  }

  // Toggle error message for if header row is between the first and last row
  // @param valid [Boolean] Indicates if the header row is between the first and last row
  toggleBetweenFirstAndLastRowErrorMessage (valid) {
    this.headerRowBetweenFirstAndLastRowErrorMessageTarget.classList.toggle(this.hiddenClass, valid)
  }
}
