import BaseController from './base_controller'

// Controll the videos with a tab framework
export default class extends BaseController {
  static targets = ['iframe']

  /**
  * Stop the video
  * @param {HTML} iframe - iframe element with the video
  */
  stopVideo (iframe) {
    // Skip it, if we cannot find the iframe
    if (iframe === undefined) { return }

    const video = iframe.querySelector('video')
    // If we can access the video in the iframe, then pause the video.
    // NOTE: Most of cases we cannot access <video> under the <iframe>, it is one of the hard rules of browser security
    // A frame/iframe from an external page cannot, under any circumstances effect the content of the parent document
    // unless they are from the same domain and port number.
    if (video) {
      video.pause()
    // Otherwise, stop the video by reassigning the video source url to the iframe or video tag, in case of native
    // videos embedded.
    } else {
      const src = iframe.src
      // Some browsers cannot overwrite iframe.src with identical value, so change to '', then assign the src value back.
      iframe.src = ''
      iframe.src = src
    }
  }

  /**
  * Stop the video
  * @return {String} a data attribute of video id param
  */
  videoIdAttributeName () {
    return 'data-' + this.targets.identifier + '-video-id-param'
  }

  /**
  * Stop the video
  * @param {Event} event - a received event
  */
  stopVideoExceptCurrent (event) {
    const clickedVideoId = event.params.videoId

    this.iframeTargets.forEach(target => {
      const videoId = target.getAttribute(this.videoIdAttributeName())

      if (videoId.toString() !== clickedVideoId.toString()) {
        this.stopVideo(target)
      }
    })
  }
}
