import BaseController from '../base_controller'

export default class extends BaseController {
  static values = {
    data: String
  }

  connect () {
    super.connect()
    this.renderCompanySankey()
  }

  disconnect () {
    super.disconnect()
  }

  // render CompanySankey based on passed in data
  renderCompanySankey () {
    const data = JSON.parse(this.dataValue)
    Cog.companySankey('#company_sankey_container', data, {})
  }
}
