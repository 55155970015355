/**
 * @param {Array.<String>} seriesIds of traces to update
 * @param {HTML} stockChart - currently connected stock chart
 * @return {Array.<Integer>} traceIndexes - indexes of traces to update visibility
 */
export function filterTraceIndexByUpdatemenuSelection(seriesIds, stockChart) {
  let traceIndexes = []
  const stockChartData = stockChart.data,
        traceGroup = stockChart.querySelector('.updatemenu-button.plotly-selected .updatemenu-item-rect')
                               .dataset.tracegroup

  stockChartData.forEach(function(value, index) {
    const currTraceGroup = value.trace_group,
          seriesId = value.series_id

    // get index of trace with the current selected
    // trace groups and series_id(s)
    // if no trace group present, we only filter by series ids
    const matchesTraceGroup = (traceGroup == undefined) || (currTraceGroup == traceGroup)
    if (matchesTraceGroup && seriesIds.includes(seriesId)) {
      traceIndexes.push(index)
    }
  })

  return traceIndexes
}
