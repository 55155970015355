import BaseController from './base_controller'

// Use this class to toggle an element's state based on another element
export default class extends BaseController {
  // Anchor: main target you want to base the state on
  // Sibling: state should match the anchor
  // Opposite: state should be the opposite of the anchor
  static targets = ['anchor', 'opposite', 'sibling']
  static classes = ['hidden']

  connect () {
    super.connect()
    const config = { childList: true }

    const callback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'childList') {
          if (this.hasOppositeTarget) {
            this.toggleOppositeVisibility()
          }

          if (this.hasSiblingTarget) {
            this.toggleSiblingVisiblity()
          }
        }
      }
    }

    this.observer = new MutationObserver(callback)
    this.observer.observe(this.anchorTarget, config)
  }

  disconnect () {
    this.observer.disconnect()
  }

  // Have toggle element be the opposite of the anchor element
  // hidden anchor -> visible opposite
  // visible anchor -> hidden opposite
  toggleOppositeVisibility () {
    this.oppositeTarget.classList.toggle(this.hiddenClass, this.anchorTarget.childElementCount !== 0)
  }

  // Have sibling element state match the anchor element
  // hidden anchor -> hidden sibling
  // visible anchor -> visible sibling
  toggleSiblingVisiblity () {
    this.siblingTarget.classList.toggle(this.hiddenClass, this.anchorTarget.childElementCount === 0)
  }
}
