import { clamp } from 'lodash'
import BaseController from './base_controller'

const escapeKey = 'Escape'
const downKey = 'ArrowDown'
const upKey = 'ArrowUp'
const enterKey = 'Enter'
const selectionKeys = [downKey, upKey, enterKey]
export default class extends BaseController {
  static targets = ['result', 'resultsList']
  static classes = ['selected']

  connect () {
    super.connect()
    this.index = -1
  }

  close (event) {
    if (this.isEscapeKey(event) || this.isClickOutOfBounds(event)) {
      this.resultsListTarget.remove()
    }
  }

  selectResult (event) {
    if (!selectionKeys.includes(event.key)) { return }

    if (event.key === enterKey) { return this.resultTargets[this.index].click() }

    event.preventDefault()

    if (this.resultTargets[this.index]) { this.resultTargets[this.index].classList.remove(this.selectedClass) }

    if (event.key === upKey) {
      this.index--
    } else if (event.key === downKey) {
      this.index++
    }

    this.index = clamp(this.index, 0, this.resultTargets.length - 1)
    this.resultTargets[this.index].focus()
    this.resultTargets[this.index].classList.add(this.selectedClass)
  }

  preventScroll (event) {
    if (!selectionKeys.includes(event.key)) { return }

    event.preventDefault()
  }

  isEscapeKey = (event) => event.key === escapeKey

  isClickOutOfBounds = (event) => event.type === 'click' && !this.resultTargets.includes(event.target)
}
