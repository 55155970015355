import BaseController from './acuity_base_controller'

export default class extends BaseController {
  static values = {
    overlaySelector: String,
    defaultStyle: { type: Object, default: {} },
    updatedStyle: { type: Object, default: {} }
  }

  static classes = ['visible']

  toggleOverlayMenu () {
    const overlayMenuSelector = this.overlaySelectorValue
    const overlayMenu = document.querySelector(overlayMenuSelector)

    if (overlayMenu.classList.contains(this.visibleClass)) {
      this.updateOverlayStyle(overlayMenu, this.defaultStyleValue)
      overlayMenu.classList.remove(this.visibleClass)
    } else {
      this.updateOverlayStyle(overlayMenu, this.updatedStyleValue)
      overlayMenu.classList.add(this.visibleClass)
    }
  }

  /**
   * Read in an object of styles, and update the CSS style on the menu accordingly
   * @param {HtmlObject} element
   * @param {Object} styles
   */
  updateOverlayStyle (element, styles) {
    Object.keys(styles).forEach(function (style) {
      element.style[style] = styles[style]
    })
  }
}
