import BaseController from './base_controller'

export default class ResizeController extends BaseController {
  static targets = ['reference', 'resizeItem']
  static values = {
    updateAttribute: String,
    referenceAttribute: String
  }

  // Resizes targets' attribute values based on a reference's attribute value
  resizeFromReference () {
    const updateAttribute = this.updateAttributeValue
    const referenceValue = this.referenceTarget[this.referenceAttributeValue]

    this.resizeItemTargets.forEach(function (resizeItem) {
      resizeItem.setAttribute(updateAttribute, referenceValue)
    })
  }
}
