import BaseController from './acuity_base_controller'
import { fire, getUrlFromFields } from '../lib/form_events'
import * as Turbo from '@hotwired/turbo'

export default class extends BaseController {
  static values = {
    formSelector: String,
    fieldSelectors: Array
  }

  // Loops through the array of selectors
  // and clear the value to submit
  clearValues () {
    const formSelector = this.formSelectorValue
    this.fieldSelectorsValue.forEach(function (fieldSelector) {
      const selector = formSelector + ' ' + fieldSelector
      const hiddenField = document.querySelector(selector)

      if (hiddenField === undefined || hiddenField === null) { return }

      hiddenField.value = ''
    })

    const hiddenForm = document.querySelector(formSelector)
    const url = getUrlFromFields(hiddenForm.querySelectorAll('input'))
    Turbo.navigator.history.push(new URL(url))

    fire(hiddenForm, 'submit')
  }
}
