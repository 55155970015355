import BaseController from './base_controller'

// Validates and limit inputs that couldn't be caught by HTML5 validations
export default class extends BaseController {
  static targets = ['field']

  // Run validation on load in case there was an invalid value in the field
  // and the UI remembers the value after refresh
  fieldTargetConnected () {
    this.validateByPattern()
  }

  // Check if the value defined in the field matches the defined regex pattern
  validateByPattern () {
    if (!this.fieldTarget.hasAttribute('pattern')) {
      return
    }

    // don't run check if no regex pattern defined
    const regex = new RegExp(this.fieldTarget.getAttribute('pattern'))
    if (!regex) { return }

    // check if input value fits regex pattern, and mark
    // field valid accordingly
    const currValue = String(this.fieldTarget.value)
    const valid = currValue.match(regex) !== null
    this.fieldTarget.setAttribute('valid', String(valid))
  }

  /**
   * Prevent `.` from being entered in the input field
   * @param event {JsEvent}
   */
  preventDecimal (event) {
    if (event.key === '.') {
      event.preventDefault()
    }
  }
}
