import BaseController from './base_controller'

// Needed to call JS visuals from Turbo Stream
// since TurboStream prevents calling JS from <script> tags
// https://turbo.hotwired.dev/handbook/streams#but-what-about-running-javascript%3F
export default class TurboVisualStockChartController extends BaseController {
  static values = {
    jsonPath: String
  }

  // Should automatically load the corresponding visual once this controller is connected
  connect () {
    super.connect()
    this.renderStockChart()
  }

  disconnect () {
    super.disconnect()
  }

  // render stock chart based on saved jsonPath
  renderStockChart () {
    const jsonPath = this.jsonPathValue
    if (jsonPath.length > 0) {
      /* eslint-disable */
      const stockChart = new Cog.stockChart(document.getElementById(this.element.id), {
      /* eslint-enable */
        title: null,
        normalization: {
          enable: false
        },
        rolling_averages: {
          default: 'original',
          available_averages: []
        },
        range_selection: {
          enable: false
        },
        enable_range_slider: false,
        height: 200,
        margin: {
          l: 56,
          r: 42,
          b: 24,
          t: 8,
          pad: 4
        },
        show_legend: false,
        show_modebar: false,
        static_plot: true,
        enable_rescale_y: false,
        yaxis: {
          tickprefix: '$'
        }
      })

      stockChart.set_data(jsonPath)
    }
  }
}
