import BaseController from './base_controller'

export default class DropdownController extends BaseController {
  static targets = ['modal', 'button']

  globalClick (event) {
    if (this.buttonTarget.contains(event.target) || this.modalTarget.contains(event.target)) {
      return
    }
    this.close()
  }

  toggle () {
    if (this.modalTarget.classList.contains('hidden')) {
      this.open()
    } else {
      this.close()
    }
  }

  open () {
    this.blurButton()
    this.modalTarget.classList.remove('hidden')
    this.modalTarget.dispatchEvent(new CustomEvent('modalOpen'))
  }

  close () {
    this.blurButton()
    this.modalTarget.classList.add('hidden')
    this.modalTarget.dispatchEvent(new CustomEvent('modalClose'))
  }

  blurButton () {
    this.buttonTarget.blur()
  }
}
