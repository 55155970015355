// Checks whether the user is using a mobile device
// Reference: https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
// @return [Boolean]
export function isMobile () {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

// Checks if min-tablet size
// Ref: https://docs.microsoft.com/en-us/windows/uwp/design/layout/screen-sizes-and-breakpoints-for-responsive-design
// @return [Boolean]
export function isTabletScreen () {
  return window.outerWidth < 641
}

// Checks if current screen size is smaller than
// mobile-max-width (1024px)
// regardless of what device is used
// @return [Boolean]
export function isMobileMaxScreen () {
  return window.outerWidth <= 1024
}
