import BaseController from '../base_controller'
import { fire } from 'lib/compatibility'

/**
 * Controller for updating the company sankey based changes in related selection menus or dropdowns
 */
export default class extends BaseController {
  static values = {
    fieldSelector: String,
    formSelector: String,
    attribute: { type: String, default: '' }
  }

  /**
   * Update selected value in hidden form based on clicked level button
   * @param {Object} event - ie: click event
   */
  changeSelectedValueByAttribute (event) {
    let target = event.target

    // if event.target end up being something inside the button,
    // look for the closest parent element that has an action associated to it
    // since it's most likely that the other relevant data attributes will be also assigned there
    if (!target.dataset[this.attributeValue]) {
      target = target.closest('[data-action]')
    }

    const selectedValue = target.dataset[this.attributeValue]
    this.updateFieldValueAndSubmit(selectedValue)
  }

  /**
   * Update selected value in hidden form based on dropdown selection
   * @param {Object} event - ie: change event
   */
  changeSelectedValueByDropdown (event) {
    const selectedValue = event.target.value
    this.updateFieldValueAndSubmit(selectedValue)
  }

  /**
   * Update and submit value in hidden field based on selected value
   * @param {Integer} selectedValue
   */
  updateFieldValueAndSubmit (selectedValue) {
    const field = document.querySelector(this.fieldSelectorValue)

    // only update and submit if the values are different
    if (field.value !== selectedValue) {
      field.value = selectedValue
      this.submitForm()
    }
  }

  /**
   * Submit form for applying changes to the sankey
   */
  submitForm () {
    const form = document.querySelector(this.formSelectorValue)
    fire(form, 'submit')
  }
}
