import BaseController from './acuity_base_controller'

/**
 * this controller does NOT handle updating the icon since we re-render the whole table through turbo frames
 * this is focused on updating sort column/direction in filter form
 */
export default class AcuitySortController extends BaseController {
  static values = {
    filterFieldSelector: String,
    sortJson: String,
    filterControllerName: String
  }

  /** Toggle sort direction value based on sort column state
   * "null" (no sort direction) -> "desc"
   * "desc" -> "asc"
   * "asc" -> "desc"
   */
  change () {
    const sortData = JSON.parse(this.sortJsonValue)
    const sortKeys = Object.keys(sortData)

    // updates sort direction in JSON
    sortKeys.forEach(function (key) {
      const currValue = sortData[key]
      if (currValue == null || currValue === 'asc') {
        sortData[key] = 'desc'
      } else if (currValue === 'desc') {
        sortData[key] = 'asc'
      }
    })

    this.sortJsonValue = JSON.stringify(sortData)
    this.onChangeAcuityFilter()
  }

  /**
   * Update value in corresponding field and submit filter form
   */
  onChangeAcuityFilter () {
    // update value in active field
    const field = document.querySelector(this.filterFieldSelectorValue)
    field.value = this.sortJsonValue

    // trigger change() for filter controller
    const activeFilterController = this.filterControllerNameValue

    const controllerInstance = Acuity.filterControllers[activeFilterController]

    if (controllerInstance !== undefined || controllerInstance !== null) {
      controllerInstance.change()
    }
  }
}
