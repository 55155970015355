import BaseController from './base_controller'

export default class CollapsibleController extends BaseController {
  static targets = ['collapsible', 'icon', 'text', 'expandState']
  static classes = ['collapsed', 'collapseIcon', 'expandIcon']
  static values = {
    collapseText: { type: String, default: '' },
    expandText: { type: String, default: '' }
  }

  // Change to collapse or expand based on current collapsed state
  change () {
    // If the current state is collapsed, then expand it,
    // otherwise collapse it
    if (this.getCollapsedState()) {
      this.changeIconToExpand()
      this.changeToExpandText()
      this.expandAll()
      this.changeExpandState(true)
    } else {
      this.changeIconToCollapse()
      this.changeToCollapseText()
      this.collapseAll()
      this.changeExpandState(false)
    }

    // update expand state value in form
    if (this.hasExpandStateTarget) {
      this.updateExpandStateForm(this.expandStateTarget.dataset)
    }
  }

  // Collapse all
  collapseAll () {
    this.collapsibleTargets.forEach((target) => {
      target.classList.add(this.collapsedClass)
    })
  }

  // Expand all
  expandAll () {
    this.collapsibleTargets.forEach((target) => {
      target.classList.remove(this.collapsedClass)
    })
  }

  // Change the icon to collapse
  changeIconToCollapse () {
    this.iconTarget.classList.remove(this.expandIconClass)
    this.iconTarget.classList.add(this.collapseIconClass)
  }

  // Change to collapse text
  changeToCollapseText () {
    if (this.hasTextTarget) {
      this.textTarget.innerText = this.collapseTextValue
    }
  }

  // Change the icon to expand
  changeIconToExpand () {
    this.iconTarget.classList.remove(this.collapseIconClass)
    this.iconTarget.classList.add(this.expandIconClass)
  }

  // Change to expand text
  changeToExpandText () {
    if (this.hasTextTarget) {
      this.textTarget.innerText = this.expandTextValue
    }
  }

  // update expand state value on header row
  // @param value [Boolean] updated expand state value
  changeExpandState (value) {
    if (this.hasExpandStateTarget) {
      this.expandStateTarget.dataset.stateValue = value
    }
  }

  // update expand state value on form
  // @param data [Object]
  updateExpandStateForm (data) {
    const stateKey = data.stateKey
    const stateValue = data.stateValue
    const h = {}
    h[stateKey] = stateValue

    // update values for risk returns table
    if (stateKey.includes('risk_returns_configuration')) {
      Cog.stateControllers.riskReturns.updateValues(h)
    } else if (stateKey.includes('multi_horizon_ui_state')) {
      Cog.stateControllers.multiHorizon.updateValues(h)
    }
  }

  // Returns current state is collapsed or not
  // @return [Boolean] state is collapsed or not
  getCollapsedState () {
    return this.collapsibleTargets.some((target) => {
      return target.classList.contains(this.collapsedClass)
    })
  }
}
