import BaseController from './base_controller'
import { fire } from 'lib/compatibility'

export default class MultipleSelectController extends BaseController {
  static targets = ['selectAll', 'selectable', 'button']

  connect () {
    this.updateButton()
  }

  updateButton () {
    if (!this.hasButtonTarget) {
      return
    }

    const selected = this.selectableTargets
      .filter((t) => t.checked)
      .map((t) => this.labelName(t))

    let str
    if (selected.length > 0) {
      str = `(${selected.length}) ${selected.join(', ')}`
    } else {
      str = 'No Selection'
    }

    this.buttonTarget.innerHTML = str
  }

  // Unified action to handle checking a checkbox.
  check (event) {
    if (event.target.contains(this.selectAllTarget)) {
      this.toggleSelectAllState(this.selectAllTarget.checked)
    } else {
      this.inferSelectAllState()
    }
    this.updateButton()
  }

  // Matches all "selectable" target checkboxes to the state of the
  // selectAll checkbox.
  toggleSelectAllState (checkAll) {
    this.selectableTargets.forEach((target) => {
      target.checked = checkAll
      fire(target, 'change')
    })
  }

  // Infers the state of the selectAll checkbox based on the state of all the
  // selectable target checkboxes.
  inferSelectAllState () {
    if (this.selectableTargets.every((target) => target.checked)) {
      this.selectAllTarget.checked = true
    } else {
      this.selectAllTarget.checked = false
    }
  }

  labelName (checkbox) {
    return this.element
      .querySelector(`label[for='${checkbox.id}']:not(.styled-checkbox-content)`)
      .textContent
  }
}
