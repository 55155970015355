import BaseController from './base_controller'

export default class UniqueSelectController extends BaseController {
  static targets = ['select', 'appendButton']

  selectedValues = new Set()
  assignableValues = new Set()

  selectTargetConnected (select) {
    if (select.handled) return

    for (const option of select.options) {
      this.assignableValues.add(option.value)
    }

    if (this.selectedValues.has(select.value)) {
      this.#reassignSelect(select)
    } else {
      this.selectedValues.add(select.value)
    }
    this.updateSelectedValues()
  }

  selectTargetDisconnected (select) {
    this.updateSelectedValues()
  }

  updateSelectedValues () {
    this.selectedValues.clear()
    this.selectTargets.map(select => this.selectedValues.add(select.value))
    for (const select of this.selectTargets) {
      select.handled = true
      for (const option of select.options) {
        option.disabled = this.selectedValues.has(option.value) && !option.selected
      }
    }

    this.appendButtonTarget.disabled = this.selectedValues.size === this.assignableValues.size
  }

  #firstAvailableValue () {
    for (const candidate of this.assignableValues) {
      if (!this.selectedValues.has(candidate)) {
        return candidate
      }
    }

    return null
  }

  #reassignSelect (select) {
    select.value = this.#firstAvailableValue()
  }
}
