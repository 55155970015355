class StackedBarChartLegend {
  graph
  legendContainer

  /**
   * Default constructor for the StackedBarChartLegend
   * @param {StackedBarChart} graphReference - the graph for the legend
   */
  constructor (graphReference) {
    this.graph = graphReference
  }

  /**
   * Get the appropriate string hexidecimal color for the given key for the legend box
   * @param {String} legendKey - The given key to get the legend color
   * @return {String} The appropriate fill color
   */
  legendColor (legendKey) {
    const _this = this

    // Check to see if the legendKey is being filtered and if so return grey color
    if (_this.graph.filteredItems.indexOf(legendKey) !== -1) {
      return '#fff'
    } else {
      return _this.graph.colorScale(legendKey)
    }
  }

  /**
   * Get the appropriate string hexidecimal color for the given key for the legend box border
   * @param {String} legendKey - The given key to get the legend border color
   * @return {String} The appropriate border color
   */
  legendBorderColor (legendKey) {
    const _this = this

    // Check to see if the legendKey is being filtered and if so return white color
    if (_this.graph.filteredItems.indexOf(legendKey) !== -1) {
      return '#d8d8d8'
    } else {
      return _this.graph.colorScale(legendKey)
    }
  }

  // Remove the contents from the legendContainer
  emptyLegendContainer () {
    const _this = this

    _this.legendContainer.selectAll('div').remove()
  }

  /**
   * Filter the data for the items with 0 weight
   * @param {Array<Hash>} data - The data for the graph
   * @return {Array<Hash>} the empty items from the graph data
   */
  getEmptyData (data) {
    // empty_data is an array of all item labels from the graph
    const emptyData = Object.keys(data[0])

    data.forEach(function (dp) {
      for (const attrWeight in dp) {
        // once we found the weight for a item is not 0, then this item is not our target rbr, remove it from empty_data array
        if (dp[attrWeight] !== 0 && emptyData.indexOf(attrWeight) >= 0) {
          emptyData.splice(emptyData.indexOf(attrWeight), 1)
        }
      }
    })

    return emptyData
  }

  /**
   * Update the legend of the graph and the graph based on the selected option from the legend
   * @param {Selection} d - The selected option from the legend
   * @param {HTMLElement} legendItems - The HTML for the legend items
   */
  updateLegend (d, legendItems) {
    const _this = this

    // Note: the filtered items are stored in the graph class due to them being affected by other graph elements
    _this.graph.toggleFilteredItem(d.key)

    _this.emptyLegendContainer()

    // We re-draw the graph based on the selected items in the legend
    _this.graph.draw()

    // Toggle legend fill color
    legendItems.selectAll('.legend-button-inner')
      .attr('style', function (d) {
        return 'background-color:' + _this.legendColor(d.key)
      })
    // Toggle legend border color
    legendItems.selectAll('.legend-button')
      .attr('style', function (d) {
        return 'border: 3px solid ' + _this.legendBorderColor(d.key)
      })
  }

  // Draw the legend for the graph by clearing the legend container then drawing each legend box, border, and text.
  // Each item in the legend is click-able to show or hide the associated graph section.
  drawLegend () {
    const _this = this

    _this.emptyLegendContainer()

    const stackedData = d3.stack() // eslint-disable-line no-undef
      .keys(_this.graph.subgroups)(_this.graph.currentDataHash.dataPoints)

    const legendItems = _this.legendContainer.selectAll('div')
      .data(stackedData)
      .enter()
      .append('div')
      .filter(function (d) {
        // Note: Dont show the legend item for the sections with 0 weight
        return _this.getEmptyData(_this.graph.currentDataHash.dataPoints).indexOf(d.key) === -1
      })
      .attr('class', 'cursor-pointer legend-item')
      .on('click', function (d) {
        _this.updateLegend(d, legendItems)
      })

    // Append the box and border for the legend item
    legendItems.append('span')
      .attr('class', 'legend-button')
      .attr('style', function (d) {
        return 'border: 3px solid ' + _this.legendColor(d.key)
      })
      .append('span')
      .attr('class', 'legend-button-inner')
      .attr('style', function (d) {
        return 'background-color:' + _this.legendBorderColor(d.key)
      })

    // Append the text for the legend item
    legendItems.append('span')
      .attr('class', 'legend-text mr-2')
      .text(function (d) { return d.key })
  }

  /**
   * Add and save the Legend Container section to the given graph
   * @param {HTMLElement} svgContainer - The graph HTML element section
   */
  addLegend (svgContainer) {
    const _this = this

    const paddingPx = _this.graph.options.margin.left + _this.graph.options.legendPadding.left + _this.graph.options.legendPadding.right

    _this.legendContainer = svgContainer.append('div')
      .attr('class', 'stacked-bar-chart-legend flex flex-wrap justify-center mb-6 mt-6')
      .attr('style', `padding:0 ${paddingPx}px`)
  }
}

global.Cog.StackedBarChartLegend ||= StackedBarChartLegend
