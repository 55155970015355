import BaseController from './base_controller'
import { fire } from 'lib/compatibility'

// Needed to submit forms with turbo frame responses
// otherwise will not get output
export default class TurboFormController extends BaseController {
  static targets = ['activeField', 'form']
  static values = {
    fieldSelector: String
  }

  submitForm () {
    const selectedValue = this.activeFieldTarget.value
    const formField = this.formTarget.querySelector(this.fieldSelectorValue)
    formField.value = selectedValue
    fire(this.formTarget, 'submit')
  }
}
