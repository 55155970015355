import BaseController from './base_controller'

// Video behavior is inconsistent between browsers - chrome will auto-pause vid
// when it goes offscreen, firefox will not. Since we use some CSS animations,
// we should keep their state synced with video state
export default class extends BaseController {
  static targets = ['video', 'animation']

  // Set option = 'running' to start CSS anims when video plays and ensure CSS anims keep running once video completes
  play () {
    this.setAnimationPlayState('running')
  }

  // Set option = 'paused' to ensure CSS anims pause while video is paused
  pause () {
    this.setAnimationPlayState('paused')
  }

  // @param [String] option is CSS property value string
  setAnimationPlayState (option) {
    this.animationTargets.forEach(icon => {
      icon.style.setProperty('animation-play-state', option)
    })
  }
}
