import BaseController from '../base_controller'

export default class extends BaseController {
  static values = {
    data: String
  }

  connect () {
    super.connect()
    this.renderSectorSankey()
  }

  disconnect () {
    super.disconnect()
  }

  // render CompanySankey based on passed in data
  renderSectorSankey () {
    const data = JSON.parse(this.dataValue)
    Cog.sectorSankey('#sector_sankey', data, {})
  }
}
