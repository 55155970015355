import BaseController from './base_controller'

// Add an indicator to an element to show if it was changed
export default class extends BaseController {
  static targets = ['form']

  // On controller intitialization, set the field 'changed' to false
  connect () {
    super.connect()
    this.formTarget.setAttribute('changed', 'false')
  }

  // When a field is changed, update it's 'changed' attribute to true
  fieldChanged () {
    this.formTarget.setAttribute('changed', 'true')
  }
}
