// Using the Rails fire method from Cog
// What if I want to submit a form? Calling form.submit() will submit the form
// directly,regardless of whether or not it was a remote form.
// Instead call fire(form, "submit") to submit the form.
//
// Pulled from @rails/ujs.
export function fire (obj, name, data) {
  const event = new CustomEvent(name, {
    bubbles: true,
    cancelable: true,
    detail: data
  })
  obj.dispatchEvent(event)
  return !event.defaultPrevented
}

/**
 * @param {Object} fieldTargets HTML object for associated fields to derive URL from
 * @returns {String} an URL is constructing based on field values in the filter form
 *                   e.g http://host?field1=value1&field2=value2
 */
export function getUrlFromFields (fieldTargets) {
  const baseUrl = window.location.origin
  const path = window.location.pathname
  let url = baseUrl + path

  fieldTargets.forEach((field, ind, _array) => {
    const id = field.id
    const value = field.value

    if (field.type === 'submit') { return }

    if (value.toString().trim() !== '') {
      if (ind === 0) {
        url += '?' + id + '=' + value
      } else {
        url += '&' + id + '=' + value
      }
    }
  })

  return url
}
