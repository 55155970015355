import BaseController from './base_controller'

// This class will check if an input is valid through HTML5 validations
// if the input field is not valid, display an error outlining why it is not valid
export default class extends BaseController {
  static targets = ['field', 'valueMissingErrorMessage',
    'tooShortErrorMessage', 'tooLongErrorMessage',
    'rangeUnderflowErrorMessage', 'rangeOverflowErrorMessage']

  static classes = ['hidden']

  // Check an input fields HTML5 validity without dispatching an event
  validate () {
    this.toggleInputValidation()

    // Event to indicate that an input was validated. This can be used when other parts of HTML need to be updated
    // in response to a validation (EX: Refresh tippy content to display new validation error from content target)
    this.dispatch('input-validated', { detail: { valid: this.fieldTarget.validity.valid } })
  }

  // Check an input fields HTML5 validity and Dispatch an event to alert the page that an input was updated
  validateAndDispatchUpdate () {
    this.validate()

    // This event is meant to indicate that validation happened and an input was updated by a user.
    // This can be used whenever user interaction prompts change in the HTML. (EX: validating another field in response to this one)
    this.dispatch('input-updated')
  }

  // Check the field is disabled. If it is, remove validations (remove attribute and hide error messages)
  clearValidationsForDisabledField () {
    if (this.fieldTarget.hasAttribute('disabled')) {
      this.clearValidations()
    }
  }

  // Remove valid attribute and Hide all Messages
  // This can be used if a field should no longer be validated. i.e. by being disabled
  clearValidations () {
    if (this.fieldTarget.hasAttribute('valid')) {
      this.fieldTarget.removeAttribute('valid')
    }

    // Hide all error messages that belong to the input element, if they exist
    if (this.hasValueMissingErrorMessageTarget) {
      this.valueMissingErrorMessageTarget.classList.toggle(this.hiddenClass, this.hasValueMissingErrorMessageTarget)
    }
    if (this.hasTooShortErrorMessageTarget) {
      this.tooShortErrorMessageTarget.classList.toggle(this.hiddenClass, true)
    }
    if (this.hasTooLongErrorMessageTarget) {
      this.tooLongErrorMessageTarget.classList.toggle(this.hiddenClass, true)
    }
    if (this.hasRangeUnderflowErrorMessageTarget) {
      this.rangeUnderflowErrorMessageTarget.classList.toggle(this.hiddenClass, true)
    }
    if (this.hasRangeOverflowErrorMessageTarget) {
      this.rangeOverflowErrorMessageTarget.classList.toggle(this.hiddenClass, true)
    }
  }

  // Run validation logic: Add valid attribute and toggle error message visibility
  toggleInputValidation () {
    if (this.fieldTarget.hasAttribute('disabled')) { return }

    this.toggleErrorMessages()

    if (this.fieldTarget.validity.valid) {
      this.fieldTarget.setAttribute('valid', 'true')
    } else {
      this.fieldTarget.setAttribute('valid', 'false')
    }
  }

  // Toggle each error message's visibility state
  toggleErrorMessages () {
    this.toggleValueMissingErrorMessage()
    this.toggleTooShortErrorMessage()
    this.toggleTooLongErrorMessage()
    this.toggleRangeUnderflowErrorMessage()
    this.toggleRangeOverflowErrorMessage()
  }

  // Toggle valueMissing state, if there is a valueMissing target
  toggleValueMissingErrorMessage () {
    if (!this.hasValueMissingErrorMessageTarget) return

    this.toggleErrorMessage(this.valueMissingErrorMessageTarget, !this.fieldTarget.validity.valueMissing)
  }

  // Toggle tooShort state, if there is a tooShort target
  toggleTooShortErrorMessage () {
    if (!this.hasTooShortErrorMessageTarget) return

    this.toggleErrorMessage(this.tooShortErrorMessageTarget, !this.fieldTarget.validity.tooShort)
  }

  // Toggle tooLong state, if there is a tooLong target
  toggleTooLongErrorMessage () {
    if (!this.hasTooLongErrorMessageTarget) return

    this.toggleErrorMessage(this.tooLongErrorMessageTarget, !this.fieldTarget.validity.tooLong)
  }

  // Toggle rangeUnderflow state, if there is a rangeUnderflow target
  toggleRangeUnderflowErrorMessage () {
    if (!this.hasRangeUnderflowErrorMessageTarget) return

    this.toggleErrorMessage(this.rangeUnderflowErrorMessageTarget, !this.fieldTarget.validity.rangeUnderflow)
  }

  // Toggle rangeOverflow state, if there is a rangeOverflow target
  toggleRangeOverflowErrorMessage () {
    if (!this.hasRangeOverflowErrorMessageTarget) return

    this.toggleErrorMessage(this.rangeOverflowErrorMessageTarget, !this.fieldTarget.validity.rangeOverflow)
  }

  // Toggle an error message's hidden state, depending on if the target is valid or not
  toggleErrorMessage (targetErrorMessage, targetIsValid) {
    targetErrorMessage.classList.toggle(this.hiddenClass, targetIsValid)
  }
}
