import BaseController from './base_controller'

export default class extends BaseController {
  // store flag (fetchData) on each tab
  // set flag value on each tab
  /// / 0) set all flag == true for all tabs
  /// / 1) when switch tab, if flag == true, fetch; set current flag back to false
  /// / 2) when search box changes, set ALL tab flags to true; fetch; set current flag back to false
  /// / 3) when select organization dropdown, set CURRENT tab flag to true; fetch; set current flag back to false
  // 4) when click any column heading to sort, set ALL tab flags to true; fetch; set current flag back to false
  // 5) when click "next 10" pagination, set ALL tab flags to true; fetch; set current flag back to false

  // To add a fiter element as a trigger to update fetchDataFlag,
  // place data-portfolio-filter-tab-affected-value attribute on the element.
  // Set the attribute to 'all' when the changes on the trigger need to update all flags (most cases).
  // Set the attribute to 'current' when the changes on the trigger need to only
  // update current tab flag (i.e. organization with the non-my-org-portfolio).
  static targets = ['fetchDataFlag']
  static values = {
    name: String, // name of the controller instance
    fetchDataFlag: { type: Boolean, default: true }
  }

  connect () {
    super.connect()
    global.Cog.portfolioTabDataFetchControllers ||= {}
    global.Cog.portfolioTabDataFetchControllers[this.nameValue] = this
  }

  disconnect () {
    super.disconnect()
    global.Cog.portfolioTabDataFetchControllers[this.nameValue] = null
  }

  updateAll (booleanValue) {
    const dataFetchControllers = global.Cog.portfolioTabDataFetchControllers

    for (const name in dataFetchControllers) {
      const controller = dataFetchControllers[name]
      controller.fetchDataFlagValue = booleanValue
    }
  }

  // update fetchDataFlag for current active tab
  updateCurrent (booleanValue) {
    this.fetchDataFlagValue = booleanValue
  }

  // update fetchDataFlag
  update (tabAffected) {
    if (tabAffected === 'all') {
      this.updateAll(true)
    } else if (tabAffected === 'current') {
      this.updateCurrent(true)
    }
  }
}
