import BaseController from '../base_controller'

export default class extends BaseController {
  static targets = ['firstRow', 'lastRow', 'firstRowLargerThanLastRowErrorMessage']
  static classes = ['hidden']

  // validate the first and last data row
  // @param [Object] Event object that contains the valid attribute from input-validation:input-updated
  validate ({ detail: { valid } }) {
    const dataRowsValid = this.firstAndLastDataRowIsValid()
    const allValidationsPassed = valid && dataRowsValid

    if (dataRowsValid) {
      this.toggleErrorMessage(true)
    } else {
      this.toggleErrorMessage(false)
    }

    this.toggleValidAttribute(allValidationsPassed)
    this.dispatch('data-row-updated', { detail: { valid: allValidationsPassed } })
  }

  // Toggle the error message for if the first row is greater than the last row
  // @param valid [Boolean] Is the first and last data rows valid compared to each other
  toggleErrorMessage (valid) {
    this.firstRowLargerThanLastRowErrorMessageTarget.classList.toggle(this.hiddenClass, valid)
  }

  // Toggle the valid attribute
  // @param valid [Boolean] Is the first and last data rows valid compared to each other
  toggleValidAttribute (valid) {
    if (valid) {
      this.firstRowTarget.setAttribute('valid', 'true')
    } else {
      this.firstRowTarget.setAttribute('valid', 'false')
    }
  }

  // Check if the first and last data row are valid
  firstAndLastDataRowIsValid () {
    const firstRowValue = parseInt(this.firstRowTarget.value)
    const lastRowValue = parseInt(this.lastRowTarget.value)

    // Guard clause: Variables are valid if one or both are not defined (can't check values that don't exist yet)
    if (!firstRowValue || !lastRowValue) { return true }

    return firstRowValue <= lastRowValue
  }
}
