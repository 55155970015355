import BaseController from './base_controller'

const NAV_BAR_OFFSET = 200 // height of navbar

export default class NavScrollerController extends BaseController {
  static targets = ['navTab']
  static classes = ['active']
  static values = { sectionId: String }

  /**
   * update tab highlights while scrolling on the page
   */
  updateNavFromScroll () {
    const closestTab = this.getClosestNavTab()
    this.updateHighlights(closestTab)
  }

  /**
   * Find the nav tab based on the closest section to the current scroll position
   * @return {Object} HTML corresponding to nav tab of the closest section on page
   */
  getClosestNavTab () {
    let closestTab = null
    let minSectionOffset = 0

    this.navTabTargets.forEach(function (tab) {
      const sectionId = tab.dataset.navScrollerSectionIdValue
      const sectionOffSet = document.getElementById(sectionId).offsetTop - NAV_BAR_OFFSET

      // distance between curr scrolling position and closest show page section
      const currOffset = Math.abs(window.scrollY - sectionOffSet)
      if (closestTab == null || currOffset < minSectionOffset) {
        closestTab = tab
        minSectionOffset = currOffset
      }
    })

    return closestTab
  }

  /**
   * Update tab styling for active tab
   * @param activeTab {HTMLObject} tab in nav bar
   */
  updateHighlights (activeTab) {
    const activeCss = this.activeClass
    this.navTabTargets.forEach(function (tab) {
      if (tab === activeTab) {
        activeTab.classList.add(activeCss)
      } else {
        tab.classList.remove(activeCss)
      }
    })
  }

  /**
   * Scroll to the section corresponding to the clicked tab
   * @param event {Object} click event
   */
  scrollToSection (event) {
    const clickedTab = event.target
    const sectionId = clickedTab.dataset.navScrollerSectionIdValue
    const sectionScroll = document.getElementById(sectionId).offsetTop - NAV_BAR_OFFSET

    // 1. Scroll the page to defined y position
    // 2. Will trigger updateNavFromScroll() due to the scroll (and the event listener for scroll events)
    // 3. Highlights will be handled by updateNavFromScroll() to avoid highlight inconsistencies (in case of double clicking)
    window.scrollTo({ top: sectionScroll, behavior: 'smooth' })
  }
}
