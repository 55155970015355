import Timeago from 'stimulus-timeago'

// This class inherits class functions and controller parameters found here:
// https://github.com/stimulus-components/stimulus-timeago/blob/master/src/index.ts
// Additional documentation: https://www.stimulus-components.com/docs/stimulus-timeago/
export default class NotificationTimeController extends Timeago {
  // Time value to disconnect notification component in ms
  disconnectTime = 3600000

  load () {
    super.load()
    this.element.innerHTML = this.element.innerHTML.charAt(0).toUpperCase() + this.element.innerHTML.slice(1) + ' ago'
    // If time is greater than disconnectTime stop sending updates
    if (Date.now() - Date.parse(this.datetimeValue) > this.disconnectTime) {
      this.disconnect()
    }
  }
}
