import BaseController from './acuity_base_controller'

export default class AcuityCollapsibleController extends BaseController {
  static targets = ['collapsible', 'icon', 'sectionHeader']
  static classes = ['collapsed', 'collapseIcon', 'expandIcon']

  static values = {
    preExpandedItems: Array
  }

  initialize () {
    this.expandedItems = this.preExpandedItemsValue
    this.firstTimeSetup = true
  }

  connect () {
    super.connect()
    this.expandedItems = this.preExpandedItemsValue
    this.firstTimeSetup = true
  }

  disconnect () {
    super.disconnect()
    this.expandedItems = []
    this.firstTimeSetup = false
  }

  // When the collapsible targets connect, check to see if they should be expanded by default
  // @param target [HtmlElement] target we want to check
  collapsibleTargetConnected (target) {
    if (this.expandedItems.includes(target.getAttribute('data-acuity-collapsible-id'))) {
      target.classList.remove(this.collapsedClass)
    }
  }

  // When the section header targets connect, check to see if they should have the down arrow
  // @param target [HtmlElement] target we want to check
  sectionHeaderTargetConnected (target) {
    const id = target.getAttribute('data-acuity-collapsible-id-param')

    // First time setup to add the top level items to the expanded items array.
    if (target.getAttribute('data-acuity-collapsible-top-level') === 'true' && this.firstTimeSetup) {
      if (!this.expandedItems.includes(id)) {
        this.expandedItems.push(id)
      }
      this.firstTimeSetup = false
    }

    // When we are reloading the items in the tree we check to see if they should be expanded rather
    // than the default collapsed.
    if (this.expandedItems.includes(id)) {
      target.innerHTML = '<i class="none-pointer fas fa-angle-down" data-acuity-collapsible-target="icon"></i>'
    }
  }

  // Change to collapse or expand based on current collapsed state
  change () {
    // If the current state is collapsed, then expand it,
    // otherwise collapse it
    if (this.getCollapsedState()) {
      this.changeIconToExpand()
      this.expandAll()
    } else {
      this.changeIconToCollapse()
      this.collapseAll()
    }
  }

  // Collapse all
  collapseAll () {
    this.collapsibleTargets.forEach((target) => {
      target.classList.add(this.collapsedClass)
    })

    this.expandedItems = []
  }

  // Expand all
  expandAll () {
    this.collapsibleTargets.forEach((target) => {
      target.classList.remove(this.collapsedClass)

      const id = target.getAttribute('data-acuity-collapsible-id-param')
      if (!this.expandedItems.includes(id)) {
        this.expandedItems.push(id)
      }
    })
  }

  // Change the icon to collapse
  changeIconToCollapse () {
    this.iconTarget.classList.remove(this.expandIconClass)
    this.iconTarget.classList.add(this.collapseIconClass)
  }

  // Change the icon to expand
  changeIconToExpand () {
    this.iconTarget.classList.remove(this.collapseIconClass)
    this.iconTarget.classList.add(this.expandIconClass)
  }

  // Returns current state is collapsed or not
  // @return [Boolean] state is collapsed or not
  getCollapsedState () {
    return this.collapsibleTargets.some((target) => {
      return target.classList.contains(this.collapsedClass)
    })
  }

  // @param event [Event] the toggle event
  toggleCollapseSection (event) {
    if (this.expandedItems.includes(event.params.id.toString())) {
      this.collapseSection(event.params.id)

      event.currentTarget.querySelector('[data-acuity-collapsible-target="icon"]').classList.replace(this.expandIconClass, this.collapseIconClass)
    } else {
      this.expandSection(event.params.id)

      event.currentTarget.querySelector('[data-acuity-collapsible-target="icon"]').classList.replace(this.collapseIconClass, this.expandIconClass)
    }
  }

  // @param id [String] the id of the target section
  expandSection (id) {
    const sections = this.collapsibleTargets.filter(target => target.getAttribute('data-acuity-collapsible-id') === id.toString())

    if (!this.expandedItems.includes(id.toString())) {
      this.expandedItems.push(id.toString())
    }

    if (sections.length <= 0) { return }

    sections.forEach(section => section.classList.remove(this.collapsedClass))
  }

  // @param id [String] the id of the target section
  collapseSection (id) {
    const sections = this.collapsibleTargets.filter(target => target.getAttribute('data-acuity-collapsible-id') === id.toString())

    this.expandedItems = this.expandedItems.filter(item => item !== id.toString())

    if (sections.length <= 0) { return }

    sections.forEach(section => section.classList.add(this.collapsedClass))
  }
}
