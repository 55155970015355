import { DateTime } from "luxon"
import { filterTraceIndexByUpdatemenuSelection } from './utilities'

/**
 * Stock chart legend
 * @constructor
 * @param {String} selector - Stock chart legend selector
 * @param {String} legendItemSelector - selector for legend item in the legend
 * @param {HTML} stockChartElement - associated stock chart to the legend
 */
export function StockChartLegend(selector, legendItemSelector, stockChartElement) {
  let self = this
  let legend,
      legendItems,
      legendSections,
      legendCheckboxes,
      legendWeightButtons,
      legendDateHolder

  (function initialize() {
    legend = document.querySelector(selector)
    legendItems = legend.querySelectorAll(legendItemSelector),
    legendSections = legend.querySelectorAll('.legend-section'),
    legendCheckboxes = legend.querySelectorAll("input[type='checkbox']"),
    legendWeightButtons = getLegendWeightButtons(legendSections),
    legendDateHolder = legend.querySelector('#stock_chart_date_holder')

    addLegendItemEventListener()
    addLegendCheckboxListener()
    addPortfolioWeightButtonListener()
  })()

  /**
   * @param {NodeList} legendSections
   * @return {NodeList} portfolio weight selection buttons
   */
  function getLegendWeightButtons(legendSections) {
    const portfolioSection = [...legendSections].filter(section => section.dataset.benchmarkType == 'portfolio')
    if (portfolioSection.length > 0) {
      return portfolioSection[0].querySelectorAll('.legend-weight-btn')
    } else {
      return []
    }
  }

  /** Add event listeners to legend item */
  function addLegendItemEventListener() {
    legendItems.forEach(function(item) {
      item.addEventListener('click', function() {
        const legendItem = this,
              viewTrace = legendItem.classList.contains('legend-unselected')

        // change selection styling for legend items
        setClass([legendItem], viewTrace, 'legend-unselected')

        // look at checkbox indeterminate state
        const checkbox = legendItem.closest('.legend-section').querySelector("input[type='checkbox']")
        toggleCheckboxIndeterminateState(checkbox)

        // look at weight strategy button state
        const benchmarkType = checkbox.dataset.benchmarkType
        if (benchmarkType == 'portfolio') {
          toggleWeightSelectionButton(benchmarkType, legendItem.dataset.weightStrategy)
        }

        // change trace visibility
        toggleStockchartLine([legendItem], viewTrace)
      })
    })
  }

  /**
   * Adjust checkbox state based on number of selected legend items
   * @param {Node} checkbox - checkbox to modify
   */
  function toggleCheckboxIndeterminateState(checkbox) {
    const benchmarkType = checkbox.dataset.benchmarkType,
          benchmarkTypeItems = self.legendItemsByBenchmarkType(benchmarkType),
          unselectedItems = [...benchmarkTypeItems].filter(item => item.classList.contains('legend-unselected')),
          isChecked = unselectedItems.length == 0,
          isIndeterminate = (unselectedItems.length != benchmarkTypeItems.length) && !isChecked

    checkbox.dataset.indeterminate = isIndeterminate
    checkbox.indeterminate = isIndeterminate
    checkbox.checked = isChecked
  }

  /** Add event listeners to legend section checkbox */
  function addLegendCheckboxListener() {
    legendCheckboxes.forEach(function(checkbox) {
      checkbox.addEventListener('click', function() {
        const checkbox = this,
              selectionType = checkbox.dataset.benchmarkType,
              isChecked = checkbox.checked

        // change selection styling for legend items
        const affectedLegendItems = self.legendItemsByBenchmarkType(selectionType)
        setClass(affectedLegendItems, isChecked, 'legend-unselected')

        // change styling for weight selection button
        if (selectionType == 'portfolio') {
          const affectedWeightButtons = activeWeightButtons(legendWeightButtons)
          setClass(affectedWeightButtons, isChecked, 'unselected')
        }

        // change trace visibility
        toggleStockchartLine([...affectedLegendItems], isChecked)
      })
    })
  }

  /**
   * @param {NodeList} legendWeightButtons
   * @return {Array<Node>} array of active portfolio weight buttons
   */
  function activeWeightButtons(legendWeightButtons) {
    return [...legendWeightButtons].filter(button => !button.classList.contains('disabled'))
  }

  /** Add event listeners to portfolio section weight buttons */
  function addPortfolioWeightButtonListener() {
    activeWeightButtons(legendWeightButtons).forEach(function(button) {
      button.addEventListener('click', function() {
        const weightBtn = this,
              weightStrategy = weightBtn.dataset.weightStrategy,
              viewWeightStrategy = weightBtn.classList.contains('unselected')

        // change styling for weight strategy button
        setClass([weightBtn], viewWeightStrategy, 'unselected')

        // change selection styling for legend items
        const affectedLegendItems = [...self.legendItemsByBenchmarkType('portfolio')]
                                          .filter(item => item.dataset.weightStrategy == weightStrategy)
        setClass(affectedLegendItems, viewWeightStrategy, 'legend-unselected')

        // look at checkbox indeterminate state
        const checkbox = [...legendCheckboxes].filter(checkbox => checkbox.dataset.benchmarkType == 'portfolio')[0]
        toggleCheckboxIndeterminateState(checkbox)

        // change trace visibility
        toggleStockchartLine(affectedLegendItems, viewWeightStrategy)
      })
    })
  }

  /**
   * Toogle weight selection button styling based on number of portfolio selections with the corresponding weight
   * @param {String} benchmarkType - 'portfolio', 'performance', 'syntax', 'organization'
   * @param {String} weightStrategy - 'portfolio', 'stratified', 'equal', 'cap'
   */
  function toggleWeightSelectionButton(benchmarkType, weightStrategy) {
    const currLegendItems = [...self.legendItemsByBenchmarkType(benchmarkType)].filter(item => item.dataset.weightStrategy == weightStrategy),
          unselectedItems = currLegendItems.filter(item => item.classList.contains('legend-unselected')),
          weightBtn = [...legendWeightButtons].filter(button => button.dataset.weightStrategy == weightStrategy),
          showSelected = currLegendItems.length != unselectedItems.length
    setClass(weightBtn, showSelected, 'unselected')
  }

  /**
   * Show/hide trace on the stock chart
   * @param {Array<Node>} affectedLegendItems
   * @param {Boolean} viewTrace
   */
  function toggleStockchartLine(affectedLegendItems, viewTrace) {
    // get seriesIds from the legend items
    const seriesIds = affectedLegendItems.map((selection) => selection.dataset.seriesId)

    // toggle visibility on stock chart based on checked state
    const traceIndexes = filterTraceIndexByUpdatemenuSelection(seriesIds, stockChartElement)
    Plotly.restyle(stockChartElement, {
      'visible': [viewTrace]
    }, traceIndexes)
  }

  /**
   * Setting classes for given elements based on trace visibility
   * @param {Array<Node>} currElement
   * @param {Boolean} viewTrace
   * @param {String} classToToggle
   */
  function setClass(currElements, viewTrace, classToToggle) {
    currElements.forEach(function(element) {
      if (viewTrace) {
        element.classList.remove(classToToggle)
      } else {
        element.classList.add(classToToggle)
      }
    })
  }

  /**
   * return the current legend
   * @return {Node}
   */
  this.legend = function() {
    return legend
  }

  /**
   * return all the legend items
   * @return {NodeList}
   */
  this.legendItems = function() {
    return legendItems
  }

  /**
   * return all legend types based on benchmark selection type
   * @param {String} benchmarkType - "portfolio", "performance", "syntax", "organization"
   */
  this.legendItemsByBenchmarkType = function(benchmarkType) {
    return [...legendSections].filter(section => section.dataset.benchmarkType == benchmarkType)[0]
                              .querySelectorAll(legendItemSelector)
  }

  /**
   * return all the unselected legend items
   * @return {Array<Node>}
   */
  this.unselectedLegendItems = function() {
    return [...legendItems].filter(item => item.classList.contains('legend-unselected'))
  }

  /** Sets value displayed in date holder
   * @param {Date|String} date - currently hovered date or empty string
   */
  this.setDateHolder = function(date) {
    if (typeof date !== 'string') {
      date = DateTime.fromISO(date.toISOString())
                     .toUTC()
                     .toFormat('M/dd/yyyy')
    }
    legendDateHolder.innerHTML = date
  }

  /**
   * Sets values seen on legend items
   * @param {String} seriesId - for corresponding trace
   * @param {String} valueTypeClass - css class selected - '.annualized-returns' or '.share-price'
   * @param {String} value - value to set in field
   */
  this.setLegendItemValue = function(seriesId, valueTypeClass, value) {
    let activeLegendItems = legendItems
    if (seriesId.length > 0) { // filter by specfic series id
      activeLegendItems = [...legendItems].filter(item => item.dataset.seriesId == seriesId)
    }

    // update values for active legend items
    activeLegendItems.forEach(function(item) {
      item.querySelector(valueTypeClass).innerHTML = value
    })
  }
}
