import BaseController from './base_controller'

/**
 * Class to manage the restriction section and underlying obscurable section
 */
export default class RestrictedOptionController extends BaseController {
  static targets = ['restrictorSection', 'obscurableSection']
  static classes = ['obscured', 'hidden']

  /**
   * Method to show a restriction overlay and obscure the underlying section
   */
  toggleRestrict () {
    if (this.restrictorSectionTarget.classList.contains(this.hiddenClass)) {
      this.restrictorSectionTarget.classList.remove(this.hiddenClass)

      this.obscurableSectionTarget.classList.add(this.obscuredClass)
    } else {
      this.restrictorSectionTarget.classList.add(this.hiddenClass)

      this.obscurableSectionTarget.classList.remove(this.obscuredClass)
    }
  }
}
