import BaseController from './acuity_base_controller'
import { fire } from '../lib/form_events'
import { clamp, debounce } from 'lodash'

const escapeKey = 'Escape'
const downKey = 'ArrowDown'
const upKey = 'ArrowUp'
const enterKey = 'Enter'
const selectionKeys = [downKey, upKey, enterKey]

export default class AcuityAutocompleteController extends BaseController {
  static targets = ['form', 'search', 'autocompleteBox', 'resultsList', 'result']
  static classes = ['selected']

  initialize () {
    // We need to set the action as a waitable function here.
    this.autocomplete = debounce(this.autocomplete, 250)
  }

  connect () {
    super.connect()
    this.index = -1
  }

  autocomplete () {
    if (this.searchTarget.value.toString().trim().length > 0) {
      fire(this.formTarget, 'submit')
    } else if (this.hasResultsListTarget) {
      this.resultsListTarget.remove()
    }
  }

  // Complete action involving setting the box to be empty and resetting the list, index
  // @param id [String] the id of the thing we want to complete
  complete () {
    this.searchTarget.value = ''

    this.resultsListTarget.remove()

    this.index = -1
  }

  // Close the results box if the escape key was pressed or we clicked away.
  // @param event [Event] the event we want to check
  close (event) {
    if (this.isEscapeKey(event) || this.isClickOutOfBounds(event)) {
      if (this.hasResultsListTarget) {
        this.resultsListTarget.remove()

        this.index = -1
      }
    }
  }

  // @param event [Event] the event we want to check
  // @returns [Boolean] true iff the events key was the escape key
  isEscapeKey = (event) => event.key === escapeKey

  // @param event [Event] the event we want to check
  // @returns [Boolean] true iff the events type was a click and the click was on one of our list results
  isClickOutOfBounds = (event) => event.type === 'click' && !this.resultTargets.includes(event.target)

  // Method to navigate through our list results using the arrow keys and enter key
  // @param event [Event] the event we want to check
  selectResult (event) {
    if (!selectionKeys.includes(event.key)) { return }

    if (selectionKeys.includes(event.key) && !this.autocompleteBoxTarget.children.length > 0) { return }

    if (event.key === enterKey && this.index >= 0) { return this.resultTargets[this.index].click() }

    event.preventDefault()

    if (this.resultTargets[this.index]) { this.resultTargets[this.index].classList.remove(this.selectedClass) }

    if (event.key === upKey) {
      this.index--
    } else if (event.key === downKey) {
      this.index++
    }

    this.index = clamp(this.index, -1, this.resultTargets.length - 1)

    if (this.index >= 0) {
      this.resultTargets[this.index].focus()
      this.resultTargets[this.index].classList.add(this.selectedClass)
    }
  }

  // Prevent default scrolling for our arrow keys while in use.
  // @param event [Event] the event we want to check
  preventScroll (event) {
    if (!selectionKeys.includes(event.key)) { return }

    event.preventDefault()
  }
}
