/**

 - BEFORE
 LEVEL 1                 LEVEL 2                PRODUCT LINES
 RBR_1 ------------------ RBR_2 ------------------ PL_1
     |
      \___________________________________________ PL_2

- AFTER
  LEVEL 1                 LEVEL 2                PRODUCT LINES
  RBR_1 ------------------ RBR_2 ------------------ PL_1
   |
    \____________________ FAKE_PL_2 _______________ PL_2

 * Create fake nodes and links to prevent weird crossovers in the data
 * NOTE: The issue is more noticable when there are more child nodes invovled, and the difference in levels between the parent node, and child node is greater
 * @param {Object} data sankey data
 * @param {Number} maxLevel max level in the sankey
 */

export function seedFakeData (data, maxLevel) {
  // get all the RBRs that are linked to a product line
  const rbrsWithPLs = data.links.filter((link) => { return link.target.includes('p') }).map((link) => { return link.source })
  rbrsWithPLs.forEach(function (rbrId) {
    const currRBR = data.nodes.filter((node) => { return node.id === rbrId })[0]

    // loop through each RBR and find all of its links, and the max level
    const relatedLinks = data.links.filter((link) => { return link.source === rbrId })
    const relatedLinkRbrLevels = relatedLinks.map((link) => { return link.level }).filter((level) => { return level !== undefined })

    // If the given RBR has more than one non-product line child
    // Or the level of the current RBR is NOT at the max level
    if (relatedLinkRbrLevels.length > 0 || currRBR.level < maxLevel) {
      // get the max level to use for the fake node
      const fakeNodeLevel = relatedLinkRbrLevels.length > 0 ? Math.max(...relatedLinkRbrLevels) : maxLevel

      // go through each product line link and create fake nodes to act as a buffer
      const plLinks = relatedLinks.filter((link) => { return link.level === undefined })
      plLinks.forEach(function (link) {
        const relatedPL = data.nodes.filter((node) => { return node.id === link.target })[0]
        const fakeNode = { id: `${currRBR.id}_${relatedPL.id}_fake`, type: 'fake_node', value: relatedPL.value, level: fakeNodeLevel, name: `fake ${relatedPL.name}` }
        const fakeLink = { source: fakeNode.id, target: relatedPL.id, value: fakeNode.value }

        data.nodes.push(fakeNode)
        data.links.push(fakeLink)

        link.target = fakeNode.id
        link.level = fakeNodeLevel
      })
    }
  })
}
