import BaseController from './base_controller'

export default class extends BaseController {
  static targets = ['container', 'button']
  static classes = ['concealed']
  static values = {
    showBtnText: String,
    hideBtnText: String
  }

  toggle () {
    const isShowButton = this.containerTarget.classList.contains(this.concealedClass)

    const updatedText = isShowButton ? this.hideBtnTextValue : this.showBtnTextValue

    this.buttonTarget.blur()
    this.containerTargets.forEach(container => {
      container.classList.toggle(this.concealedClass)
    })

    this.buttonTarget.textContent = updatedText
  }
}
