import BaseController from './acuity_base_controller'

// Contain helper methods for multi-select related controllers
// Should NOT use this class on front end
export default class AcuityBaseMultiSelectController extends BaseController {
  static targets = ['selectionList', 'selection', 'section', 'activeField',
    'selectionListContainer', 'instructions', 'selectionListContainerInstructions',
    'percentageFilterContainer', 'percentageFilter', 'selectionType']

  static classes = ['flex', 'hidden']

  static values = {
    filterControllerName: String,
    preSelectedIds: Array,
    normalizedPercentageHiddenFieldSelector: String,
    isRbrSelect: { type: Boolean, default: false },
    checkboxContainerSelector: String
  }

  connect () {
    super.connect()
    this.selectedIds = this.preSelectedIdsValue
  }

  disconnect () {
    super.disconnect()
    this.selectedIds = []
  }

  // Update list of currently selected ids
  // @param {Integer} selectedId
  // @param {Boolean} removeId
  updateSelectedIds (selectedId, removeId) {
    // remove selected selectionId
    if (removeId) {
      this.selectedIds = this.selectedIds.filter(id => id !== selectedId)
    // add selected record id and the selectedId is not present in the selectedIds master list
    } else if (!this.selectedIds.includes(selectedId)) {
      this.selectedIds.push(selectedId)
    }
  }

  /*
   * Update counts in the corresponding select menu
   * @param event {Event}
  */
  updateMenuSelectionCount (event) {
    const menu = document.querySelector('.acuity-rbr-menu[data-acuity-menu-multi-select-type-value="acuity-multi-select"]')
    const menuController = this.application.getControllerForElementAndIdentifier(menu, 'acuity-menu')

    if (menuController !== null) {
      menuController.toggleSelected(event)
    }
  }

  // Update value in corresponding field and submit filter form
  onChangeAcuityFilter () {
    // update value in active field
    this.activeFieldTarget.value = this.selectedIds.toString()

    // trigger change() for filter controller
    const activeFilterController = this.filterControllerNameValue
    const controllerInstance = Acuity.filterControllers[activeFilterController]

    if (controllerInstance !== undefined || controllerInstance !== null) {
      controllerInstance.change()
    }
  }

  /*
   * @param sectionId {Number}
   * @return {HTMLObject} corresponding multi-selection section
   */
  findTargetSectionHelper (sectionId) {
    let selector = 'data-acuity-multi-select-section-id-param'
    if (this.filterControllerNameValue.includes('base')) {
      // should work if defined with acuity-base-multi-select
      // but ideally we should use data-acuity-multi-select
      selector = 'data-acuity-base-multi-select-section-id-param'
    }
    return this.sectionTargets.find(target => target.getAttribute(selector) === sectionId.toString())
  }

  /*
  * Add multi select item from the list
  * @param name {String} name of the item
  * @param sectionId {Number} id of the section the item will be in
  * @param id {Number} id of the item to add
  * @param section {String} corresponding section to add the multi-select item to
  */
  addToMultiSelectHelper (name, sectionId, id, section) {
    const targetSection = this.findTargetSectionHelper(sectionId)

    // Get the selections of the given section.
    const sectionSelections = Array.from(this.selectionsPerTargetSection(targetSection))

    // Check to see if we already have a matching existing selection and prevent the default.
    if (targetSection.children.length > 0 && sectionSelections.find(target => target.getAttribute('data-acuity-multi-select-id-param') === id.toString()) !== undefined) { return }

    if (this.selectionListTarget.classList.contains(this.hiddenClass)) {
      this.selectionListTarget.classList.remove(this.hiddenClass)

      // NOTE: THIS IS DIFFERENT FROM MAIN
      // SHOWS PARENT DIV
      if (this.isRbrSelectValue) {
        this.selectionListTarget.parentElement.classList.remove(this.hiddenClass)
        this.selectionListTarget.classList.add(this.flexClass)
        this.toggleInstructions()
      }
    }

    // If the given section is empty then we remove the hidden class and add the flex class.
    if (sectionSelections.length <= 0) {
      targetSection.classList.remove(this.hiddenClass)
      targetSection.classList.add(this.flexClass)
    }

    targetSection.innerHTML += `<div class="mx-05 acuity-selection-item">
        ${name}
        <a data-action="acuity-menu#toggleSelected acuity-multi-select#removeFromMultiSelect"
           data-acuity-multi-select-target="selection"
           data-acuity-multi-select-id-param="${id}"
           data-acuity-multi-select-section-id-param="${sectionId}"
           data-acuity-multi-select-section-param="${section}"
           data-acuity-menu-section-param="${sectionId}"
           data-acuity-menu-id-param="${id}"
           class="ml-1 pa-1 acuity-selection-link">
          <i class="fas fa-times"></i></a>
      </div>`

    this.updateSelectedIds(id, false)
    this.updateListInstructions()
    this.onChangeAcuityFilter()
  }

  /*
   * Update the list instructions by showing or hiding any or all option
  */
  updateListInstructions () {
    // Selection list instructions are optional so we can return if there aren't any
    if (!this.hasSelectionListContainerInstructionsTarget) { return }

    if (this.selectedIds.length >= 2) {
      this.selectionListContainerInstructionsTarget.classList.remove(this.hiddenClass)
    } else {
      this.selectionListContainerInstructionsTarget.classList.add(this.hiddenClass)
    }
  }

  /*
   * Update the list instructions by showing or hiding the percentage filter option
  */
  updatePercentageFilterOption () {
    if (this.selectionTypeTarget.options[this.selectionTypeTarget.selectedIndex].value === 'product_line_rbr_ids') {
      this.percentageFilterContainerTarget.classList.remove(this.hiddenClass)
    } else {
      this.percentageFilterContainerTarget.classList.add(this.hiddenClass)
    }
  }

  /*
   * Gets the current selections in the target section
   * @param targetSection {HTMLObject}
   * @return {HTMLObject}
  */
  selectionsPerTargetSection (targetSection) {
    let selector = '[data-acuity-multi-select-target="selection"]'
    if (this.filterControllerNameValue.includes('base')) {
      // should work if defined with acuity-base-multi-select
      // but ideally we should use data-acuity-multi-select
      selector = '[data-acuity-base-multi-select-target="selection"]'
    }

    return targetSection.querySelectorAll(selector)
  }

  /*
   * Gets the current selections in the target section
   * @param targetSection {HTMLObject}
   * @param id {Integer} item id in target section
   * @return {HTMLObject} target item link
  */
  targetItem (targetSection, id) {
    let selector = 'data-acuity-multi-select-id-param'
    if (this.filterControllerNameValue.includes('base')) {
      // should work if defined with acuity-base-multi-select
      // but ideally we should use data-acuity-multi-select
      selector = 'data-acuity-base-multi-select-id-param'
    }
    return targetSection.find(target => target.getAttribute(selector) === id.toString())
  }

  /*
   * @param id {Number}
   * @param sectionId {Number}
  */
  removeFromMultiSelectHelper (id, sectionId) {
    const targetSection = this.findTargetSectionHelper(sectionId)

    // Get the selections of the given section.
    const sectionSelections = Array.from(this.selectionsPerTargetSection(targetSection))
    const targetItem = this.targetItem(sectionSelections, id)

    if (targetItem === undefined) { return }

    targetItem.parentElement.remove()

    // If the given section is empty then we add the hidden class and remove the flex class.
    if (this.selectionsPerTargetSection(targetSection).length <= 0) {
      targetSection.classList.remove(this.flexClass)
      targetSection.classList.add(this.hiddenClass)
    }

    if (this.sectionTargets.every(section => section.classList.contains(this.hiddenClass))) {
      this.selectionListTarget.classList.add(this.hiddenClass)

      // NOTE: THIS IS DIFFERENT FROM MAIN
      // HIDES PARENT DIV
      if (this.isRbrSelectValue) {
        this.selectionListTarget.parentElement.classList.add(this.hiddenClass)
        this.selectionListTarget.classList.remove(this.flexClass)
        this.toggleInstructions()
      }
    }

    // Update checkboxes and counts in the menu
    this.updateMenuSelectionCount(event)

    this.updateSelectedIds(id, true)
    this.updateListInstructions()
    this.onChangeAcuityFilter()
  }

  // Display or hide instructions based on the number of visible multi-selection containers
  toggleInstructions () {
    const hiddenSelectionsContainer = this.selectionListContainerTargets.filter(target => target.classList.value.includes(this.hiddenClass))
    if (hiddenSelectionsContainer.length === this.selectionListContainerTargets.length) {
      // show instructions if all sections are hidden
      this.instructionsTarget.classList.remove(this.hiddenClass)
    } else {
      // hide instructions
      this.instructionsTarget.classList.add(this.hiddenClass)
    }
  }

  // For clearing all selections at once
  clearAllSelectionsHelper () {
    const hiddenClass = this.hiddenClass
    const flexClass = this.flexClass

    // clear checkbox state and current selection state
    this.clearCheckboxes()
    this.selectedIds = []
    this.selectionTargets.forEach(function (targetItem) {
      targetItem.parentElement.remove()
    })

    // hide UI
    this.sectionTargets.forEach(function (section) {
      section.classList.add(hiddenClass)
      section.classList.remove(flexClass)
    })

    this.selectionListTarget.classList.add(hiddenClass)
    this.selectionListTarget.classList.remove(flexClass)

    this.selectionListContainerTargets.forEach(function (container) {
      container.classList.add(hiddenClass)
      container.classList.remove(flexClass)
    })

    if (this.hasInstructionsTarget) {
      this.toggleInstructions()
    }
  }

  // For clearing the checkbox state
  clearCheckboxes () {
    const selector = this.checkboxContainerSelectorValue + ' input[type="checkbox"]'
    const checkboxes = document.querySelectorAll(selector)
    checkboxes.forEach(function (checkbox) {
      checkbox.checked = false
    })
  }
}
