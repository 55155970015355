import BaseController from '../base_controller'

export default class extends BaseController {
  static values = {
    data: String,
    selector: String,
    groupLabel: String,
    drillDownEnabled: Boolean,
    rootDataHashKey: String,
    breadCrumbText: String
  }

  connect () {
    super.connect()
    this.renderStackedBarChart()
  }

  disconnect () {
    super.disconnect()
  }

  // Render the stacked bar chart
  renderStackedBarChart () {
    const data = JSON.parse(this.dataValue)
    const stackedBarChart = new Cog.StackedBarChart(this.selectorValue, this.rootDataHashKeyValue, data, { groupLabel: this.groupLabelValue, drillDownEnabled: this.drillDownEnabledValue, breadCrumbText: this.breadCrumbTextValue })
    stackedBarChart.draw()
  }
}
