import BaseController from './base_controller'

class NotiflyAppendController extends BaseController {
  connect () {
    super.connect()
    window.display_notifly()
  }
}

export default NotiflyAppendController
