import BaseController from './base_controller'
import { fire } from 'lib/compatibility'
import { debounce } from 'lodash'

export default class extends BaseController {
  static targets = [
    'form', // filter form
    'field', // filter field in the filter form
    'turboFrameField' // field has value for the filter form's data-turbo-frame
  ]

  initialize () {
    this.submitForm = debounce(this.submitForm, 250)
  }

  connect () {
    super.connect()
    global.Cog.portfolioFilterFormController = this
  }

  disconnect () {
    super.disconnect()
    delete global.Cog.portfolioFilterFormController
  }

  // Sets value for the filter form's data-turbo-frame
  setFormTurboFrame () {
    this.formTarget.dataset.turboFrame = this.turboFrameFieldTarget.value
  }

  // Sets value for hidden field
  setFieldValue (filterFieldSelector, value) {
    const field = this.formTarget.querySelector(filterFieldSelector)
    field.value = value
  }

  // Submits the formSelector form
  submitForm () {
    // Setup the value for the filter form's data-turbo-frame
    this.setFormTurboFrame()
    // Submit form
    fire(this.formTarget, 'submit')
  }

  // @return [String] an URL is constructing based on field values in the filter form
  //  e.g http://host/portfolios?field1=value1&field2=value2
  url () {
    const baseUrl = window.location.origin
    let url = baseUrl + '/portfolios'

    this.fieldTargets.forEach((field, ind, _array) => {
      const id = field.id
      const value = field.value

      if (value.toString().trim() !== '') {
        if (ind === 0) {
          url += '?' + id + '=' + value
        } else {
          url += '&' + id + '=' + value
        }
      }
    })

    return url
  }
}
