import BaseController from './base_controller'

// A controller for clearing an element if user clicks outside of it's inside targets. The target element is replaced
// with a div with the same ID
export default class extends BaseController {
  static targets = ['element', 'inside']

  hideIfOutsideClick (event) {
    if (event && this.insideTargets.find(element => element === event.target)) {
      // If click event is on an "inside" element, do nothing
      return
    }
    // If click event is not an "inside" element, hide the element
    this.hide()
  }

  hide () {
    // Create new element that's just a div with the current element's ID (so it can be replaced with a turbostream again)
    const newElement = document.createElement('div')
    newElement.id = this.elementTarget.id

    // Repalce current element with new element with only the same ID
    this.elementTarget.replaceWith(newElement)
  }
}
