import * as d3 from 'd3'

/**
 * Draw the bar chart given the data
 * @param {Array} data - The data to be used in the chart
 * @param {String} containerSelector - The container for the chart to be drawn in
 */
export function drawBarCharts (data, containerSelector) {
  // options for the chart
  const barHeight = 30
  const marginTop = 5
  const marginRight = 10
  const marginBottom = 20
  const marginLeft = 370
  const graphWidth = 695
  const spaceBetweenBars = 8
  const namePadding = 65 // Padding for the barcode name (how far away from the axis the name is)
  const percentPadding = 5 // Padding for the % value displayed for a barcode. (how far away from the axis the % value is)
  const height = Math.ceil(data.length * barHeight) + (spaceBetweenBars * (data.length + 1)) + marginTop + marginBottom
  const darkTextColor = '#000010'
  const lightTextColor = '#00062060'
  const darkBarColor = '#0033A0'
  const lightBarColor = '#E5EAF5'

  // Create an SVG to store the graph on
  const svg = d3.select(containerSelector)
    .append('svg')
    .attr('width', graphWidth + marginLeft + marginRight)
    .attr('height', height + marginTop + marginBottom)
    .append('g')
    .attr('transform', 'translate(' + marginLeft + ',' + marginTop + ')')

  // define the x scale of the chart
  const x = d3.scaleLinear()
    .domain([0, 100])
    .range([0, graphWidth - marginRight])

  // draw the x axis
  svg.append('g')
    .style('font', '12px open sans')
    .attr('transform', 'translate(0,' + (marginTop + (spaceBetweenBars * (data.length + 1)) + (barHeight * data.length)) + ')')
    .call(d3.axisBottom(x).tickPadding(10).tickSize(0).ticks(5).tickFormat(d => d + '%'))
    .selectAll('text')
    .style('text-anchor', 'middle')
    .style('font-weight', '600')

  svg.selectAll('.domain')
    .attr('stroke', '#D6DBE6')

  // define the y scale of the chart
  const y = d3.scaleBand()
    .range([0, height])
    .domain(data.map(function (d) { return d.name }))
    .rangeRound([marginTop, height - marginBottom])

  // draw the names for the y axis
  svg.append('g')
    .style('font', '14px open sans')
    .classed('barcode-name', true)
    .call(d3.axisLeft(y).tickSize(0).tickPadding(namePadding))
    // Remove the axis line - it is drawn as part of the grid lines
    .call(g => g.select('.domain').remove())

  const labelMaxWidth = marginLeft - namePadding
  svg.selectAll('.barcode-name text')
    .call(truncateText, labelMaxWidth)

  // Find each axis label and color it based on the matching data in filter attribute
  d3.selectAll(containerSelector + ' .barcode-name .tick').each(function (_e, index) {
    d3.select(this).select('text').attr('fill', function () {
      return data[index].product_line_rbr_is_in_filter ? darkTextColor : lightTextColor
    })
  })

  // Draw the Percents for the Product Lines so they line next to the percent
  // The product line names are drawn separately to manage the space between the name and percent value on the axis
  const yBarcodePercents = d3.scaleBand()
    .range([0, height])
    .domain(data.map(function (_d, index) { return index }))
    .rangeRound([marginTop, height - marginBottom])
  svg.append('g')
    .style('font', '14px open sans')
    .style('font-weight', '600')
    .classed('barcode-percent', true)
    .call(d3.axisLeft(yBarcodePercents)
      .tickSize(0)
      .tickPadding(percentPadding)
      .tickFormat(tick => data[tick].summed_normalized_metric_percentage.toFixed(1) + '%'))
    // Remove the axis line - it is drawn as part of the grid lines
    .call(g => g.select('.domain').remove())

  d3.selectAll(containerSelector + ' .barcode-percent .tick').each(function (_e, index) {
    d3.select(this).select('text').attr('fill', function () {
      return data[index].product_line_rbr_is_in_filter ? darkTextColor : lightTextColor
    })
  })

  // Add tooltip to product line labels that are truncated, or
  // Add tooltip to product line labels that are not part of the filter
  d3.selectAll(containerSelector + ' .barcode-name .tick text')
    .call(tooltipText, data, 'truncated')

  // Draw the bars
  svg.selectAll(containerSelector + ' rect')
    .data(data)
    .enter()
    .append('rect')
    .attr('x', x(0))
    .attr('y', function (d) { return y(d.name) + 3 })
    .attr('width', function (d) { return x(d.summed_normalized_metric_percentage) })
    .attr('height', y.bandwidth() - spaceBetweenBars)
    .attr('fill', function (d) {
      if (d.product_line_rbr_is_in_filter) {
        return darkBarColor
      } else {
        return lightBarColor
      }
    })

  // Draw vertical lines on grid
  svg.selectAll('line .verticalGrid')
    .data(x.ticks(5))
    .enter()
    .append('line')
    .classed('verticalGrid', true)
    .attr('x1', function (d) { return x(d) })
    .attr('x2', function (d) { return x(d) })
    .attr('y1', 0)
    .attr('y2', height - marginBottom)
    .attr('fill', 'none')
    .attr('stroke', '#D6DBE6')
    .attr('stroke-width', '1px')
}

/**
 * Add tooltip to product line labels that are truncated, or
 * Add tooltip to product line labels that are not part of the filter
 * @param {Selection} text - The text dataset.
 * @param {Array} data - Data contains product line name.
 * @param {String} truncated - CSS name to find the text is truncated or not.
 */
function tooltipText (text, data, truncated) {
  text.each(function (_e, index) {
    const _this = d3.select(this)
    const isTruncated = _this.classed(truncated)

    const d = data[index]
    const isNotInFilter = !d.product_line_rbr_is_in_filter

    let text = ''
    if (isTruncated && d.name.length > 0) {
      text += d.name + '.<br>'
    }
    if (isNotInFilter) {
      text += 'This product line is unrelated to your current query.'
    }

    if (text.length > 0) {
      _this
        .attr('data-controller', 'acuity-tippy-tooltip')
        .attr('data-acuity-tippy-tooltip-target', 'trigger')
        .attr('data-acuity-tippy-tooltip-placement-value', 'top')
        .attr('data-acuity-tippy-tooltip-theme-value', 'acuity')
        .attr('data-acuity-tippy-tooltip-max-width-value', 'auto')
        .append('div')
        .attr('data-acuity-tippy-tooltip-target', 'content')
        .html(text)
    }
  })
}

/**
 * Truncate text to not extend past a given width
 * @param {Selection} text - The text dataset that needs to be truncated
 * @param {Number} maxWidth - The max width for text in pixels
 */
function truncateText (text, maxWidth) {
  text.each(function () {
    const text = d3.select(this)

    // Guard clause, if the text is not too long, we do not need truncate
    if (text.node().getComputedTextLength() < maxWidth) {
      return
    }

    const words = text.text().split(/\s+/)
    const line = ['...']
    // Reset the text to empty
    text.classed('truncated', true)
    text.text('')

    for (const word of words) {
      line.splice(-1, 0, word)
      text.text(line.join(' '))
      if (text.node().getComputedTextLength() > maxWidth) {
        // Remove last word that put the text over the max width
        line.splice(-2, 1)
        text.text(line.join(' '))
        break
      }
    }
  })
}
