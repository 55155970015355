import AcuityBaseController from './acuity_base_controller'
import { fire, getUrlFromFields } from '../lib/form_events'
import { debounce } from 'lodash'
import * as Turbo from '@hotwired/turbo'

export default class AcuityFilterController extends AcuityBaseController {
  static targets = ['activeField']
  // values for corresponding hidden field and company filter form
  static values = {
    name: String, // name of controller instance to access later
    formSelector: String,
    fieldSelector: String,
    resetBasePath: String,
    resetButtonSelector: String,
    resetButtonField: String
  }

  connect () {
    super.connect()
    global.Acuity.filterControllers ||= {}
    global.Acuity.filterControllers[this.nameValue] = this
  }

  disconnect () {
    super.disconnect()
    global.Acuity.filterControllers[this.nameValue] = null
  }

  initialize () {
    this.submitFilterForm = debounce(this.submitFilterForm, 250)
    this.delayedChange = debounce(this.delayedChange, 500).bind(this)
  }

  /**
   * Copies value from visible form into hidden forms
   */
  change () {
    this.cleanValue()
    this.setHiddenValue()
    this.submitFilterForm()
  }

  /**
   * like change(), but fires after a delay when called
   */
  delayedChange () {
    this.change()
  }

  /**
   * Sets value for hidden field
   */
  setHiddenValue () {
    const hiddenFieldSelector = this.hiddenFieldSelector()
    const hiddenField = document.querySelector(hiddenFieldSelector)

    hiddenField.value = this.activeFieldTarget.value
  }

  /**
   * @return {String} selector for hidden field
   */
  hiddenFieldSelector () {
    return this.formSelectorValue + ' ' + this.fieldSelectorValue
  }

  /**
   * Submits values in hidden form
   */
  submitFilterForm () {
    const hiddenForm = document.querySelector(this.formSelectorValue)
    const url = getUrlFromFields(hiddenForm.querySelectorAll('input'))
    Turbo.navigator.history.push(new URL(url))

    fire(hiddenForm, 'submit')
  }

  /**
   * Clean values set in the field if min/max values are defined
   */
  cleanValue () {
    const min = parseInt(this.activeFieldTarget.min)
    const max = parseInt(this.activeFieldTarget.max)

    // field is a number field and either min or max value have been defined
    if (this.activeFieldTarget.type === 'number' && (!isNaN(min) || !isNaN(max))) {
      const currValue = parseInt(this.activeFieldTarget.value)
      if (currValue < min) {
        this.activeFieldTarget.value = min
      } else if (currValue > max) {
        this.activeFieldTarget.value = max
      }
    }
  }

  /**
   * Retain some of the filters applied
   */
  preventReset () {
    document.querySelector(this.resetButtonSelectorValue).href = this.resetBasePathValue +
      `?${this.resetButtonFieldValue}=${this.activeFieldTarget.value}`
  }
}
