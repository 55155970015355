import BaseController from './base_controller'
import Swal from 'sweetalert2/dist/sweetalert2.js'

// Reference: https://github.com/sweetalert2/sweetalert2/blob/040a2fc9281a2abda8532f3164a464e184cf07cb/src/utils/params.js
// Notes:
// https://stackoverflow.com/questions/57019404/how-to-remove-class-from-button-and-add-custom-class-in-sweetalert2
// https://sweetalert2.github.io/#customClass
// - buttonsStyling - remove default styling classes from SWAL2
// - customClass - append custom class to specified elements

// The dark overlay for when the modal is open is a little lighter than the one we have in the app

export default class extends BaseController {
  static classes = ['confirmBtnCss', 'cancelBtnCss']
  static values = {
    template: String
  }

  showDialogue (event) {
    const form = event.currentTarget.parentElement
    event.preventDefault()

    // render modal with styling
    Swal.fire({
      template: `#${this.templateValue}`,
      showDenyButton: false,
      reverseButtons: true, // change button order to (L-> R: cancel, confirm)
      buttonsStyling: false,
      customClass: {
        confirmButton: this.confirmBtnCssClasses,
        cancelButton: this.cancelBtnCssClasses,
        actions: 'mx-5 my-0 justify-flex-end'
      },
      width: '570px',
      focusConfirm: false,
      focusCancel: false,
      showClass: {
        popup: '' // use '' if you don't want any animation
      },
      hideClass: {
        popup: ''
      }
    }).then((result) => {
      if (result.isConfirmed) {
        // continue submitting form
        form.submit()
      }
    })
  }
}
