import BaseController from './base_controller'
import { fire } from 'lib/compatibility'
import { debounce, isEqual } from 'lodash'

export default class StateController extends BaseController {
  static values = { name: String, timeout: Number }
  static targets = ['requestId']

  initialize () {
    this.updateState = debounce(this.forceUpdateState, this.timeoutValue)
  }

  connect () {
    super.connect()
    global.Cog.stateControllers ||= {}
    global.Cog.stateControllers[this.nameValue] = this
  }

  disconnect () {
    super.disconnect()
    global.Cog.stateControllers[this.nameValue] = null
  }

  // Track an of unloaded request.
  // This can be used to track lazy-loaded components that are not yet loaded.
  // Changing the UI state invalidates this list.
  trackUnloaded (key, request) {
    this.unloaded ||= {}
    this.unloaded[key] = request
  }

  // Remove a request from the unloaded list.
  untrackUnloaded (key) {
    const v = this.unloaded[key]
    delete this.unloaded[key]
    return v
  }

  // Lookup a request from the unloaded list.
  isUnloaded (key) {
    return this.unloaded[key]
  }

  fieldElement (fieldName) {
    return this.element.querySelector(`[name='${fieldName}']`)
  }

  cleanValue (value) {
    if (Array.isArray(value)) {
      return value.map((str) => `"${str}"`).join(',')
    } else {
      return value
    }
  }

  // Submit any queued state changes to the controller right now.
  forceUpdateState () {
    this.requestIdTarget.value = this.currentRequestId() + 1
    this.unloaded = {}
    fire(this.element, 'submit')
  }

  // Use this method to update the state.
  // Takes in an object with keys being the name of fields managed by the
  // state manager and values that they will change to.
  // A value can be an array. It will be converted to a comma seperated string.
  updateValues (hash) {
    const originalState = this.currentState()
    this.silentUpdateValues(hash)
    const newState = this.currentState()

    if (!isEqual(originalState, newState)) {
      fire(this.element, 'update_state', { controllerName: this.nameValue, state: newState })
      this.updateState()
    }
  }

  // @param array [Array<String>] array of strings with quotes
  // @return [Array<String>] clean array with extra quotes removed
  cleanArrayQuotes (array) {
    return array.map(function (element) {
      const firstLetter = element.charAt(0)
      const lastLetter = element.slice(-1)

      if (firstLetter === '"' && lastLetter === '"') {
        return element.substring(1, element.length - 1)
      } else {
        return element
      }
    })
  }

  // Update the state without queueing the changes up to be submitted to the
  // controller. This should only be called by responses from the controller.
  silentUpdateValues (hash) {
    for (const key in hash) {
      this.fieldElement(key).value = this.cleanValue(hash[key])
    }
  }

  currentRequestId () {
    return parseInt(this.requestIdTarget.value)
  }

  // Returns the current state in an object with the field as the key.
  // Lists are not converted from strings to arrays.
  currentState () {
    const state = {}
    this.element.querySelectorAll("input[type='hidden']").forEach((element) => {
      if (element === this.requestIdTarget || element.name === '_method') {
        return
      }

      if (element.dataset.array) {
        const values = element.value.split(',')
        state[element.name] = this.cleanArrayQuotes(values)
      } else {
        state[element.name] = element.value
      }
    })
    return state
  }
}
