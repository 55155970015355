import { handleCheckboxStateUpdate, handleJsonStateUpdate, handleKeyValueStateUpdate, isEnabled } from './helpers'

/*
 * Update state from key/value attributes
 */

// Clicking on a button or link.
document.addEventListener('click', (event) => {
  const selector = 'button[data-state-key], a[data-state-key]'
  const element = event.target.closest(selector)
  if (isEnabled(element)) {
    handleKeyValueStateUpdate(event, element)
  }
})

// Changing a form key-value form element
document.addEventListener('change', (event) => {
  const selector = 'select[data-state-key], input[type=\'radio\'][data-state-key], input[type=\'number\'][data-state-key]'
  const element = event.target.closest(selector)
  if (isEnabled(element)) {
    handleKeyValueStateUpdate(event, element)
  }
})

// Changing a state from a checkbox
document.addEventListener('change', (event) => {
  const selector = 'input[type=\'checkbox\'][data-state-key]'
  const element = event.target.closest(selector)
  if (isEnabled(element)) {
    handleCheckboxStateUpdate(event, element)
  }
})

/*
 * Update state from JSON attribute
 */
document.addEventListener('click', (event) => {
  const selector = 'button[data-state], a[data-state], input[type=\'radio\'][data-state]'
  const element = event.target.closest(selector)
  if (isEnabled(element)) {
    handleJsonStateUpdate(event, element)
  }
})
