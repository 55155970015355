import AcuityBaseMultiSelectController from './acuity_base_multi_select_controller'

export default class AcuityMultiSelectController extends AcuityBaseMultiSelectController {
  // Add multi select item from the list
  // @param name {String} name of the item
  // @param id {Number} id of the item to add
  // @param sectionId {Number} id of the section the item will be in
  // @param section {String} correspond section to add the multi-select item to
  addToMultiSelect ({ params: { name, sectionId, id, section } }) {
    this.addToMultiSelectHelper(name, sectionId, id, section)
  }

  // Either add the selected item or remove it
  // @param event {Event} the toggle event
  toggleSelected (event) {
    if (!event.target.checked) {
      this.removeFromMultiSelect(event)
    } else {
      this.addToMultiSelect(event)
    }
  }

  // Remove multi select item from the list
  // @param id {Number} id of the item to remove
  // @param sectionId {Number} id of the section the item is in
  removeFromMultiSelect ({ params: { id, sectionId } }) {
    this.removeFromMultiSelectHelper(id, sectionId)
  }

  // Finds the desired section of the multi select list
  // @param sectionId {String} the id of the desired section
  // @return {HTML} the section target with the matching section id
  findTargetSection (sectionId) {
    return this.findTargetSectionHelper(sectionId)
  }

  // Clear all selections and reset the UI
  clearAllSelections () {
    this.clearAllSelectionsHelper()
  }
}
