import BaseController from './base_controller'
import { fire } from 'lib/compatibility'

// Use this class to toggle an element's state based on another element
export default class extends BaseController {
  // Anchor: main target you want to see if there's been changes on
  // Form: associated form to trigger request
  static targets = ['anchor']
  static classes = ['visible']
  static values = {
    formSelector: String,
    containerSelector: String
  }

  connect () {
    super.connect()
    const config = { childList: true }
    this.form = document.querySelector(this.formSelectorValue)
    this.container = document.querySelector(this.containerSelectorValue)

    const callback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        // only send request if:
        // 1. anchor is visible
        // 2. changes were made on the anchor's DOM
        if (mutation.type === 'childList') {
          this.submitForm()
        }
      }
    }

    this.observer = new MutationObserver(callback)
    this.observer.observe(this.anchorTarget, config)
  }

  disconnect () {
    this.observer.disconnect()
  }

  // Submit the associated form if the DOM is visible
  submitForm () {
    if (this.containerIsVisible()) {
      fire(this.form, 'submit')
    }
  }

  // @return [Boolean] TRUE if the anchor is visible
  containerIsVisible () {
    return this.container.classList.contains(this.visibleClass)
  }
}
