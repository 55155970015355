import BaseController from './base_controller'
import { fire } from 'lib/compatibility'

// clear value in field and submit the related form
export default class extends BaseController {
  static targets = ['field', 'form']

  // Clear value in field and submit form
  clearValueAndSubmit () {
    this.fieldTargets.forEach((target) => {
      target.value = ''
      // remove attribute valid to prevent weird styling
      if (target.hasAttribute('valid')) {
        target.removeAttribute('valid')
      }
    })

    fire(this.formTarget, 'submit')

    this.dispatch('field-cleared')
  }
}
