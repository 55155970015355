import AcuityBaseController from './acuity_base_controller'
// NOTE: Need to include 'followCursor' since it is a plugin https://atomiks.github.io/tippyjs/v6/plugins/
import tippy, { followCursor } from 'tippy.js'

export default class AcuityTippyTooltipController extends AcuityBaseController {
  static targets = ['trigger', 'content']
  static values = {
    placement: { type: String, default: 'bottom' }, // options: https://atomiks.github.io/tippyjs/v6/all-props/#placement
    arrow: { type: Boolean, default: true },
    duration: { type: Array, default: [300, 250] }, // transition duration [show duration, hide duration] https://atomiks.github.io/tippyjs/v6/all-props/#duration
    interactive: { type: Boolean, default: true }, // interactive determines if the tippy has interactive content inside of it, so that it can be hovered over and clicked inside without hiding. https://atomiks.github.io/tippyjs/v6/all-props/#interactive
    maxWidth: { type: Number, default: 485 }, // max width of tooltip; numbers in px; can use 'none'
    offset: { type: Array, default: [0, 10] }, // offset [skidding, distance] is to position the tooltip relative the trigger element https://popper.js.org/docs/v2/modifiers/offset
    theme: { type: String, default: 'acuity' },
    followCursor: { type: Boolean, default: false }, // Follow the cursor as the cursor moves https://atomiks.github.io/tippyjs/#follow-cursor
    animation: { type: String, default: 'fade' }, // Define the animation we want to use for tippy. The Tippy default is fade https://atomiks.github.io/tippyjs/v6/all-props/#animation
    trigger: { type: String, default: 'mouseenter focus' } // Define when the tippy tooltip should be displayed https://atomiks.github.io/tippyjs/v6/all-props/#trigger
  }

  initialize () {
    this.initPopup()

    this.contentTarget.style.display = 'none'
  }

  initPopup () {
    tippy(this.triggerTarget, {
      placement: this.placementValue,
      content: this.contentTarget.innerHTML,
      allowHTML: true,
      appendTo: () => document.body, // If interactive: true, the default behavior is appendTo: "parent"
      arrow: this.arrowValue,
      duration: this.durationValue,
      interactive: this.interactiveValue,
      maxWidth: this.maxWidthValue,
      offset: this.offsetValue,
      theme: this.themeValue,
      followCursor: this.followCursorValue,
      animation: this.animationValue,
      plugins: [followCursor],
      trigger: this.triggerValue
    })
  }
}
