import BaseController from './base_controller'
import { fire } from 'lib/compatibility'

// This class will submit a form using JS, instead of relying on default behavior
export default class extends BaseController {
  static targets = ['form', 'field']

  // Submit the form target
  // Use compatibility fire function to submit form
  submitForm () {
    const trackingChanged = this.formTarget.hasAttribute('changed')

    // If changed is being tracked and it has not been changed, do not submit the form
    if (trackingChanged && this.formTarget.getAttribute('changed') === 'false') {
      return
    }

    fire(this.formTarget, 'submit')

    // If changed is being tracked, set it to false after the form has submitted
    if (trackingChanged) {
      this.formTarget.setAttribute('changed', 'false')
    }
  }

  // Check each field in a form for it's validity before submitting. Prevent submission if invalid
  // @param event [Event] an event that triggers the submitIfValid function
  submitIfValid (event) {
    // Check if the triggering event is a CustomEvent to submit. Custom events should always have isTrusted be false
    // If the event is a custom submit event - do nothing, allowing the event to propogate and finish.
    // Do not submit again, as this will cause an infinite loop
    if (event instanceof CustomEvent && event.type === 'submit' && !event.isTrusted) {
      return
    }

    // Check if the event is a Submit Event and trusted. That indicates the SubmitEvent was created by a browser
    // An event where isTrusted is true and has a submit Type should always be a SubmitEvent.
    if (event.type === 'submit' && event.isTrusted) {
      // Turbo has a listener for SubmitEvents. If the SubmitEvent propogates, then turbo will submit the form, even after
      // our validation check. We need to stop Propagation here, and let our custom submit event (which only fires if valid) trigger Turbo
      event.stopImmediatePropagation()
      event.preventDefault()
    }

    // Check each field in a form to see if they are enabled and valid
    const valid = this.fieldTargets.every(field => {
      if (!field.hasAttribute('disabled') && field.hasAttribute('valid') && field.getAttribute('valid') === 'false') {
        return false
      }
      return true
    })

    if (valid) {
      this.submitForm()
    }
  }
}
