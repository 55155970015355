import BaseController from './base_controller'

// This class controls what actions to take when user interact with radio buttons within the group
export default class extends BaseController {
  // place controller on the element that contains all related radio buttons
  // place following dispatch action example on each radio button where radio-eable-disable-state-controller is assigned
  // data-action="radio-group:radio-button-switch@document->radio-enable-disable-state#methodToTrigger"

  // Dispatches target controller (i.e. radio-eable-disable-state-controller) on each radio in the group
  // when above data-action is used
  changeRadioState () {
    this.dispatch('radio-button-switch')
  }
}
