import BaseController from './base_controller'

export default class AppendableController extends BaseController {
  static targets = ['appended', 'template', 'container']

  append () {
    const newNode = this.templateTarget.content.firstElementChild.cloneNode(true)
    this.containerTarget.append(newNode)
  }

  remove (event) {
    event.target.closest("[data-appendable-target='appended']").remove()
  }
}
