import BaseController from './base_controller'
import { currentDataFetchControllerInstance } from '../lib/portfolios/helpers'

export default class extends BaseController {
  // entity = each column heading
  // updateAllFetchDataFlag to TRUE
  // copy col name and direction to form
  // update icon to next sort direction
  // submit form
  // updateCurrentFetchDataFlag to FALSE

  static values = {
    fieldSortColumnSelector: String,
    fieldSortDirectionSelector: String,
    sortColumnName: String,
    nextSortDirection: String
  }

  sort () {
    // update all flags to TRUE
    this.updateAllFetchDataFlag(true)

    // copy values to hidden form
    this.updateFilterForm()

    // Submit the form
    const formCtrlInstance = Cog.portfolioFilterFormController
    formCtrlInstance.submitForm()

    // update current tab's flag to FALSE
    this.updateCurrentFetchDataFlag(false)
  }

  // set values for hiddent fields
  updateFilterForm () {
    const formCtrlInstance = Cog.portfolioFilterFormController
    const sortColumnField = this.fieldSortColumnSelectorValue
    const sortDirectionField = this.fieldSortDirectionSelectorValue

    formCtrlInstance.setFieldValue(sortColumnField, this.sortColumnNameValue)
    formCtrlInstance.setFieldValue(sortDirectionField, this.nextSortDirectionValue)
    formCtrlInstance.setFormTurboFrame()
  }

  // update all fetchDataFlag
  updateAllFetchDataFlag (booleanValue) {
    currentDataFetchControllerInstance().updateAll(booleanValue)
  }

  // update current fetchDataFlag
  updateCurrentFetchDataFlag (booleanValue) {
    currentDataFetchControllerInstance().updateCurrent(booleanValue)
  }
}
