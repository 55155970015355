import { merge } from "lodash";
import { StockChartLegend } from "./stock_chart_legend";
import { filterTraceIndexByUpdatemenuSelection } from './utilities';
import ahoy from 'ahoy.js';

// This stock chart object can be used to create a variety stock charts.
// It relies on utilities.js for viewport normalization to work.
//
// Options:
//   title: Set the title of the stock chart.
//   normalization:
//     These options only have an effect if enable is set to true.
//     enable: Normalizes all inputted series by their starting point on the chart. Good for seeing the growth of $100.
//     normalization_factor: The starting value for normalization. Set to 100 to see the growth of $100.
//     normalize_to_viewport: The normalizes the data based on the first value in the user's viewport as they pan and
//       resize. NOTE: This is expensive and reduces the number of series we can efficiently display and update rapidly.
//       Use a higher debounce time if you expect to display many datasets side by side.
//     debounce_time: Viewport normalization uses a debounced method to increase slider performance.
//       This sets the minimum number of milliseconds to wait between normalization redraws.
//   rolling_averages:
//     default: The default view mode selection. Allowed values are 'all', 'original', or the index of an available average.
//     available_averages: An array of objects with the name for the average, and years to use for the mean calculation.
//       {name: "Name", years: 1}
//   height: The height of the chart.
//   width: The width of the chart. This has no effect if responsive is set to true.
//   range_selection:
//     These options only have an effect if enable is set to true.
//     enable: Set to true to enable quick range selection buttons atop the graph.
//     year_options: An array of years to have quick zoom ranges for.
//     suffix_text: The text to show as a suffix.
//     include_all: Includes a button to reset to the full range of the graph.
//   enable_range_slider: Set to false to disable the range slider below the graph.
//   responsive: Set to false, to disable responsive width adjustments.
//     You can then use width to set a pixel width on the chart.
//   show_legend: Set to false to disable plotly's legend.
//   show_custom_legend: Will have legend methods refer to an external custom made HTML legend
//   custom_legend: default null, contains StockChartLegend object
//   custom_legend_options: default null, contains selectors for associated custom legend and its legend items
//   show_modebar: Set to false to disable the menu items that appear when the chart is hovered upon.
//   prevent_timezone_conversion: If true, will prevent the user's timezone from affecting the dates displayed on the chart.
//   static_plot: If true, no interactions will be allowed with the stock chart (except hovering tooltips).
//   hoverinfo: decide whether stockchart need to render hovering tooltips, enabled with 'all', disabled with 'skip'.
//   hovertext: text that can be displayed on hover tooltips, work like a var that can be mapped in order to the this trace's (x,y) coordinates.
//   hovertemplate: Template string used for rendering the information that appear on hover box. Default: ""
//     Note that this will override `hoverinfo`. Variables are inserted using %{variable}, for example "y: %{y}".
//     Anything contained in tag `<extra>` is displayed in the secondary box, for example "<extra>{fullData.name}</extra>".
//     To hide the secondary box completely, use an empty tag `<extra></extra>`.
//   hoverlabel_bgcolor: Sets the background color of the hover labels
//   xaxis: used for toggling elements specific to the x-axis
//     showgrid: Set to false to disable the grid lines for x-axis
//     zeroline: Set to false to disable the base lines for x-axis
//     showticklabels: Set to false to disable the ticker for x-axis
//     showspikes: whether to show a vertical cross hair line when hovering on the chart
//     spikethickness: spike line thickness
//     spikecolor: spike line color
//     spikemode: length of spike line (whether to closest axes or across the whole chart)
//     spikedash: styling of spike line
//     spikesnap: whether spike line relative to data point or cursor
//     rangeselector: determine data timeframe
//       buttons: options for date ranges
//       bgcolor: default button background color
//       activecolor: active button background color
//   yaxis: used for toggling elements specific to the y-axis
//     title: Title for Y axis (shown vertically)
//     showgrid: Set to false to disable the grid lines for y-axis
//     zeroline: Set to false to disable the base lines for y-axis
//     showticklabels: Set to false to disable the ticker for y-axis
//   paper_bgcolor: Sets the color of paper where the graph is drawn
//   plot_bgcolor: Sets the color of plotting area in-between x and y axes
//   annotations: Adds text to label navigation items and/or points on the graph
//   spikedistance: determine how far from cursor and data point to display spike lines if enabled
//                  (-1 to always display)
//   extended_distance: how far to extend hover area to prevent cut off on small widths

function StockChart(selector, options) {

  var default_options = {
    title: 'Stock Chart',
    normalization: {
      enable: false,
      normalization_factor: 100,
      normalize_to_viewport: false,
      debounce_time: 25
    },
    rolling_averages: {
      default: 'original',
      available_averages: [],
      hovertemplate: ""
    },
    height: 600,
    width: null,
    app_min_width: 1152,
    app_max_width: 1440,
    paper_bgcolor: '#fff',
    plot_bgcolor: '#fff',
    xaxis: {
      autorange: true,
      type: 'date',
      showgrid: true,
      zeroline: true,
      showticklabels: true
    },
    yaxis: {
      autorange: true,
      type: 'linear',
      showgrid: true,
      zeroline: true,
      showticklabels: true
    },
    hovertemplate: "",
    hoverinfo: 'all',
    range_selection: {
      enable: true,
      year_options: [1, 3, 5, 10],
      suffix_text: 'y',
      include_all: true
    },
    margin: {
      l: 48,
      r: 24,
      b: 16,
      t: 72,
      pad: 4
    },
    enable_range_slider: true,
    xaxis_range_slider: {
      thickness: '' // default is let plotly handle it
    },
    responsive: true,
    show_legend: true,
    show_custom_legend: false,
    custom_legend: null,
    show_modebar: true,
    show_datefields: false,
    prevent_timezone_conversion: true,
    static_plot: false,
    enable_rescale_y: true,
    spikedistance: 0,
    extended_distance: 5
  };

  options = merge(default_options, options);

  var layout, stock_data, plotly_options, normalization_debounce;

  var layout_active = false;

  var colors = [
    '#0033A0', '#0097A7', '#9C27B0', '#9FA8DA', '#C2185B', '#FFAB00',
    '#26C6DA', '#7E57C2', '#388D41', '#42A5F5', '#BA68C8', '#FF5252',
    '#81C784', '#F06292', '#DE463D', '#FF7043'
  ];

  // button color styling
  var buttonBgColor = '#EEEEEE';
  var buttonBgColorActiveRGB = 'rgb(242, 245, 250)';
  var defaultActiveUpdateMenuRGB = 'rgb(244, 250, 255)';

  var color_scale = d3.scaleOrdinal(colors);

  var is_initial_load = true;

  // for rescaling y when changing the rolling averages
  // (without changing date slider)
  var overall_start_x, overall_end_x;

  // initializes the x coordinate for updatemenu annotation
  // based on window size
  if (options.annotations) {
    changeAnnotationValues();
  }

  (function initialize() {
    layout = {
      title: options.title,
      height: options.height,
      spikedistance: options.spikedistance,
      width: options.responsive ? null : options.width,
      paper_bgcolor: options.paper_bgcolor,
      plot_bgcolor: options.plot_bgcolor,
      xaxis: options.xaxis,
      yaxis: options.yaxis,
      margin: options.margin,
      hoverlabel: {
        bgcolor: options.hoverlabel_bgcolor
      },
      showlegend: options.show_legend,
      legend: options.legend,
      annotations: options.annotations,
      extended_distance: options.extended_distance
    };

    plotly_options = {
      displaylogo: false,
      responsive: options.responsive,
      displayModeBar: options.show_modebar && !options.static_plot
    };

    if (options.enable_range_slider) {
      layout.xaxis.rangeslider = {
        autorange: true,
        bgcolor: buttonBgColorActiveRGB,
        thickness: options.xaxis_range_slider.thickness
      }
    }

    if (options.static_plot) {
      layout.xaxis.fixedrange = true;
      layout.yaxis.fixedrange = true;
    }

    set_layout_range_selection();

    addUpdatemenuEventListener();

    addDateRangeSelectorListener();

    setCustomLegend();
  })();

  // Gets an array of dates for a series.
  // @param data The json object for the series.
  function get_dates(data) {
    var offset = 0;
    // Plotly does not do anything to handle timezones. It uses the `Date` object to plot.
    // Javascripts built-in date object will automatically convert any date given to it
    // to its timezone when displayed as a string which means everyone in the US will
    // see the day before. This workaround adds the user's browser time offset to
    // the date so that no matter what browser the plot is viewed in, the dates will
    // display the same.
    if (options.prevent_timezone_conversion) {
      offset = get_standard_timezone_offset() * 60000;
    }
    return unpack(data.data, 0).map(function(date) {
      return new Date(date + offset);
    });
  }

  // built-in getTimezoneeOffset will be affected by daylight save time
  // which will cause the returned offset being smaller if dst is applied.
  // We solve this by getting two offset at both Jan and July and
  // return the bigger one.
  // @return [Integer] standard timezone offset in minutes.
  function get_standard_timezone_offset() {
    var today_local = new Date(),
      jan = new Date(today_local.getFullYear(), 0, 1),
      jul = new Date(today_local.getFullYear(), 6, 1);
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
  }

  // Gets the adjusted price for a series as an array.
  // The adjusted price is constant normalized based on the normalized settings.
  // @param data The json object for the series.
  function get_constant_normalized_price(data) {
    if (data.data.length === 0) {
      return []
    }
    var starting_point = data.data[0][1];
    return normalize_series(get_price(data), starting_point);
  }


  // Gets the price for a series as an array.
  // @param data The json object for the series.
  function get_price(data) {
    return unpack(data.data, 1);
  }

  // Pulls a specific column from a set of rows.
  // @param rows The set of rows.
  // @param key The key/index of the column.
  function unpack(rows, key) {
    return rows.map(function (row) {
      return row[key];
    });
  }

  // Gets a color for a series.
  // If a color isn't passed, we use the default cog color scheme.
  // @param color The color set in the JSON.
  // @param index The series index, used to lookup the color from the scale.
  function get_line_color(color, index) {
    if (color === null || color === undefined) {
      return color_scale(index);
    }
    return color;
  }

  // Gets a line stroke style for a series.
  // If a stroke style isn't passed, we default to solid.
  // Valid values include solid, dash, and dotdash. There may be more, see plotly's documentation.
  // @param stroke The stroke style set in the JSON.
  function get_line_stroke(stroke) {
    if (stroke === null || stroke === undefined) {
      return 'solid'
    }
    return stroke;
  }

  // Gets a width for a series.
  // If a width isn't passed, we use the default width of 2px.
  // @param width The width set in the JSON.
  function get_line_width(width) {
    if (width === null || width === undefined) {
      return 2;
    }
    return width;
  }

  // We use the geometric mean instead of arithmetic mean to calculate a moving/rolling
  // average given an array of dates and values, and the number of years to average.
  // @param series_dates [Array<Date>] An array of integer representation of dates. They should be unix timestamps.
  // @param series_values [Array<Float>] An array of the price values for the series.
  // @param years_for_average [Integer] The number of years to include in the rolling means.
  // @return [Hash] a hash of moving average data.
  function get_moving_average(series_dates, series_values, years_for_average) {
    var moving_average_variables = {
      moving_average_dates: [],
      moving_average_values: [],
      moving_values: []
    }

    // An array of the change percentages for the series.
    var series_change_percentages = calculate_series_change_percentages(series_values);

    if (series_change_percentages.length === 0) {
      return moving_average_variables;
    }

    // Rolling values
    // a series of dates for rolling values
    var rolling_dates = [];
    // a series of change percentages for rolling values
    var rolling_values = [];

    // Moving values
    // a total moving value based on change percentages for total moving value
    var moving_total = 1;
    // a series of dates for moving values
    var moving_average_dates = [];
    // a series of change percentages has been calculated by geometric mean based.
    var moving_average_values = [];
    // a series of price values for moving values
    var moving_values = [];

    // We do NOT want to change the date. Create a new date from the dates value.
    var start_date = new Date(series_dates[0].getTime());
    start_date = start_date.setFullYear(start_date.getFullYear() + years_for_average);

    for (var i = 0; i < series_change_percentages.length; i++) {
      if (series_dates[i].getTime() < start_date) {
        rolling_dates.push(series_dates[i]);
        rolling_values.push(series_change_percentages[i]);

        moving_total = moving_total * (1 + series_change_percentages[i]);

        continue;
      }
      // We do NOT want to change the date. Create a new date from the dates value.
      var current_date = new Date(series_dates[i].getTime());
      var cutoff_date = current_date.setFullYear(current_date.getFullYear() - years_for_average);

      moving_average_dates.push(series_dates[i]);

      var geometric_mean = Math.pow(moving_total, (1.0 / years_for_average)) - 1;
      // this makes sure cases like 1.005 round correctly
      // Ref: https://stackoverflow.com/questions/11832914/round-to-at-most-2-decimal-places-only-if-necessary
      var moving_average_value = Math.round((geometric_mean * 100 + Number.EPSILON) * 100) / 100
      moving_average_values.push(moving_average_value);

      moving_values.push(series_values[i]);

      // multiple series_change_percentages[i] for preparing next rolling year set.
      moving_total = moving_total * (1 + series_change_percentages[i]);

      rolling_dates.push(series_dates[i]);
      rolling_values.push(series_change_percentages[i]);

      while (rolling_dates[0] <= cutoff_date) {
        var rolling_date = rolling_dates.shift();
        var rolling_value = rolling_values.shift();

        // in order to remove 1st rolling_value from moving_total,
        // divide by 1st rolling_value.
        moving_total = moving_total / (1 + rolling_value);
      }
    }

    moving_average_variables.moving_average_dates = moving_average_dates;
    moving_average_variables.moving_average_values = moving_average_values;
    moving_average_variables.moving_values = moving_values;

    return moving_average_variables;
  }

  // Returns a string for the label text in the dropdown menu for a trace group.
  // @param index The index of the trace group.
  function label_for_trace_group(index) {
    if (index === 'original') {
      return 'None';
    } else {
      return options.rolling_averages.available_averages[index].name;
    }
  }

  // Gets the trace groups present in the layout.
  function get_trace_groups() {
    // We should build the trace groups based on the configuration options so it still works
    var trace_groups = {
      'original': []
    };

    options.rolling_averages.available_averages.forEach((_value, index) => trace_groups[index] = [])

    stock_data.forEach((trace, index) => trace_groups[trace.trace_group].push(index))

    return trace_groups;
  }

  // Sets the layout's updatemenu object.
  // This is used to create the dropdown menu for rolling average selection.
  function create_rolling_average_updatemenu() {
    var trace_groups = get_trace_groups();
    var updatemenu_buttons = [];
    Object.keys(trace_groups).forEach(function(index) {
      var trace_group = trace_groups[index];
      var visible_args = [];

      for (var i = 0; i < stock_data.length; i++) {
        if (trace_group.includes(i)) {
          visible_args.push(true)
        } else {
          visible_args.push(false)
        }
      }
      updatemenu_buttons.push({
        method: 'restyle',
        args: ['visible', visible_args],
        label: label_for_trace_group(index),
        key: index
      });
    });
    updatemenu_buttons = move_object_to_array_front("original", updatemenu_buttons);

    return {
      yanchor: 'bottom',
      xanchor: 'right',
      y: 0.975,
      x: 1,
      showactive: true,
      buttons: updatemenu_buttons,
      bgcolor: buttonBgColor,
      borderwidth: 0,
      active: layout_active ? layout.updatemenus[0].active : find_trace_group_button_by_key(options.rolling_averages.default, updatemenu_buttons),
      type: 'buttons',
      direction: 'left'
    };
  }

  // Purges the selector, then creates a new Plotly instance that will draw this the base configuration.
  // Destroys any event listeners and recreates them.
  // Restyles to apply the default dropdown view.
  // Note: Once a stock chart is drawn, we should never modify the layout or stock data directly.
  // They are used and modified by Plotly. You can update them through relayouts and restyles.
  function purge_and_draw() {
    window.removeEventListener('resize', resizeStyling)
    selector.removeEventListener('plotly_relayout', handle_relayout);
    selector.removeEventListener('plotly_restyle', handle_restyle);
    selector.removeEventListener('plotly_hover', handleHover);
    selector.removeEventListener('plotly_unhover', handleUnhover);

    Plotly.purge(selector);
    Plotly.newPlot(selector, stock_data, layout, plotly_options);

    sanitize_xaxis_range();
    layout_active = true;

    apply_selected_view();
    if (options.normalization.enable && options.normalization.normalize_to_viewport) {
      normalization_debounce = debounce(handle_relayout, options.normalization.debounce_time);
      selector.on('plotly_relayout', normalization_debounce);
    }
    selector.on('plotly_restyle', handle_restyle);
    selector.on('plotly_hover', handleHover);
    selector.on('plotly_unhover', handleUnhover);
    window.addEventListener('resize', resizeStyling);
    hideGhostTooltip();

    // resets selected color hover when hovering
    // over the stockchart or update menu
    selector.querySelector('.menulayer').addEventListener('hover', updateUpdatemenuStyles);

    if (options.show_datefields) {
      injectDatefields();
    }
  }

  // Handles calling the rescale y-axis method when some restyle event occurs.
  // Also calls the method to update the datefields from the selected rangeslider values.
  // @param event The relayout event.
  function handle_restyle(event) {
    if (options.normalization.enable && options.normalization.normalize_to_viewport && options.enable_rescale_y) {
      // triggered by normalize_to_view or changing the date range
      if (event[0]["triggered_by_normalize"]) {
        rescale_y_axis(event[0]["data"]);
      } else if (!event[0]["do_not_rerender"] && event[0]["line"] === undefined) {
        // CASE: - changing trace / rolling average type
        //       - changing the bucket
        // NOTE: - changing line styles messes up the scaling y so don't want line event
        var xaxis_range = find_closest_xaxis_range_by_traces();

        var rescale_y_data = gets_normalized_data(event);
        rescale_y_axis(rescale_y_data, xaxis_range);

        if (event[0]["triggered_by_changing_view"]) {
          // case: changing buckets or adding additional traces
          // do_not_rerender is to prevent a loop
          Plotly.restyle(selector, {y: rescale_y_data['data'], hovertext: getAnnualizedReturns(xaxis_range[0]), do_not_rerender: true});
        }
      }
      is_initial_load = false;
    }

    // make sure yaxis title text is present
    if (options.yaxis && options.yaxis.title && options.yaxis.title.text) {
      shiftYAxisTitle();
    }

    // make sure update menu style changes don't get reset
    // when stock chart restyling occurs
    updateUpdatemenuStyles();

    // shift spacing in the rolling averages menu
    shiftUpdateMenuButton(event);

    extendHoverArea();

    if (event[0] && event[0]['visible']) {
      updateDatefieldsFromRangeslider();
      updateDateRangeInputStyle();
    }
  }

  // Set the legend items values when hovering
  // @param [Hash] data - hover data
  function handleHover(data) {
    if (!options.show_custom_legend || !options.custom_legend) {
      return;
    }

    // viewing share price or rolling averages
    var activeUpdateMenuKey = layout.updatemenus[0].active;
    var isRollingAvg = activeUpdateMenuKey != 0;

    data.points.forEach(function(point,index) {
      if (index == 0) { // set date
        var date = new Date(point.x);
        options.custom_legend.setDateHolder(date);
      }
      // format and set share-price and annualized returns based on current view
      if (isRollingAvg) {
        var annualizedReturns = point.y.toFixed(2) + "%";
        options.custom_legend.setLegendItemValue(point.data.series_id, '.annualized-returns', annualizedReturns);
      } else { // viewing share price
        var internalSharePrice = point.y.toFixed(2);
        var sharePrice = Math.sign(internalSharePrice) >= 0 ? "$" + internalSharePrice : "-$" + Math.abs(internalSharePrice) ;
        var annualizedReturns = point.hovertext.toFixed(2) + "%";

        options.custom_legend.setLegendItemValue(point.data.series_id, '.annualized-returns', annualizedReturns);
        options.custom_legend.setLegendItemValue(point.data.series_id, '.share-price', sharePrice);
      }
    });
  }

  // when only x axis hover tooltip is enabled, add a listener for:
  // if an extra tooltip is appended to the hoverlayer, remove the tooltip
  function hideGhostTooltip() {
    if (options.hoverinfo == 'x') {
      var hoverlayer = selector.querySelector('.hoverlayer');
      hoverlayer.addEventListener('DOMNodeInserted', function() {
        var hoverText = this.querySelector('.hovertext');
        if (hoverText) {
          hoverText.remove();
        }
      });
    }
  }

  // reset html on legend items when not hovering on the stock chart
  function handleUnhover() {
    if (!options.show_custom_legend || !options.custom_legend) {
      return;
    }
    // clear date
    options.custom_legend.setDateHolder('');

    // clear annualized returns and share price
    options.custom_legend.setLegendItemValue('', '.annualized-returns', '-------%');
    options.custom_legend.setLegendItemValue('', '.share-price', '$----------');
  }

  // Injects the datefields for manual date entry into the dom and repositions them over the
  // stock chart in the appropriate positions.
  function injectDatefields() {
    var svgContainer = selector.querySelector('.svg-container');
    var rangeSelectorButtons = svgContainer.querySelector('.rangeselector');
    var dateRangeContainer = createDateFields(rangeSelectorButtons);

    svgContainer.prepend(dateRangeContainer);
    bindDatefields();
  }

  // @param rangeSelectorButtons [NodeList] range selection buttons
  // @return [HTML] range selector input section
  function createDateFields(rangeSelectorButtons) {
    var buttonWidth = 35; // default button width of existing range selector
    var leftPad = 10; // how far the inputs should be padded away from the buttons

    // calculate the offset based on range selector button count in case more buttons are added
    var leftOffset = leftPad + options.margin.l + (buttonWidth * rangeSelectorButtons.querySelectorAll('.button').length);

    var dateRangeContainer = document.createElement('span');
    dateRangeContainer.setAttribute('class', 'stock-chart-range');

    // setting the top offset to match the top of the plot.ly generated dropdown.
    dateRangeContainer.style.top = '41px';
    dateRangeContainer.style.left = leftOffset + 'px';
    dateRangeContainer.style.zIndex = 2;

    var minField = createTextInputField('range-min', 'stock-chart-date min');
    var maxField = createTextInputField('range-max', 'stock-chart-date max');
    var toText = " To ";

    dateRangeContainer.append(minField);
    dateRangeContainer.append(toText);
    dateRangeContainer.append(maxField);

    return dateRangeContainer;
  }

  // @param name [String] name of in text input
  // @param cssClass [String]
  // @return [HTML] text input field
  function createTextInputField(name, cssClass) {
    var field = document.createElement('input');
    field.setAttribute('type', 'text');
    field.setAttribute('name', name);
    field.setAttribute('class', cssClass);
    return field;
  }

  // Method to update the date inputs from the rangeslider values. Called on relayout and restyle.
  function updateDatefieldsFromRangeslider() {
    var startX = layout.xaxis.range[0]
    var endX = layout.xaxis.range[1]

    setDateFieldValues(startX, endX);
  }

  function bindDatefields() {
    var dateFields = selector.querySelectorAll('input.stock-chart-date')
    dateFields.forEach(function(field) {
      field.addEventListener('change', updateRangesliderFromDatefields)
      field.addEventListener('change', function() {
        ahoy.track("stock_chart_date_change_fields", {language: "JavaScript"});
      })
    })
  }

  // Method to adjust the visible range of the chart and rangeslider based on datefield input.
  // Uses MomentJS to parse arbitrary dates and then validate them.
  // This method should be bound from the datefields on update.
  function updateRangesliderFromDatefields(event) {
    var $datefield = event.target;
    // validate that the string is valid date.
    if (validateStringAsDate($datefield.value)) {
      // remove any red border set
      $datefield.style.borderColor =  'rgb(190, 200, 217)';
      // make sure it is formatted in the EN locale format
      $datefield.value = moment($datefield.value).format('L')
    }
    else {
      // add red border
      $datefield.style.borderColor =  'red';
      // refocus the element
      $datefield.focus();
      // return early
      return;
    }

    var min_date, max_date;

    if ($datefield.classList.contains('min')) {
      min_date = $datefield.value;
      max_date = selector.querySelector('.stock-chart-date.max').value
    }

    if ($datefield.classList.contains('max')) {
      max_date = $datefield.value;
      min_date = selector.querySelector('.stock-chart-date.min').value;
    }

    // if min is greater than max, don't update
    min_date = moment(min_date).toDate();
    max_date = moment(max_date).toDate();
    if (max_date > min_date) {
      // Assign min_date and max_date to be xaxis_range, then relayout.
      var xaxis_range = [moment(min_date).toDate(), moment(max_date).toDate()];
      Plotly.relayout(selector, {'xaxis.range': xaxis_range});
    }
  }

  function validateStringAsDate(dateString) {
    // Using moment JS to better support arbitrary date entry formats just in case
    // forcing locale to en to support the MM/DD/YYYY formats we want to output to by default
    moment.locale('en');
    moment.suppressDeprecationWarnings = true;

    return moment(dateString).isValid();
  }

  // @param startX [String|Date]
  // @param endX [String|Date]
  function setDateFieldValues(startX, endX) {
    // I am locking this to the JS recognized en-US locale for simplicity for now.
    // If this needs internationalization support, this will need to be significantly more complex.
    var localeOptions = {day: '2-digit', month: '2-digit', year: 'numeric'};
    var startValue = moment(startX).toDate().toLocaleDateString('en-US', localeOptions);
    var endValue = moment(endX).toDate().toLocaleDateString('en-US', localeOptions);

    // ahoy tracking
    ahoy.track("stock_chart_date_change_slider", {language: "JavaScript"});

    selector.querySelector('.stock-chart-date.min').value = startValue;
    selector.querySelector('.stock-chart-date.max').value = endValue;
  }

  // Updates the visibility of the graphed traces based on the selected view.
  function apply_selected_view() {
    // The updatemenus will not exist if rolling averages are not enabled.
    if (layout.updatemenus === undefined || layout.updatemenus.length === 0) {
      return;
    }

    // Find closet xaxis_range and relayout.
    var xaxis_range = find_closest_xaxis_range_by_traces();
    Plotly.relayout(
      selector,
      {
        'xaxis.range': xaxis_range
      }
    );

    // Restyle based on updatemenus.
    var active_updatemenu_key = layout.updatemenus[0].active;
    Plotly.restyle(
      selector,
      {
        visible: layout.updatemenus[0].buttons[active_updatemenu_key].args[1],
        triggered_by_changing_view: true
      }
    );
  }

  // Handles calling the normalization method when a relayout event occurs.
  // @param event The relayout event.
  function handle_relayout(event) {
    // Normalizing can trigger a relayout. We should ignore them to avoid extra calculations, or an infinite loop.
    if (event['triggered_by_normalize']) {
      return;
    }

    // We need to catch events that modify the viewable x axis. The rest can be ignored.
    if (event['xaxis.range'] !== undefined || event['xaxis.range[0]'] !== undefined || event['xaxis.autorange'] !== undefined) {
      // Find closet xaxis_range and relayout.
      var xaxis_range = find_closest_xaxis_range_by_traces();
      Plotly.relayout(selector, {'xaxis.range': xaxis_range});
      // Recalculate and modify annualized return for hovertext
      Plotly.restyle(selector, {hovertext: getAnnualizedReturns(xaxis_range[0])});
      // These events also affect date ranges, so we update those values here.
      updateDatefieldsFromRangeslider();
      // Normalize data and viewport.

      var normalized_data = gets_normalized_data(event);
      normalize_to_viewport(normalized_data);
    }

    updateDateRangeInputStyle();
    updateDateRangeButtonStyle();
    selectDateRangeButtons(layout.xaxis.range[0], layout.xaxis.range[1]);
  }

  function resizeStyling() {
    // move the moving average annotation based on window size
    if (options.annotations) {
      changeAnnotationValues();
      Plotly.relayout(selector, {'annotations': options.annotations});
    }
    shiftUpdateMenuButton(event);
    updateUpdatemenuStyles();
    extendHoverArea();

    // make sure yaxis title text is present
    if (options.yaxis && options.yaxis.title && options.yaxis.title.text) {
      shiftYAxisTitle();
    }
  }

  // Gets normalized chart data and date ranges based on whatever area the user is currently viewing.
  // @param event The relayout event.
  // @return [Object] with the selected date range, normalized data, and index for visible traces
  function gets_normalized_data(event) {
    // Plotly events are a little weird: Some events may return the fully changed x range as a single array,
    // some may report the changes using the indexes. We need to handle both.
    var start_x, end_x;
    if (event['xaxis.range[0]'] !== undefined) {
      start_x = event['xaxis.range[0]'];
    } else if (event['xaxis.range'] !== undefined) {
      start_x = event['xaxis.range'][0];
    } else if (event[0] && event[0]['visible'] !== undefined) {
      // for when changing rolling average / no date range is set
      start_x = overall_start_x;
    } else {
      // Auto range was applied. Use the first value in the dataset.
      start_x = 0;
    }

    // Gets the last date from the range selector
    if (event['xaxis.range[1]'] !== undefined) {
      end_x = event['xaxis.range[1]'];
    } else if (event['xaxis.range'] !== undefined) {
      end_x = event['xaxis.range'][1];
    } else {
      end_x = 0;
    }

    var trace_groups = get_trace_groups();
    var normalization_base_values = {};

    var start_index, end_index;
    // The normalized values can be updated based on the values we set for the original data.
    // NOTE: only normalized original traces, not derived traces (rolling averages).
    trace_groups['original'].forEach(function(stock_data_index) {
      var trace = stock_data[stock_data_index];
      start_index = closestIndexToDate(trace, start_x);
      if (start_index === null) {
        normalization_base_values[trace.series_id] = null;
      } else {
        normalization_base_values[trace.series_id] = trace.y[start_index];
      }
    });
    var normalized_data = {};
    var restyles = [];
    var visible_trace_indices = [];

    stock_data.forEach(function(trace, stock_data_index) {
      if (trace.y.length === 0) {
        // Empty array is pushed to keep stock data index matched with trace data appropriately
        return restyles.push([]);
      }

      var base_value = null;

      // Only original traces should be normalized.
      // Derived traces (rolling averages) should be NOT normalized.
      if (trace.derived_trace === false) {
        base_value = normalization_base_values[trace.series_id];
      }

      // If the user scrolls past the series, just return the base series so the data doesn't change.
      if (base_value === null) {
        restyles.push(trace.y);
      } else {
        restyles.push(normalize_series(trace.y, base_value));
      }

      if (trace.visible) {
        // For case when "all" is selected for date range
        if (event['xaxis.autorange'] || is_initial_load) {
          start_x = trace.x[0];
          end_x = trace.x[trace.x.length - 1];
        }

        if (start_x != 0 && end_x != 0) {
          overall_start_x = start_x;
          overall_end_x = end_x;
        }

        start_index = closestIndexToDate(trace, overall_start_x);
        end_index = closestIndexToDate(trace, overall_end_x);

        if (start_index != end_index) {
          visible_trace_indices.push({
            "index": stock_data_index,
            "start_index": start_index,
            "end_index": end_index
          });
        }
      }
    });

    normalized_data["data"] = restyles;
    normalized_data["visible_trace_indices"] = visible_trace_indices;
    return normalized_data;
  }

  // Normalizes the chart to whatever area the user is currently viewing.
  // @param [Object] normalized_data
  function normalize_to_viewport(normalized_data) {
    Plotly.restyle(selector, {y: normalized_data.data, data: normalized_data, triggered_by_normalize: true});
  }

  // Normalizes a series of data given the base value.
  // @param series The series data.
  // @param normalization_base The value that will become the starting point for the normalization.
  function normalize_series(series, normalization_base) {
    return series.map(function(price) {
      return price / normalization_base * options.normalization.normalization_factor
    })
  }

  // Uses binary search to find the date that is numerically closest to the given date
  // @param trace [Object] The trace object to search
  // @param date [Date|String] The target date
  // @return [Integer] index of closest date
  function closestIndexToDate(trace, date) {
    var lowerIndex = 0,
        upperIndex = trace.x.length - 1,
        date = new Date(sanitize_date(date)),
        pivotIndex,
        pivotValue;

    // loops through to find the pivot, lower, and upper index
    while (lowerIndex < upperIndex) {
      pivotIndex = Math.floor(lowerIndex + (upperIndex - lowerIndex) / 2.0);
      pivotValue = trace.x[pivotIndex];

      if (pivotValue == date) {
        return pivotIndex; // return if exact match
      }

      if (pivotValue <= date) {
        lowerIndex = pivotIndex + 1;
      } else {
        upperIndex = pivotIndex;
      }
    }

    // find the value with the smallest difference
    var lowerValue = trace.x[lowerIndex],
        upperValue = trace.x[upperIndex],
        lowerDiff = Math.abs(lowerValue - date),
        upperDiff = Math.abs(upperValue - date),
        pivotDiff = Math.abs(pivotValue - date);

    if (lowerDiff < upperDiff) {
      return lowerIndex;
    } else if (upperDiff < lowerIndex) {
        return upperIndex;
    } else if (lowerDiff < pivotDiff) {
      // upperValue == lowerValue
      // but the upper/lower values are closer than pivot
      return lowerIndex;
     } else {
      return pivotIndex;
    }
  }

  // Finds the index of a trace groups menubutton by its key value.
  // Used to find and set the default view of the stock chart.
  // @param key The unique key of the trace group, can be 'all', 'original',
  //   or an index of the rolling averages defined in options.
  function find_trace_group_button_by_key(key, buttons) {
    for(var i = 0; i < buttons.length; i++) {
      if (buttons[i].key == key) {
        return i;
      }
    }
    return 0;
  }

  // Finds closet xaxis_range by using traces and current layout.xaxis_range.
  function find_closest_xaxis_range_by_traces () {
    var xaxis_range_start_date,
        xaxis_range_end_date,
        earliest_date;
    // When no traces exists at chart, assign layout.xaxis.range.
    if (stock_data.length == 0) {
      xaxis_range_start_date = new Date(layout.xaxis.range[0]);
      xaxis_range_end_date = new Date(layout.xaxis.range[1]);
    }

    // Find closet start index and end index by looping through each trace.
    stock_data.forEach(function(trace, index) {
      // Find closet start index and end index of this trace and current
      // layout.xaxis_range.
      var start_index = closestIndexToDate(trace, layout.xaxis.range[0]),
          end_index   = closestIndexToDate(trace, layout.xaxis.range[1]);

      // Determine the earliest date in the visible data in case it's after the xaxis range start date
      if (trace.visible && (!earliest_date || trace.x[0] < earliest_date)) {
          earliest_date = trace.x[0];
      }

      // Find closet start and end dates.
      var start_date = new Date(trace.x[start_index]),
          end_date   = new Date(trace.x[end_index]);

      // Assign first trace range as a baseline.
      if (index == 0) {
        xaxis_range_start_date = start_date;
        xaxis_range_end_date   = end_date;
      }

      if (start_date < xaxis_range_start_date) {
        xaxis_range_start_date = start_date;
      }

      if (end_date < xaxis_range_end_date) {
        xaxis_range_end_date = end_date;
      }
    });

    // Use the earliest visible trace's date as the start date if it's after the xaxis range start date
    if (earliest_date && earliest_date > xaxis_range_start_date) {
      xaxis_range_start_date = earliest_date
    }

    var closet_xaxis_range = [xaxis_range_start_date, xaxis_range_end_date];

    return closet_xaxis_range;
  }

  // Sets the stock data object which contains all the trace information for the chart.
  // @param raw_stock_data The raw stock data JSON response from the server.
  function set_stock_data(raw_stock_data) {
    stock_data = [];
    create_series_traces(raw_stock_data).forEach(function(trace, _index) {
      stock_data.push(trace)
    });
  }

  // Creates traces for a set of new series to be added to the chart.
  function create_series_traces(raw_stock_data) {
    var created_traces = [];
    raw_stock_data.data.forEach(function (series, index) {
      var y_values = options.normalization.enable ? get_constant_normalized_price(series) : get_price(series);
      var trace = {
        type: 'scatter',
        mode: 'lines',
        name: series.name,
        series_id: series.id,
        x: get_dates(series),
        y: y_values,
        line: {
          color: get_line_color(series.color, stock_data.length - 1 + index),
          width: get_line_width(series.width),
          dash: get_line_stroke(series.stroke)
        },
        hoverinfo: options.hoverinfo,
        hovertext: options.hovertemplate ? init_annualized_return(y_values) : "",
        hovertemplate: options.hovertemplate,
        visible: true,
        derived_trace: false,
        trace_group: 'original'
      };
      created_traces.push(trace);

      create_rolling_average_traces(trace, stock_data.length + created_traces.length - 1).forEach(function(trace) {
        created_traces.push(trace);
      });
    });

    return created_traces;
  }

  // Creates the rolling average traces for a series.
  // @param parent_trace The original trace for the series.
  // @param parent_trace_index The index of the parent in the stock_data array.
  function create_rolling_average_traces(parent_trace) {
    var created_traces = [];
    options.rolling_averages.available_averages.forEach(function (rolling_average, index) {
      var avg_data = get_moving_average(parent_trace.x, parent_trace.y, rolling_average.years);
      var rolling_average_trace = {
        type: 'scatter',
        mode: 'lines',
        name: parent_trace.name + " (" + rolling_average.name + ")",
        series_id: parent_trace.series_id,
        x: avg_data.moving_average_dates,
        y: avg_data.moving_average_values,
        line: {
          color: parent_trace.line.color,
          width: parent_trace.line.width,
          dash: parent_trace.line.dash
        },
        hovertemplate: options.rolling_averages.hovertemplate,
        visible: parent_trace.visible,
        trace_group: index,
        derived_trace: true,
        hoverinfo: options.hoverinfo
      };

      created_traces.push(rolling_average_trace);
    });

    return created_traces;
  }

  // Sets the layout range selection buttons at the top of the stock chart.
  function set_layout_range_selection() {
    if (options.range_selection.enable) {
      var items = options.range_selection.year_options.map(function (year) {
        return {
          count: year,
          label: year + options.range_selection.suffix_text,
          step: 'year',
          stepmode: 'backward'
        }
      });
      if (options.range_selection.include_all) {
        items.push({step: 'all'})
      }
      layout.xaxis.rangeselector = {
        buttons: items,
        bgcolor: buttonBgColor,
        activecolor: buttonBgColorActiveRGB
      };
    }
  }

  // Triggers a rerender of the update menus, and maintains the currently selected option.
  function render_view_after_data_update() {
    // We need to update the updatemenus, but the traces won't be present until after the promise is resolved.
    if (options.rolling_averages.available_averages.length) {
      Plotly.relayout(selector, {updatemenus: [create_rolling_average_updatemenu()]}).then(apply_selected_view);
    }
  }

  // Rescales y axis based on given chart data
  // @param chart_data [Object] that contains:
  //   start_index: index of begin date in date range)
  //   end_index: index of end date in date range)
  //   data: stock chart data
  //   visible_trace_indices: index for traces that are visible
  // @param xaxis_range [Array<Integer>] optional two item array that sets the the start and end indices on the x-axis
  function rescale_y_axis(chart_data, xaxis_range) {
    // A hash of layout settings will be updated.
    var update_layout = {}

    var min_y, max_y;
    // goes through data of each visible trace to find the min and max
    chart_data.visible_trace_indices.forEach(function(trace) {
      var curr_min_max = get_min_max_values(trace.start_index, trace.end_index + 1, chart_data.data[trace.index]);

      if (curr_min_max.min !== undefined && isFinite(curr_min_max.min) && (min_y === undefined || (curr_min_max.min < min_y))) {
        min_y = curr_min_max.min;
      }

      if (curr_min_max.max !== undefined && isFinite(curr_min_max.min) && (max_y === undefined || curr_min_max.max > max_y)) {
        max_y = curr_min_max.max;
      }
    });

    // change y-axis scaling
    if (min_y !== undefined && max_y !== undefined) {
      // +/- some amount so lines aren't rendered at the edge
      var adjustment = (max_y - min_y) * 0.05;
      update_layout['yaxis.range'] = [min_y - adjustment, max_y + adjustment];

      if (xaxis_range) {
        update_layout['xaxis.range'] = xaxis_range;
      }
    }

    // Check current active updatemenu key index.
    var active_updatemenu_key = layout.updatemenus[0].active;
    // Rolling average is None, key is 0.
    var is_rolling_avg = active_updatemenu_key != 0;

    // Toggle yaxis_tickprefix by active updatemenu is selected.
    var yaxis_tickprefix = is_rolling_avg ? "" : "$";
    update_layout['yaxis.tickprefix'] = yaxis_tickprefix;

    // Toggle yaxis_ticksuffix by active updatemenu is selected.
    var yaxis_ticksuffix = is_rolling_avg ? "%" : "";
    update_layout['yaxis.ticksuffix'] = yaxis_ticksuffix;

    // Toggle yaxis title based on active menu selected
    var yaxis_title = is_rolling_avg ? options.yaxis.rolling_average : options.yaxis.price;
    update_layout['yaxis.title.text'] = yaxis_title;

    // Relayout
    Plotly.relayout(selector, update_layout);
  }

  // Calculates the min and max values date within a given date range
  // @param [Integer] start_date_index
  // @param [Integer] end_date_index
  // @param [Array<Integer>] stock_data
  // @return [Object] containing the min, max value for the given range
  function get_min_max_values(start_date_index, end_date_index, stock_data) {

    // swap the indecies if end date is less than start date
    if (start_date_index > end_date_index) {
      var temp = start_date_index;
      start_date_index = end_date_index;
      end_date_index = temp;
    }

    var curr_min = Math.min.apply(Math, stock_data.slice(start_date_index, end_date_index));
    var curr_max = Math.max.apply(Math, stock_data.slice(start_date_index, end_date_index));

    return {"min": curr_min, "max": curr_max};
  }

  // Calculates annualized return based on current viewing range
  // @param trace [Hash] all data of a trace
  // @param start_date [Date] start_date of current viewing range
  // @return [Array] all annualized return for each data point
  function calculate_annualized_return(trace, start_date) {
    var start_index = closestIndexToDate(trace, start_date),
        start_price = trace.y[start_index];

    return trace.y.map(function(point, index) {
      if (index <= start_index) {
        return 0;
      }else {
        var current_price = point,
            months = index - start_index;
        return annualized_return_formula(start_price, current_price, months);
      }
    });
  }

  // Returns annualized return values for all traces
  // @param start_date [Date] start_date of current viewing range
  // @return annualized_returns [Array] annualized return values for all traces
  function getAnnualizedReturns(start_date) {
    var annualized_returns = [];
    stock_data.forEach( function(trace) {
      if (trace.y.length === 0) {
        // Empty array is pushed to keep stock data index matched with trace data appropriately
        annualized_returns.push([]);
      } else {
        annualized_returns.push(calculate_annualized_return(trace, start_date));
      }
    });
    return annualized_returns;
  }

  // Initializes annualized return value based on the whole range
  // @param data [Array] array of all return values(for y-axis).
  // @return [Array] all annualized return for each data point
  function init_annualized_return(data) {
    return data.map(function(current_price, index) {
      if (index == 0) {
        return 0;
      }else {
        var start_price = data[0];
        return annualized_return_formula(start_price, current_price, index);
      }
    });
  }

  // Returns annualized return value based on the formula
  // @param start_value [Float] first y value of current viewing range
  // @param current_value [Float] current y value for the data point
  // @param months [Integer] number of months between current and first point of current viewing range
  // @return [Float] annualized return value
  function annualized_return_formula(start_value, current_value, months) {
    return (Math.pow(current_value / start_value, 12 / months) - 1) * 100;
  }

  // Moves object with matching key to the front of the object array
  // for create_rolling_average_updatemenu
  // @param key [String] key for what object you want to move to front
  // @param arr [Array<Object>] the array you want reorganized
  // @return [Array<Object] reorganized array
  function move_object_to_array_front(key, arr) {
    for (var i = 0; i < arr.length; i++ ) {
      if (arr[i].key === key) {
        var to_move = arr.splice(i, 1);
        arr.unshift(to_move[0]);
        break;
      }
    }
    return arr;
  }

  // Manually adds selected class to the current button
  // Selected in the update menu to override styling
  // Plotly does not have indicators for if a button is selected
  function updateUpdatemenuStyles() {
    var updateMenuButtons = selector.querySelectorAll('.updatemenu-button'),
        updateMenuSelectedButtons = [...updateMenuButtons].filter(function(button) {
          var selectedButton = button.querySelector('.updatemenu-item-rect[style*="' + defaultActiveUpdateMenuRGB + '"]');
          if (selectedButton) {
            return button;
          }
        })

    updateMenuButtons.forEach(function(buttonContainer, index) {
      var button = buttonContainer.querySelector('.updatemenu-item-rect');
      button.setAttribute("height", "19px");
      // adjust width based on text in button
      var text = button.nextElementSibling.innerHTML;
      if (text === 'None') {
        button.setAttribute("width", '47px');
      } else if (text === '10y') {
        button.setAttribute("width", '36px');
      } else {
        button.setAttribute("width", '30px');
      }

      addTraceGroupToButton(button, index)
    })
    // ----- HACK to get our own custom styling for plotly updatemenu btns ---
    // Clear our plotly-selected class on all updatemenu buttons
    updateMenuButtons.forEach(button => button.classList.remove('plotly-selected'));
    // If any updatemenu button has plotly's default "selected" color,
    // add our own class to override the style
    updateMenuSelectedButtons.forEach(button => button.classList.add('plotly-selected'));
    // -----------------------------------------------------------------------
  }

  // @param [Jquery] button
  // @param [Number] index
  // Assign tracegroup data attribute to the button
  function addTraceGroupToButton(button, index) {
    var traceGroup = 'original';
    if (index > 0) {
      traceGroup = index - 1;
    }
    button.dataset.tracegroup = traceGroup;
  }

  // Manually shift the update menu buttons to the right
  // @param [Event] event
  function shiftUpdateMenuButton(event) {
    // guard to prevent overshifting
    if (event['hovertext'] || (event[0] && event[0]['hovertext'])) {
      return;
    }

    // check if there's data before moving
    // otherwise the menu will move off the page
    if (stock_data.length > 0 && stock_data) {
      var updateMenuButtons = selector.querySelectorAll('.updatemenu-button');

      updateMenuButtons.forEach(function(button, index) {
        var translateXY = getTranslateCordinates(button.getAttribute('transform'));
        var translateX = parseInt(translateXY[0]);
        translateX -= (5 * index) - 22;
        button.setAttribute('transform', 'translate(' + translateX + ', ' + translateXY[1] + ')');
      });
    }
  }

  // extend hover area to prevent tooltip cutoff at add of graph
  function extendHoverArea() {
    var stockchartHoverArea = selector.querySelector(".draglayer .nsewdrag");
    var width = stockchartHoverArea.getAttribute('width');

    // extend hover area by a set amount to the right
    width = parseInt(width) + options.extended_distance;
    stockchartHoverArea.setAttribute('width', width);
  }

  // move position of y axis title
  function shiftYAxisTitle() {
    selector.querySelector(".g-ytitle").setAttribute('transform', 'translate(-20,0)');
  }

  // Converts string for translate attribute into a array
  // Example attribute: "translate(100, 100)"
  // @param [String] attribute transform attribute for an SVG
  // @return [Array<String>] ["100", "100"]
  function getTranslateCordinates(attribute) {
    attribute = attribute.replace("translate(", "");
    attribute = attribute.replace(")", "");
    return attribute.split(",");
  }

  // change the values from the annotations values
  function changeAnnotationValues() {
    options.annotations.forEach(function(annotation) {
      if (annotation.text === 'Moving Average:') {
        annotation.x = calculateUpdateMenuAnnotationX(annotation);
        annotation.y = calculateUpdatemenuAnnotationY(annotation);
      }
    });
  }

  // calculates the x coordinate value for
  // update menu annotation based on window size
  // @param [Object] annotation
  // @return [Number]
  function calculateUpdateMenuAnnotationX(annotation) {
    var windowWidth = document.documentElement.clientWidth;
    var width_granularity = 4800;
    var adjustment_before_breakpoint = 0.59;
    var adjustment_after_breakpoint = 0.45;

    if (windowWidth <= annotation.breakpoint) {
      windowWidth = windowWidth <= options.app_min_width ? options.app_min_width : windowWidth;
      return (windowWidth / width_granularity) + adjustment_before_breakpoint;
    } else {
      windowWidth = windowWidth <= options.app_max_width ? windowWidth : options.app_max_width;
      return (windowWidth / width_granularity) + adjustment_after_breakpoint;
    }
  }

  // calculates the y coordinate value for updating menu annotation based on window size
  // solve the issue when there's no enough room for "moving average label" after a
  // breakpoint and stack it on top of the buttons
  // @param [Object] annotation
  // @return [Number]
  function calculateUpdatemenuAnnotationY(annotation) {
    var windowWidth = document.documentElement.clientWidth;
    var adjustmentBeforeBreakpoint = 1.17;
    var adjustmentAfterBreakpoint = 1.085;

    if (windowWidth <= annotation.breakpoint) {
      return adjustmentBeforeBreakpoint;
    } else {
      return adjustmentAfterBreakpoint;
    }
  }
  // calculate and generate an array of moving change percentage based on series values
  // @param [Array<Float>] series_values  an array of each monthly_end trading price
  // @return [Array<Float>] an array of each monthly_end price change percentage
  // e.g.
  // series_values = [1, 1.5, 2],
  // then, calculate series_change_percentages based on previous value,
  // => series_change_percentages = [0, (1.5-1)/1, (2-1.5)/2]
  // => series_change_percentages = [0, 0.5, 0.25]
  function calculate_series_change_percentages(series_values) {
    // first change_percentage should start at 0
    var series_change_percentages = [0];

    series_values.forEach( function(value, index) {
      // Skip index is 0, since the formula needs calculate based on previous value.
      if (index > 0) {
        // change between 2 values in percentage = (current value - previous value) / current value
        var change_percentage = (value - series_values[index - 1]) / value;
        series_change_percentages.push(change_percentage);
      };
    });

    return series_change_percentages;
  }

  // update visibility when switching rolling averages
  function hideUnselectedTraces() {
    if (!options.show_custom_legend || !options.custom_legend) {
      return;
    }

    // get the series ids of all the currently unselected benchmark selections
    var customLegendItems = options.custom_legend.unselectedLegendItems();
    var unselectedSeries = customLegendItems.map((selection) => selection.dataset.seriesId);
    if (unselectedSeries.length == 0) {
      return;
    }

    // hide traces when switching rolling averages
    // Note: By default plotly will show all the relevant traces when switching rolling averages
    // so we only need to care about which ones to hide
    var traceIndexes = filterTraceIndexByUpdatemenuSelection(unselectedSeries, selector);
    Plotly.restyle(selector, {
      'visible': [false]
    }, traceIndexes);
  }

  // add event listeners to the update menu / rolling average buttons menu
  function addUpdatemenuEventListener() {
    // trigger hide traces when clicking on rolling average button
    if (options.show_custom_legend) {
      selector.addEventListener("click", function(e) {
        // loop parent nodes from the target to the delegation node
        for (var target=e.target; target && target!=this; target=target.parentNode) {
          if (target.matches('.updatemenu-button')) {
            hideUnselectedTraces();
            break;
          }
        }
      }, false);
    }
  }

  // set custom legend object for current stock chart
  function setCustomLegend() {
    if (options.show_custom_legend && options.custom_legend_options) {
      var legend_selector = options.custom_legend_options.legend_selector;
      var item_selector = options.custom_legend_options.item_selector;
      options.custom_legend = new StockChartLegend(legend_selector, item_selector, selector);
    }
  }

  // update dateRange input styling based button clicked
  function addDateRangeSelectorListener() {
    selector.addEventListener("click", function(e) {
      for (var target=e.target; target && target!=this; target=target.parentNode) {
        if (target.matches('.rangeselector .button')) {
          updateDateRangeInputStyle();
          break;
        }
      }
    }, false);
  }

  // update DateRange input field styling
  function updateDateRangeInputStyle() {
    var highlightField = !hasSelectedDateRange();
    var dateFields = selector.querySelectorAll('.stock-chart-range .stock-chart-date')
    dateFields.forEach(function(field) {
      field.classList.toggle('selected-date', highlightField);
    });
  }

  // @return [Boolean] whether current date range input fits the rolling average date ranges:
  //         1 year, 3 years, 5 years, 10 years
  function hasSelectedDateRange() {
    if (layout.xaxis.range[0] && layout.xaxis.range[1]) {
      var dateMin = new Date(layout.xaxis.range[0]);
      var dateMax = new Date(layout.xaxis.range[1]);

      // check if dates matches and year difference fit range
      var sameDate = dateMin.getMonth() == dateMax.getMonth() &&
                     dateMin.getDate() == dateMax.getDate();
      var yearDifference = dateMax.getFullYear() - dateMin.getFullYear();

      // highlight range selector inputs if no selected dates
      return options.range_selection.year_options.includes(yearDifference) && sameDate;
    } else {
      return false;
    }
  }

  // update DateRange button color styling
  function updateDateRangeButtonStyle() {
    // remove highlight class
    var buttons = selector.querySelectorAll('.rangeselector .button');
    buttons.forEach(function(button) {
      button.classList.remove('plotly-selected');
    });

    // add highlight class based on if current date range matches range seen on button
    // so the button and date range input are NOT highlighted at the same time
    var button = selector.querySelector('.rangeselector .button [style*="' + buttonBgColorActiveRGB + '"]');
    if (button) {
      var buttonHolder = button.parentElement;
      buttonHolder.classList.toggle('plotly-selected', hasSelectedDateRange());
    }
  }

  // in safari only, when we do Plotly.newPlot, it will return date format d= "1992-03-21 01:00"
  // and this date type cannot be processed by new Date(d) which will eventually return invalid
  // date(we expect "1992-03-21"). This method removes the extra "01:00" after Plotly.newPlot
  function sanitize_xaxis_range() {
    var range = layout.xaxis.range
    if (range) {
      range.forEach(function(date, idx) {
        range[idx] = sanitize_date(date);
      })
    }
  }

  // same issue as above, in safari only, whenever plotly relayout, we'll need
  // to sanitize the date again
  // @param [Object/string] date
  // @return [string] correct date format like "1992-03-21"
  function sanitize_date(date) {
    if (typeof(date) == 'string' && date.split(" ").length > 1) {
      return date.split(" ")[0];
    }else {
      return date;
    }
  }

  // Apply active state styling to date range button if the
  // the months are equal and year difference fits those specified
  // @param startDate [Date|String]
  // @param endDate [Date|String]
  function selectDateRangeButtons(startDate, endDate) {
    var startDate = sanitize_date(startDate),
        startMonth = startDate.getMonth(),
        startYear = startDate.getFullYear(),
        endDate = sanitize_date(endDate),
        endMonth = endDate.getMonth(),
        endYear = endDate.getFullYear(),
        button;

    // Apply highlight if:
    // - the months are different and year difference between dates are specified in year options
    // - current date range is max date range for whole year
    var yearDifference = Math.abs(endYear - startYear);
    if (startMonth == endMonth && options.range_selection.year_options.includes(yearDifference)) {
      // highlight date range button
      var textSelector = "[data-unformatted='" + yearDifference + "y']",
          buttonText = selector.querySelector('.rangeselector ' + textSelector),
          button = buttonText.parentElement;
    } else { // check if currently viewing whole date range
      var fullDateRange = getFullDateRange();
      if ((startDate - fullDateRange["minDate"] == 0) && (endDate - fullDateRange["maxDate"] == 0)) {
        var textSelector = "[data-unformatted='all']",
            buttonText = selector.querySelector('.rangeselector ' + textSelector),
            button = buttonText.parentElement;
      }
    }

    if (button != null) {
      // apply selected styling to button
      button.classList.add('plotly-selected');

      // if button is highlighted, datefields should NOT be highlighted
      selector.querySelector('.stock-chart-date.min').classList.remove('selected-date');
      selector.querySelector('.stock-chart-date.max').classList.remove('selected-date');
    }
  }

  // Goes through all the visible traces and find the earliest and latest dates
  // of the currently viewed
  // @return [Hash]
  function getFullDateRange() {
    var minDate, maxDate;

    stock_data.forEach(function (trace) {
      if (trace.visible) {
        var traceMinDate = trace.x[0],
            traceMaxDate = trace.x[trace.x.length - 1];

        if (!minDate || traceMinDate < minDate ) {
          minDate = traceMinDate;
        }
        if (!maxDate || traceMaxDate > maxDate ) {
          maxDate = traceMaxDate;
        }
      }
    });
    return { "minDate": minDate, "maxDate": maxDate };
  }

  // Sets and draws the data for the stock chart,
  // Accepts a path to a json endpoint to make a request.
  this.set_data = function (source) {
    Plotly.d3.json(source, function (err, raw_stock_data) {
      set_stock_data(raw_stock_data);
      if (options.rolling_averages.available_averages.length) {
        layout.updatemenus = [create_rolling_average_updatemenu()];
      }
      purge_and_draw();
    });
  };

  // Adds additional series to the existing chart
  this.append_data = function (source) {
    Plotly.d3.json(source, function (err, raw_stock_data) {
      var new_traces = create_series_traces(raw_stock_data);

      Plotly.addTraces(selector, new_traces)
        .then(render_view_after_data_update)
        .then(function () {
          if (options.normalization.enable && options.normalization.normalize_to_viewport) {
            normalization_debounce({"xaxis.range": layout.xaxis.range});
          }
        });
    });
  };

  // Given a set of series IDs, this method will remove them from the stock chart.
  this.remove_data = function (series_ids) {
    var items_to_remove = [];
    stock_data.forEach(function(trace, index) {
      for(var i = 0; i < series_ids.length; i ++) {
        if (trace.series_id === series_ids[i]) {
          items_to_remove.push(index);
          break;
        }
      }
    });

    if (items_to_remove.length > 0) {
      Plotly.deleteTraces(selector, items_to_remove).then(render_view_after_data_update);
    }
  };

  // This method updates the line style of a series,
  // @param [Object] series_id The series ID.
  // @param [Object] updated_styles The new style object to be applied.
  this.update_line_style = function (series_id, updated_styles) {
    var items_to_update = [];

    stock_data.forEach(function(trace, index) {
      if (trace.series_id === series_id) {
        items_to_update.push(index);
      }
    });

    var style = {line: updated_styles};

    if (updated_styles.stroke !== undefined) {
      style.line.dash = updated_styles.stroke;
      delete updated_styles.stroke;
    }

    Plotly.restyle(selector, style, items_to_update);
  };

  // Purges the plot object, plotly's event listeners and clears the plot from the DOM.
  // After calling this method, this stock chart object can no longer be used.
  this.purge = function() {
    Plotly.purge(selector);
  }
}

global.Cog.stockChart ||= StockChart;
