/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// We can define a global singleton object we'll call "Cog". This is the only
// entrypoint through we can interact with JS compiled with webpacker.
// Other included JS libraries can accept calls by registering their instances
// in this variable. In NodeJS (and testing) global is just the global scope. In the browser,
// global is the window object. This helps keep us from polluting the window scope.

// initialize ahoy frontend metrics tracking.
import ahoy from 'ahoy.js'

import {} from 'lib/state_manager'
import '@hotwired/turbo-rails'
import {} from 'lib/hubspot'
import {} from 'lib/custom_actions'

global.Cog = {}
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
const images = require.context('../images', true)
/* eslint-disable */
const imagePath = (name) => images(name, true)
/* eslint-enable */

// Uncomment to copy all static videos under ../videos to the output folder and reference
// them with the asset_pack_path helper in views (e.g <%= asset_pack_path 'media/videos/rails.mp4' %>).
const videos = require.context('../videos', true)
/* eslint-disable */
const videoPath = (name) => videos(name, true)
/* eslint-enable */

// Import our apps Stimulus controllers.
// controllers/index.js will import all controller files automatically.
// There is no need to add individual controllers here.
require('controllers')

// don't use cookies for GDPR compliance
// https://github.com/ankane/ahoy#gdpr-compliance-1
ahoy.configure({ cookies: false })
ahoy.trackView()
ahoy.trackClicks('a, button, input[type=submit]')
ahoy.trackSubmits('form')
ahoy.trackChanges('input, textarea, select')
require('lib/stock_chart')
require('lib/company_sankey')
require('lib/stacked_bar_chart')
require('lib/sector_sankey')
require('lib/turbo_override')

// Import fontawesome
require('@fortawesome/fontawesome-free/js/all.js')
require('@fortawesome/fontawesome-free/css/all.css')

// Import SweetAlert2 Styling
require('sweetalert2/src/sweetalert2.scss')

// Import Tippy
require('tippy.js')
require('tippy.js/dist/tippy.css')
// stylesheet to aid in adding borders to your tippies
require('tippy.js/dist/border.css')

// Import stylesheets managed through webpack.
require('stylesheets/application')

// Import acuity engine CSS/JS/Images
require('acuity')
