/**
 * Override any default options with custom options, used for styling the sankey
 * @param {Object} oldOptions
 * @param {Object} newOptions
 * @returns {Object} merged versions of options with both the default and custom options
 */
export function setOptions (oldOptions, newOptions) {
  newOptions.sankey = Object.assign({}, oldOptions.sankey, newOptions.sankey)
  newOptions = Object.assign({}, oldOptions, newOptions)

  return newOptions
}
