import BaseController from './base_controller'

export default class DropdownSelectController extends BaseController {
  static targets = ['selectable', 'button']
  static classes = ['selected']

  connect () {
    this.updateButton()
  }

  updateButton () {
    if (!this.hasButtonTarget) {
      return
    }

    const selectedTexts = this.selectableTargets
      .filter((target) => target.classList.contains(this.selectedClass))
      .map((target) => {
        return target.dataset.selectedText || target.textContent
      })

    let str
    if (selectedTexts.length > 0) {
      str = selectedTexts.join(', ')
    } else {
      str = 'No Selection'
    }

    this.buttonTarget.innerHTML = str
  }

  selected (event) {
    const selectedTargets = this.selectableTargets.filter((target) => event.currentTarget.contains(target))

    // Skip, if selectedTargets is not one of selectedableTargets
    if (selectedTargets.length === 0) {
      return
    }

    // Skip, if current selection is active
    if (event.currentTarget.classList.contains(this.selectedClass)) {
      return
    }

    // Mark all selections as inactive
    this.selectableTargets.forEach((target) => target.classList.remove(this.selectedClass))

    // Mark current selection as active
    event.currentTarget.classList.add(this.selectedClass)

    // Update button text
    this.updateButton()
  }
}
