import AcuityBaseController from './acuity_base_controller'
import * as Turbo from '@hotwired/turbo'

// Class to add the page parameter to the search params of the URL when a pagination link is clicked
export default class extends AcuityBaseController {
  static targets = []

  // Bind a pagination listener even to the correct method.
  initialize () {
    this.paginationListenerEvent = this.updateHistoryHandler.bind(this)
  }

  // When the pagination section connects we add a listener to each a tag which allows us to
  // update the URI of the page with the page parameter
  connect () {
    const _this = this
    super.connect()
    const listenersList = []

    this.element.querySelectorAll('a').forEach(function (element) {
      const listener = element.addEventListener('click', _this.paginationListenerEvent)
      listenersList.push(listener)
    })

    _this.listeners = listenersList
  }

  // Some even listeners could stick around since the pagination results are loaded via turbo
  // So we remove them as the pagniation section disconnects.
  disconnect () {
    const _this = this
    super.disconnect()

    this.element.querySelectorAll('a').forEach(
      element => element.removeEventListener('click', _this.paginationListenerEvent)
    )
  }

  /**
   * Method to handle the update history event
   * @param {Event} event the pagination link that was clicked on
   */
  updateHistoryHandler (event) {
    const baseUrl = window.location.origin
    const path = window.location.pathname
    const pageUrl = baseUrl + path

    const paginationUrlString = event.currentTarget.href

    this.updateHistoryWithPage(paginationUrlString, pageUrl)
  }

  /**
   * Method to modify the search params of the current page to include the page argument
   * @param {String} paginationUrlString the pagination link that was clicked on
   * @param {String} pageUrl the current page link
   */
  updateHistoryWithPage (paginationUrlString, pageUrl) {
    const paginationUrl = new URL(paginationUrlString)
    const newUrl = new URL(pageUrl)

    newUrl.search = paginationUrl.searchParams

    Turbo.navigator.history.push(newUrl)
  }
}
