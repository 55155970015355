import BaseController from './base_controller'
import { fire } from 'lib/compatibility'

// Used to sync values for multiple dropdowns
export default class DropdownSyncController extends BaseController {
  static targets = ['dropdown']

  /**
   * Update all dropdown targets to the updated value
   * @param event {JsEvent}
   */
  syncDropdowns (event) {
    const updatedValue = event.target.value
    const outdatedDropdowns = this.dropdownTargets.filter(dropdown => dropdown.value !== updatedValue)

    outdatedDropdowns.forEach(function (dropdown) {
      dropdown.value = updatedValue
      fire(dropdown, 'change')
    })
  }
}
