/**
 * @param {Array<Object>} nodes
 * @returns {Integer} highest level in array
 */
export function getMaxLevel (nodes) {
  return Math.max(...nodes.map(d => d.level))
}

/**
 * @param {Array<Object>} nodes
 * @param {Integer} heightMultiplier - vertical spacing for each node
 * @returns height of svg based on sankey level with the most nodes
 *
 * Example:
 * - number of `product_line` nodes = 5
 * - heightMultiplier = 100
 * - getSvgHeight (nodes, 100) = 500px
 *
 * NOTE: It's possible for there to be more RBRs in a level than more PLs in the whole diagram
 */
export function getSvgHeight (nodes, heightMultiplier) {
  // count the number of nodes per level
  const result = nodes.reduce((hash, node) => {
    const key = node.level
    if (hash[key] === undefined) {
      hash[key] = 0
    }
    hash[key] += 1
    return hash
  }, {})

  // find the largest number of nodes based on the level
  const numMaxNodesPerLevel = Math.max(...Object.values(result))
  return numMaxNodesPerLevel * heightMultiplier
}

/**
 * Modified from here: https://stackoverflow.com/a/68276389
 * @param {Array<String>} wordArray - array of Strings to group together
 * @param {Integer} maxWordLength - max length for string per array element
 * @returns {Array<String>} - strings grouped together
 *
 * Example:
 * wordArray = ['apple', 'bird', 'cat', 'dog']
 *                (5)      (4)    (3)    (3)   <-- length per word
 * maxWordLength = 8
 * in the final array, we want to make sure each joined word is NOT longer than 8 characters
 *
 * chunkedArray(wordArray, 8)
 * = ['apple', 'bird cat', 'dog']
 *     (5)        (8)       (3) <-- length per word
 *
 * - 'apple' cannot join with 'bird' --> total length = 5 + 4 + 1 (space) = 10
 * - 'bird' can join with 'cat' --> total length = 4 + 3 + 1 (space) = 8
 * - 'bird cat' cannot join with 'dog' --> total length = 8 + 3 + 1 (space) = 12
 */
export function chunkWordStrings (wordArray, maxWordLength) {
  return wordArray.reduce((chunkedArray, word, index) => {
    if (!index || chunkedArray[chunkedArray.length - 1].length + word.length >= maxWordLength) {
      chunkedArray.push(word)
    } else {
      chunkedArray[chunkedArray.length - 1] += ' ' + word
    }
    return chunkedArray
  }, [])
}

/**
 * @param {String} word - word to split into lines
 * @param {Integer} maxWordLength - max length for string per array element
 * @param {Integer} maxNumLines - max number of lines to render on top of the node
 * @returns {Array<String>} - each element in the array represents 1 line of text to render
 *
 * Example:
 * word = 'apple bird cat dog'
 * maxWordLength = 6 (each array string should not exceed 6 characters)
 * maxNumLines = 2 (should only have enough to render 2 lines of text)

 * splitWordsByMaxNumLines(word, 8, 2) = ['apple', 'bird cat...']
 * - Since 'dog' is not used in the final array, append a '...' in the last line
 */
export function splitWordsByMaxNumLines (word, maxWordLength, maxNumLines) {
  let wordArray = [word]
  if (word.length > maxWordLength) {
    // 1. split the word by spaces
    // 2. join words together unless the (total length exceeds the maxWordLength)
    //    --> each string represents 1 line of text
    wordArray = chunkWordStrings(word.split(' '), maxWordLength)
    const totalNumLines = wordArray.length

    // 3. get enough text for the specified number of lines
    wordArray = wordArray.splice(0, maxNumLines)
    const wordWithEllipsisLength = maxWordLength - '...'.length

    // 4. If some of the text was previously cut off, append "..." to signify the cutoff
    if (totalNumLines > maxNumLines && wordArray[wordArray.length - 1].length < wordWithEllipsisLength) {
      // if final line is short enough, append the '...'
      wordArray[wordArray.length - 1] += '...'
    } else if (wordArray[wordArray.length - 1].length > wordWithEllipsisLength) {
    // if the final line to render is too long, replace the last 3 characters with '...'
      wordArray[wordArray.length - 1] = wordArray[wordArray.length - 1].substring(0, wordWithEllipsisLength) + '...'
    }
  }
  return wordArray
}

/**
 * @param {Array<Object>} nodes
 * @returns {Object} An object with:
 * - key: node id
 * - value: Raw node value (before it's modified by the `sankey(data)` call)
 */
export function trackRawValues (nodes) {
  return nodes.reduce(function (map, node) {
    map[node.id] = node.value
    return map
  }, {})
}

/**
 * @param {String} text - tooltip text to format
 * @param {Integer} maxWordLength - max length for word
 * @returns {String} formatted text to account for weird word break from slashes
 */
export function textSplitForTooltip (text, maxWordLength) {
  const words = text.split(' ')
  const formattedWords = []
  words.forEach(function (word) {
    const numSlashes = word.split('').filter((c) => { return c === '/' }).length

    // run into weird case where there are multiple "/", which messes up the word break
    if (numSlashes > 1 || word.length > maxWordLength) {
      // Jest doesn't support calling replaceAll(), so this is the closest alternative
      word = word.split('/').join('/ ')
    }
    formattedWords.push(word)
  })

  return formattedWords.join(' ')
}
