import BaseController from './base_controller'

export default class RiskReturnsStateController extends BaseController {
  static targets = ['defaultSortRadio']
  static defaultSortColumn = 'benchmark_selection_name'
  static defaultSortDirection = 'default'

  static metricsRequiringBenchmark = ['alpha', 'beta']

  static selectedMetricsKey = 'risk_returns_configuration[selected_metrics]'
  static sortColumnKey = 'risk_returns_configuration[sort_column]'
  static sortDirectionKey = 'risk_returns_configuration[sort_direction]'
  static primaryBenchmarkKey = 'risk_returns_configuration[benchmark_selection_id]'

  validateOrResetState (event) {
    if (event.detail.controllerName !== 'riskReturns') {
      return
    }

    const state = event.detail.state
    if (this.hasMissingMetric(state) || this.hasMissingBenchmark(state)) {
      this.resetSorting()
    }
  }

  // Case 1: Sorting by a metric that isn't selected.
  hasMissingMetric (state) {
    return !state[RiskReturnsStateController.selectedMetricsKey]
      .includes(state[RiskReturnsStateController.sortColumnKey])
  }

  // Case 2: Sorting by a metric that requires a primary benchmark without the benchmark.
  hasMissingBenchmark (state) {
    const benchmarkDependentSort = RiskReturnsStateController.metricsRequiringBenchmark
      .includes(state[RiskReturnsStateController.sortColumnKey])
    const missingBenchmark = state[RiskReturnsStateController.primaryBenchmarkKey] === ''

    return benchmarkDependentSort && missingBenchmark
  }

  resetSorting () {
    // Since this is a radio button and the other have the same name they all
    // get deselected automatically.
    this.defaultSortRadioTarget.checked = true
    Cog.stateControllers.riskReturns.updateValues(this.defaultHash())
  }

  defaultHash () {
    const h = {}
    h[RiskReturnsStateController.sortColumnKey] = RiskReturnsStateController.defaultSortColumn
    h[RiskReturnsStateController.sortDirectionKey] = RiskReturnsStateController.defaultSortDirection
    return h
  }
}
