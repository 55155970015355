import BaseController from './acuity_base_controller'

// When connected initiate download of file at url
export default class extends BaseController {
  static values = {
    url: String
  }

  connect () {
    const link = document.createElement('a')
    link.href = this.urlValue
    document.body.appendChild(link)
    link.click()
    link.remove()
  }
}
