import BaseController from './base_controller'

// This class toggles 'disabled' attribute on radio control's target fields
export default class extends BaseController {
  // place controller on the parent of the radio button
  // place 'radioControl' on the radio button
  // place 'field' on the desired DOM element that you want to change its 'disabled' state
  static targets = ['radioControl', 'field']
  static classes = ['disabled']

  toggleFieldEnableDisableState () {
    if (this.radioControlTarget.checked) {
      this.enableAllFields()
    } else {
      this.disableAllFields()
    }
  }

  // Enable select/input fields
  enableAllFields () {
    this.fieldTargets.forEach(field => {
      field.removeAttribute('disabled')
      field.classList.remove(this.disabledClass)
    })
  }

  // Disable select/input fields
  disableAllFields () {
    this.fieldTargets.forEach(field => {
      field.setAttribute('disabled', '')
      field.classList.add(this.disabledClass)
    })

    // Dispatch event to let listeners know one or more fields have been disabled.
    this.dispatch('fields-disabled')
  }
}
