const actions = {}

// Handles streams in the form of:
// <turbo-stream action="refreshUnloadedState" state-controller="riskExposure" unloaded-key="123">
actions.refreshUnloadedState = (streamElement, fallbackToDefaultActions) => {
  const stateController = streamElement.getAttribute('state-controller')
  const unloadedKey = streamElement.getAttribute('unloaded-key')
  const controller = global.Cog.stateControllers[stateController]
  if (controller.isUnloaded(unloadedKey)) {
    const url = controller.untrackUnloaded(unloadedKey)
    global.$.get(url)
  }
}

addEventListener('turbo:before-stream-render', (event) => {
  const fallbackToDefaultActions = event.detail.render

  event.detail.render = (streamElement) => {
    if (actions[streamElement.action]) {
      actions[streamElement.action](streamElement, fallbackToDefaultActions)
    } else {
      fallbackToDefaultActions(streamElement)
    }
  }
})
