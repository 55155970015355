import BaseController from './base_controller'
import { fire } from 'lib/compatibility'

// Use this class to toggle an element's state based on another element
export default class extends BaseController {
  static values = { formSelector: String }

  submitForm () {
    const form = document.querySelector(this.formSelectorValue)
    fire(form, 'submit')
  }
}
