import { setOptions } from '../utilities/sankey_utilites'
import { isMobile, isTabletScreen } from '../utilities/mobile_utilities'

/**
 * @param {String} selector  - div to hold the sankey
 * @param {Object} sankeyData - JSON data for rendering the sankey
 * @param {Object} sankeyOptions - custom options for styling the sankey
 */
function SectorSankey (selector, sankeyData, sankeyOptions) {
  /*
    Terminology Notes:

    Source Nodes: company buttons on the left side
                  will be node_type = "company"

    Target Nodes: tables on the right side, includes the node type:
    - "header":   table header (fake node with no links)
                  so sankey will automatically render for us instead
                  of manually appending to the SVG later

    - "sub-category": nodes with actual links

    - "spacer:"  create space between tables (fake node with no links)
  */

  // d3 has not been installed through NPM, so eslint will warn that d3 not defined
  const container = d3.select(selector) // eslint-disable-line no-undef
  const data = sankeyData

  const defaultOptions = {
    width: 800,
    minWidth: 270, // min width for overall visual, any smaller and the connectors aren't clear
    height: 1081,
    colors: {
      gics_sub_industry: '#1395BA',
      resource: '#F08D1F',
      distribution: '#0A3959',
      technology_thematic: '#C3277C',
      other_thematic: '#587858',
      company: '#0A3959',
      white: '#FFFFFF'
    },
    highlightColors: {
      gics_sub_industry: '#ECF4F8',
      resource: '#FBF3EA',
      distribution: '#E8EAEE',
      technology_thematic: '#F5EAF1',
      other_thematic: '#EFF1ED'
    },
    sankey: {
      // General Sankey Settings and Styling
      widthOffset: 42, // difference between svg width and sankey width
      height: 1081,
      width: 735,
      minWidth: 270,
      nodePadding: 23,
      nodePaddingMin: 35, // min node padding, higher the value, thinner it is
      nodeStrokeWidth: 2,
      smallFontSize: '10px',
      defaultFontSize: '14px',
      regularFontWeight: 500,
      boldFontWeight: 600,
      mobileFontWeight: 700,
      strokeWidth: 14,
      strokeWidthMin: 7,

      // Source: company nodes
      sourceNodeWidth: 100,
      sourceNodeWidthMin: 65,
      sourceNodeHeight: 40,
      sourceTextOffsetX: 50,
      sourceTextOffsetXMin: 32,
      sourceTextOffsetY: 25,
      sourceTextOffsetMinY: 23,

      // Target: table nodes
      targetNodeWidth: 230,
      targetNodeWidthMin: 140,
      targetNodeHeight: 40,
      targetTextOffsetX: 115,
      targetTextOffsetXMin: 70,
      targetTextOffsetY: 25, // for nodes with 1 line of text
      targetTextOffsetY1: 17, // text offset for 2 lined text line 1
      targetTextOffsetY2: 33, // text offset for 2 lined text line 2
      targetTextOffsetMinY2: 30
    }
  }

  // Merge default options and options
  let options = setOptions(defaultOptions, sankeyOptions)

  let svg
  let sankey
  let nodes
  let graph

  /** intializes some of the sankey elements and starts drawing */
  (function initialize () {
    svg = container.append('svg')
      .attr('width', options.width) // roughly the width of a section at app_minWidth (excluding padding and margin)
      .attr('height', options.height)
      .attr('class', 'block center')
      .attr('id', 'sector_sankey_svg')

    sankey = d3.sankey() // eslint-disable-line no-undef
      .nodeWidth(options.sankey.sourceNodeWidth)
      .nodePadding(options.sankey.nodePadding)
      .size([options.sankey.width, options.sankey.height])

    handleResize()
    drawSankey()
  })()

  function drawSankey () {
    graph = sankey(data)

    // adjust data to adjust node and link positioning
    adjustNodePositions(data.nodes, true)
    adjustLinkEndpoints(data.links)

    // render actual links, nodes, and text
    drawLinks()
    drawNodes()
    addNodeBoxes()
    addNodeText()

    // add listeners to company nodes
    const companyNodes = nodesByType(nodes, ['company'])
    addNodesClickEvents(companyNodes, 'company')
    addNodesHoverEvents(companyNodes, 'company')

    const tableNodes = nodesByType(nodes, ['sub-category'])
    addNodesClickEvents(tableNodes, 'sub-category')
    addNodesHoverEvents(tableNodes, 'sub-category')

    // resize after initial load
    resizeSankey()
  }

  /**
   * Set values for endpoints to adjust positioning
   * @param {Array<Object>} linkData sankey link data
   */
  function adjustLinkEndpoints (linkData) {
    linkData.forEach(function (link) {
      link.y0 = link.source.y0 + (options.sankey.sourceNodeHeight / 2)
      link.y1 = link.target.y0 + (options.sankey.sourceNodeHeight / 2)
    })
  }

  /**
   * Set values for nodes to adjust positioning
   * @param {Array<Object>} nodeData sankey nodes data
   * @param {Boolean} initialLoad
   */
  function adjustNodePositions (nodeData, initialLoad) {
    const numCompanies = nodesByType(nodeData, ['company']).length
    nodeData.filter((d) => { return d.type === 'company' }).forEach(function (node, index) {
      // scoot to right so border doesn't get cut off
      if (initialLoad) { node.x0 += options.sankey.nodeStrokeWidth }
      node.x1 = options.sankey.sourceNodeWidth
      node.y0 = options.height * (index + 1) / (numCompanies + 1)
    })

    const nonCompanyNodes = nodeData.filter((d) => { return d.type !== 'company' })
    nonCompanyNodes.forEach(function (node, index) {
      node.x0 = options.width - scaleBySankeyWidth(options.sankey.width, 'targetNodeWidth') - options.sankey.nodeStrokeWidth
      node.y0 = (options.height - 1) * index / nonCompanyNodes.length
    })
  }

  /**
   * Render links between nodes
   */
  function drawLinks () {
    svg.append('g')
      .attr('class', 'links')
      .selectAll('.link')
      .data(graph.links)
      .enter()
      .append('path')
      .attr('class', 'link')
      .attr('d', d3.sankeyLinkHorizontal()) // eslint-disable-line no-undef
      .attr('data-category', function (d) { return d.target.category })
      .attr('data-source', function (d) { return d.source.node })
      .attr('data-target', function (d) { return d.target.node })
      .attr('stroke-width', options.sankey.strokeWidth)
  }

  /**
   * Render g for draw nodes
   */
  function drawNodes () {
    nodes = svg.append('g')
      .attr('class', 'nodes')
      .selectAll('.node')
      .data(graph.nodes)
      .enter().append('g')
      .attr('class', function (d) { return 'node ' + d.type + '-node' })
      .attr('data-node-id', function (d) { return d.node })
      .attr('data-node-type', function (d) { return d.type })
      .attr('data-category', function (d) { return d.category })
      .attr('transform', function (d) { return translateAttr(d.x0, d.y0) })
  }

  /**
   * Add boxes to the nodes
   */
  function addNodeBoxes () {
    // add text box labels for companies
    nodes.append('rect')
      .attr('height', function (d) {
        if (d.type === 'company') {
          return options.sankey.sourceNodeHeight
        }
        return options.sankey.targetNodeHeight
      })
      .attr('width', function (d) {
        if (d.type === 'company') {
          return options.sankey.sourceNodeWidth
        }
        return options.sankey.targetNodeWidth
      })
      .attr('fill', function (d) { return fillColor(d) })
      .attr('stroke', function (d) { return colors(d) })
      .attr('stroke-width', options.sankey.nodeStrokeWidth)
  }

  /**
   * Add text to the nodes
   */
  function addNodeText () {
    // Add first line of node text
    nodes.append('text')
      .text(function (d) {
        if (d.type === 'company') { return d.name[0].toUpperCase() }
        return d.name[0]
      })
      .attr('transform', function (d) {
        if (d.type === 'company') {
          return translateAttr(options.sankey.sourceTextOffsetX, options.sankey.sourceTextOffsetY)
        } else if (d.type === 'header' ||
        (d.type === 'sub-category' && d.name.length === 1)) {
          return translateAttr(options.sankey.targetTextOffsetX, options.sankey.targetTextOffsetY)
        } else {
          return translateAttr(options.sankey.targetTextOffsetX, options.sankey.targetTextOffsetY1)
        }
      })

    // Add text second line of text on nodes
    nodes.filter(function (d) { return (d.type === 'sub-category' && d.name.length > 1) })
      .append('text')
      .text(function (d) { return d.name[1] })
      .classed('second-line-text', true)
      .attr('transform', translateAttr(options.sankey.targetTextOffsetX, options.sankey.targetTextOffsetY2))

    // Adjust styling
    adjustNodeTextStyling(nodes, true)
  }

  /**
   * Adjust text placement for table nodes so it centers when resizing
   * @param {D3Selection} nodes
   */
  function adjustTargetNodeTextPlacement (nodes) {
    nodes.selectAll('text').attr('transform', function (d) {
      if (['sub-category', 'header'].includes(d.type)) {
        // transform = 'translate(X, Y)'
        // get the string => 'Y)'
        const currYTranslate = d3.select(this) // eslint-disable-line no-undef
          .attr('transform')
          .split(', ', 2)[1]
          .replace(')', '')

        return translateAttr(options.sankey.targetTextOffsetX, currYTranslate)
      } else if (d.type === 'company') {
        return translateAttr(options.sankey.sourceTextOffsetX, options.sankey.sourceTextOffsetY)
      }
    })

    // adjust spacing between first line and second line text
    const yOffset = isTabletScreen() ? options.sankey.targetTextOffsetMinY2 : options.sankey.targetTextOffsetY2
    d3.selectAll('.second-line-text').attr('transform', translateAttr(options.sankey.targetTextOffsetX, yOffset)) // eslint-disable-line no-undef
  }

  /**
   * Adjust font stying for node text
   * @param {D3Selection} nodes
   * @param {Boolean} isInitialLoad  first time drawing sankey, to prevent calling code when resize
   */
  function adjustNodeTextStyling (nodes, isInitialLoad) {
    // Add styling to all the text elements
    nodes.selectAll('text')
      .attr('font-weight', function (d) {
        if (isTabletScreen()) {
          return options.sankey.mobileFontWeight
        } else if (d.type === 'company' || d.type === 'header') {
          return options.sankey.boldFontWeight
        }
        return options.sankey.regularFontWeight
      })
      .style('text-anchor', 'middle')
      .attr('font-size', function (d) {
        if (isTabletScreen()) {
          return options.sankey.smallFontSize
        }
        return options.sankey.defaultFontSize
      })

    if (isInitialLoad) { // set default font colors for initial load
      nodes.selectAll('text').attr('fill', function (d) {
        return textColor(d)
      })
    }
  }

  /**
   * @param {Object} node
   * @returns {String} corresponding hex color
   */
  function colors (node) {
    switch (node.type) {
    case 'company':
      return options.colors[node.type]
    case 'sub-category':
    case 'header':
      return options.colors[node.category]
    default:
      return ''
    }
  }

  /**
   * @param {Object} node
   * @returns {String} corresponding hex color for text
   */
  function textColor (node) {
    if (node.type === 'header') {
      return options.colors.white
    }
    return colors(node)
  }

  /**
   * @param {Object} node
   * @returns {String} corresponding hex color for fill
   */
  function fillColor (node) {
    switch (node.type) {
    case 'spacer':
      return 'none'
    case 'header':
      return options.colors[node.category]
    default:
      return options.colors.white
    }
  }

  /**
   * listener for resize sankey and paths
   */
  function handleResize () {
    d3.select(window).on('resize', resizeSankey) // eslint-disable-line no-undef
  }

  /**
   * resize svg container
   */
  function resizeSvg () {
    svg.attr('width', options.width).attr('height', options.height)
  }

  /**
   * Resize sankey based on updated values
   */
  function resizeSankey () {
    const updateWidth = calculateWidthsBySelectorSize()
    const updatedOptions = setUpdatedOptions(updateWidth)

    if (updatedOptions.width !== options.width) {
      // update options and SVG
      options = setOptions(options, updatedOptions)
      resizeSvg()

      // update data
      sankey = d3.sankey() // eslint-disable-line no-undef
        .nodePadding(options.sankey.nodePadding)
        .size([options.sankey.width, options.sankey.height])

      graph = sankey.nodes(data.nodes).links(data.links)
      adjustNodePositions(data.nodes, false)
      adjustLinkEndpoints(data.links)

      // update node position
      svg.selectAll('.node')
        .attr('transform', function (d) {
          const xOffsetMultiplier = d.type === 'company' ? 1 : -1
          return translateAttr(d.x0 + (options.sankey.nodeStrokeWidth * xOffsetMultiplier), d.y0)
        })
        .selectAll('rect')
        .attr('width', function (d) {
          if (d.type === 'company') {
            return options.sankey.sourceNodeWidth
          } else {
            return scaleBySankeyWidth(options.sankey.width, 'targetNodeWidth')
          }
        })

      // update link paths
      svg.selectAll('.link')
        .attr('d', d3.sankeyLinkHorizontal()) // eslint-disable-line no-undef
        .style('stroke-width', function (d) { return options.sankey.strokeWidth })

      // adjust node text size and placement
      adjustNodeTextStyling(nodes, false)
      adjustTargetNodeTextPlacement(nodes)
    }
  }

  /**
   * calculate sector sankey width by window size
   * @returns {Object}  new options for SectorSankey visual
   */
  function calculateWidthsBySelectorSize () {
    // get window width, 30 is small offset
    const selectorWidth = document.querySelector(selector).clientWidth

    // calculate the new width based on window size
    let updateWidth = Math.min(defaultOptions.width, selectorWidth)
    updateWidth = Math.max(updateWidth, defaultOptions.minWidth)

    return updateWidth
  }

  /**
   * Create object with new update values for sankey.width and nodePadding
   * @param {Number} updateWidth
   * @returns {Object} updated options values scaled with new width
   */
  function setUpdatedOptions (updateWidth) {
    // set up options object for update
    const updateOptions = {
      width: updateWidth,
      sankey: {
        width: updateWidth - options.sankey.widthOffset,
        nodePadding: scaleBySankeyWidth(updateWidth, 'nodePadding'),
        targetNodeWidth: scaleBySankeyWidth(updateWidth, 'targetNodeWidth'),
        targetTextOffsetX: scaleBySankeyWidth(updateWidth, 'targetTextOffsetX'),
        sourceNodeWidth: scaleBySankeyWidth(updateWidth, 'sourceNodeWidth'),
        sourceTextOffsetX: scaleBySankeyWidth(updateWidth, 'sourceTextOffsetX'),
        sourceTextOffsetY: isTabletScreen() ? options.sankey.sourceTextOffsetMinY : options.sankey.sourceTextOffsetY,
        strokeWidth: isTabletScreen() ? defaultOptions.sankey.strokeWidthMin : defaultOptions.sankey.strokeWidth
      }
    }

    return updateOptions
  }

  /**
   * Method for all the different scales
   * @param {Number} updateWidth current sankey svg width
   * @param {String} type what scale range to use
   * @returns {Number} scaled value based on corresponding scale
   */
  function scaleBySankeyWidth (updateWidth, type) {
    const range = [defaultOptions.sankey[`${type}Min`], defaultOptions.sankey[type]]
    if (range[0] === undefined || range[1] === undefined) { return }

    // calculate newly scaled value
    const d3ScaleBySankeyWidth = d3.scaleLinear() // eslint-disable-line no-undef
      .domain([defaultOptions.sankey.minWidth, defaultOptions.sankey.width])
      .range(range)
    return d3ScaleBySankeyWidth(updateWidth)
  }

  /**
   * Add listeners for click events on company and table nodes
   * @param {Object} nodes
   * @param {String} nodeType 'company', 'sub-category'
   */
  function addNodesClickEvents (nodes, nodeType) {
    nodes.on('click', function (d) {
      const node = d3.select(this) // eslint-disable-line no-undef
      const isSelected = node.attr('class').includes('selected')
      const nodeId = d.node

      resetAndToggleNodeLinkStyles(node, nodeId, nodeType, isSelected)
    })
  }

  /**
   * Add listeners for hover events on company nodes and table nodes
   * @param {Object} nodes
   * @param {String} nodeType 'company', 'sub-category'
   */
  function addNodesHoverEvents (nodes, nodeType) {
    // only attach hover events for non-mobile device since this listener
    // interferes with click events on mobile
    if (!isMobile()) {
      nodes.on('mouseenter', function (d) {
        const node = d3.select(this) // eslint-disable-line no-undef
        const nodeId = d.node
        resetAndToggleNodeLinkStyles(node, nodeId, nodeType, false)
      }).on('mouseleave', function (d) {
        const node = d3.select(this) // eslint-disable-line no-undef
        const nodeId = d.node
        resetAndToggleNodeLinkStyles(node, nodeId, nodeType, true)
      })
    }
  }

  /**
   * toggle styling for node based on selection state
   * @param {D3Selection} node
   * @param {Number} nodeId
   * @param {String} nodeType 'company' or 'sub-category'(table) node
   * @param {Boolean} isSelected current selection state
   */
  function resetAndToggleNodeLinkStyles (node, nodeId, nodeType, isSelected) {
    // get related links
    let linkSelector = `[data-source="${nodeId}"]`
    if (nodeType === 'sub-category') {
      linkSelector = `[data-target="${nodeId}"]`
    }
    const allLinks = svg.selectAll('.link')
    const links = allLinks.filter(linkSelector)
    const otherLinks = allLinks.filter(function (d) {
      const companyNodeCondition = d.source.node !== nodeId && nodeType === 'company'
      const tableNodeCondition = d.target.node !== nodeId && nodeType === 'sub-category'

      return companyNodeCondition || tableNodeCondition
    })
    resetNodeStyling(allLinks)

    // update node and link based on current selection state
    node.classed('selected', !isSelected)
    links.classed('selected', !isSelected)
    otherLinks.classed('not-selected', !isSelected)

    toggleSelectedNodeStyling(node, nodeType, !isSelected)
    toggleNodeStylesFromLinks(links, nodeType, !isSelected)
  }

  /**
   * Reset all sankey chart links and nodes to the default unselected state
   * @param {D3Selection} allLinks all the links on the sankey chart
   */
  function resetNodeStyling (allLinks) {
    const allNodes = nodesByType(d3.selectAll('.node'), ['company', 'sub-category']) // eslint-disable-line no-undef

    // reset node and link styling
    allLinks.classed('selected', false)
    allLinks.classed('not-selected', false)
    allNodes.classed('selected', false)
    allNodes.selectAll('rect').attr('fill', options.colors.white)

    // reset text color for company nodes
    nodesByType(allNodes, ['company']).selectAll('text').attr('fill', options.colors.company)
  }

  /**
   * Highlight target node styles based on currently selected links
   * @param {D3Selection} links
   * @param {String} nodeType 'company' or 'sub-category'
   * @param {Boolean} isSelected company node is selected
   */
  function toggleNodeStylesFromLinks (links, nodeType, isSelected) {
    let fill = options.colors.white
    const dataSelector = nodeType === 'company' ? 'target' : 'source'

    links._groups[0].forEach(function (currLink) {
      const nodeId = d3.select(currLink).attr('data-' + dataSelector) // eslint-disable-line no-undef
      const node = d3.select(`[data-node-id="${nodeId}"]`) // eslint-disable-line no-undef
      const nodeRect = node.selectAll('rect')

      if (isSelected && nodeType === 'company') {
        const nodeCategory = node.attr('data-category')
        fill = options.highlightColors[nodeCategory]
      } else if (isSelected && nodeType === 'sub-category') {
        fill = options.colors.company
        node.selectAll('text').attr('fill', options.colors.white)
      } else if (!isSelected && nodeType === 'sub-category') {
        node.selectAll('text').attr('fill', options.colors.company)
      }
      nodeRect.attr('fill', fill)
    })
  }

  /**
   * toggle styling for node based on selection state
   * @param {D3Selection} node
   * @param {String} nodeType 'company' or 'sub-category'
   * @param {Boolean} isSelected current selection state
   */
  function toggleSelectedNodeStyling (node, nodeType, isSelected) {
    // edit background styling
    node.select('rect')
      .attr('fill', function () {
        if (nodeType === 'company' && isSelected) {
          return options.colors.company
        } else if (nodeType === 'sub-category' && isSelected) {
          return options.highlightColors[node.attr('data-category')]
        } else {
          return options.colors.white
        }
      })

    // edit text color for company nodes
    if (nodeType === 'company') {
      node.select('text')
        .attr('fill', function () {
          return isSelected ? options.colors.white : options.colors.company
        })
    }
  }

  /**
   * @param {Array<Object>} nodes
   * @param {Array<String} nodeTypes to filter nodes by
   * @returns {Array<Object>} filtered node data
   */
  function nodesByType (nodes, nodeTypes) {
    return nodes.filter(function (d) { return nodeTypes.includes(d.type) })
  }

  /**
   * helper for transform translate(x,y) string
   * @param {Number} x - x coordinate
   * @param {Number} y - y coordinate
   * @returns {String} formatted translate string for transform
   */
  function translateAttr (x, y) {
    return 'translate(' + x + ', ' + y + ')'
  }
}

global.Cog.sectorSankey ||= SectorSankey
