import AcuityBaseController from './acuity_base_controller'

// Class to handle showing a tooltip should be shown for text that may be tuncated
export default class extends AcuityBaseController {
  static targets = ['text', 'trigger', 'tooltip']

  /**
   * Display the full text tooltip if the text is truncated
   */
  displayTruncatedText () {
    // Add a buffer to the truncate detection logic. Sometimes IE will be off by a pixel or two
    // https://stackoverflow.com/questions/7738117/html-text-overflow-ellipsis-detection
    const tolerance = 2
    const offsetWidth = this.textTarget.offsetWidth + tolerance
    const scrollWidth = this.textTarget.scrollWidth

    // If offset width is less than scrollWidth, the text is truncated
    if (offsetWidth < scrollWidth) {
      const instance = this.triggerTarget._tippy
      instance.show()
    }
  }

  /**
   * Hide the full text tooltip. This finds the tippy associated with the tooltip target
   */
  hideTruncatedText () {
    const instance = this.tooltipTarget.closest('[data-tippy-root]')._tippy
    instance.hide()
  }
}
