import BaseController from './base_controller'
import { fire } from 'lib/compatibility'

// Controller to handle the extra options in a form.
export default class extends BaseController {
  static targets = ['collapsible', 'extraOption', 'form', 'emptyOption']
  static classes = ['collapsed']

  change () {
    if (this.getCollapsedState()) {
      this.extraOptionTargets.forEach(option => {
        if (option.checked) {
          option.checked = false
          this.emptyOptionTarget.checked = true
        }
      })
    }

    if (this.hasFormTarget) {
      fire(this.formTarget, 'submit')
    }
  }

  // Returns current state is collapsed or not
  // @return [Boolean] state is collapsed or not
  getCollapsedState () {
    return this.collapsibleTargets.some((target) => {
      return target.classList.contains(this.collapsedClass)
    })
  }
}
