import BaseController from './acuity_base_controller'

export default class AcuityModalController extends BaseController {
  static targets = ['modal', 'modalMask']
  static classes = ['hidden', 'visible', 'visibleSlide', 'hiddenMask', 'modalOpen']
  static values = { anchorSelector: String }

  /**
   * Slide in side panel modal from the right side
   */
  slideIn () {
    this.modalTarget.classList.add(this.visibleSlideClass)
    this.modalMaskTarget.classList.add(this.visibleClass)

    const anchorTarget = document.querySelector(this.anchorSelectorValue)
    anchorTarget.classList.add(this.modalOpenClass)
  }

  /**
   * Slide out side panel modal to the right side
   * when someone presses `Escape` or clicks on the modal mask
   */
  slideOut (event) {
    const escapeEvent = event.type === 'keyup' && event.key === 'Escape'
    const clickEvent = event.type === 'click'
    const modalVisible = this.modalTarget.classList.contains(this.visibleSlideClass)

    if (modalVisible && (escapeEvent || clickEvent)) {
      this.modalTarget.classList.remove(this.visibleSlideClass)
      this.modalMaskTarget.classList.remove(this.visibleClass)
      this.modalMaskTarget.classList.add(this.hiddenMaskClass)
    }
  }

  /**
   * Seperate CSS class is used for the fade-out animation
   * This function remove the fade-out class so the rest of the page is clickable
   */
  removeMaskFade () {
    this.modalMaskTarget.classList.remove(this.hiddenMaskClass)

    const anchorTarget = document.querySelector(this.anchorSelectorValue)
    anchorTarget.classList.remove(this.modalOpenClass)
  }
}
