import BaseController from '../base_controller'

// This class swtiches peer table view with more than one layout view
export default class extends BaseController {
  // Place "widthable" on target element whose column width needs to be changed
  static targets = ['viewTrigger', 'widthable', 'hidable']
  // Set one of the width classes ("narrowWidth" or "wideWidth") to the target
  // element's initial width CSS class i.e. panel-45
  static classes = ['active', 'narrowWidth', 'wideWidth', 'hidden']

  // @param [Object] JS event object
  switchView (event) {
    const currentTrigger = event.currentTarget

    if (currentTrigger.classList.contains(this.activeClass)) return

    this.activateCurrentTarget(currentTrigger)

    // Change table layout
    this.toggleHidableTargets()
    this.toggleWidthableTargets()
  }

  // Add activeClass to current trigger and remove it from other viewTriggers
  // @param [Object] Event object that contains current target
  activateCurrentTarget (currentTrigger) {
    this.viewTriggerTargets.forEach(trigger => {
      if (trigger === currentTrigger) {
        trigger.classList.add(this.activeClass)
        return
      }

      trigger.classList.remove(this.activeClass)
    })
  }

  // toggles hiddenClass for table columns with hidableTarget
  toggleHidableTargets () {
    this.hidableTargets.forEach(hidable => {
      hidable.classList.toggle(this.hiddenClass)
    })
  }

  // toggles narrowWidthClass and wideWidthClass and for table columns
  // with widthableTarget
  toggleWidthableTargets () {
    this.widthableTargets.forEach(widthable => {
      widthable.classList.toggle(this.narrowWidthClass)
      widthable.classList.toggle(this.wideWidthClass)
    })
  }
}
