import { Controller } from '@hotwired/stimulus'
import { fire } from 'lib/compatibility/form_events'

export default class extends Controller {
  static targets = ['form']

  submit () {
    this.submitForm()
  }

  // Delegate the selectResult action to the results controller if the results element is present.
  selectResult (event) {
    if (this.searchResultsElement()) {
      this.searchResultsElement().controllers['remote-autocomplete-results'].selectResult(event)
    }
  }

  submitForm () {
    fire(this.formTarget, 'submit')
  }

  // Delegate the preventScroll action to the results controller if the results element is present.
  preventScroll (event) {
    if (this.searchResultsElement()) {
      this.searchResultsElement().controllers['remote-autocomplete-results'].preventScroll(event)
    }
  }

  searchResultsElement () {
    return document.querySelector('#search_results_container ul')
  }
}
