import BaseController from './base_controller'
import { fire } from 'lib/compatibility'

// This class submit a targetted form from outside of the form element
export default class extends BaseController {
  static values = {
    formId: String,
    fieldHasValue: { type: Boolean, default: false } // make sure we don't sumbit when no search has been done yet
  }

  submitForm () {
    const targettedForm = document.getElementById(this.formIdValue)
    targettedForm.submit()
  }

  /**
   * Only submit the form once an input has been cleared
   * (IE: clicking on the built in 'X' in the input field)
   * NOTE: clicking the 'X' will trigger a 'search' event (NOT a 'change' event)
   * Ref: https://www.freecodecamp.org/news/targeting-click-of-clear-button-x-on-input-field/
   * @param {EventObject} event
   */
  submitClearedForm (event) {
    if (this.clearedFormConditions(event)) {
      this.submitForm()
    }
  }

  /**
   * Submitting a form with a remote request
   */
  submitRemoteForm () {
    const targettedForm = document.getElementById(this.formIdValue)
    fire(targettedForm, 'submit')
  }

  /**
   * Only submit the form once an input has been cleared
   * (IE: clicking on the built in 'X' in the input field)
   * NOTE: clicking the 'X' will trigger a 'search' event (NOT a 'change' event)
   * Ref: https://www.freecodecamp.org/news/targeting-click-of-clear-button-x-on-input-field/
   * @param {EventObject} event
   */
  submitClearedRemoteForm (event) {
    if (this.clearedFormConditions(event)) {
      this.submitRemoteForm()
    }
  }

  /**
   * Conditions for submitting a "cleared" form
   * 1. the value has been emptied
   * 2. there are results on the page (ie: don't sumbit if there were no results previously)
   * @param {EventObject} event
   * #@return {Boolean}
   */
  clearedFormConditions (event) {
    return event.target.value.length === 0 && this.fieldHasValueValue
  }
}
