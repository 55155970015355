import BaseController from './base_controller'

export default class extends BaseController {
  static targets = ['submit', 'input']
  static classes = ['disabled']

  // Broadcast an event that a form is being submitted.
  submitForm (event) {
    this.dispatch('submit-form')

    // This will disable whatever event triggers this function.
    // Make sure you want to prevent before using this function
    if (this.checkFormValidity()) { return }
    // Need to prevent default if form is not valid
    event.preventDefault()
  }

  // Check each input for a 'valid' attribute that is false.
  checkFormValidity () {
    let allInputsValid = true

    this.inputTargets.forEach(input => {
      // If input is not disabled and is invalid, flag that at least one attribute is not valid
      if (!input.hasAttribute('disabled') && input.hasAttribute('valid') && input.getAttribute('valid') === 'false') {
        allInputsValid = false
      }
    })

    this.toggleSubmitDisabled(allInputsValid)
    return allInputsValid
  }

  // Toggle the submit target disabled state
  // @param enabled [Boolean] Should the target be enabled or disabled
  toggleSubmitDisabled (enabled) {
    if (enabled) {
      this.submitTarget.classList.remove(this.disabledClass)
      this.submitTarget.removeAttribute('disabled')
    } else {
      this.submitTarget.classList.add(this.disabledClass)
      this.submitTarget.setAttribute('disabled', 'disabled')
    }
  }
}
