import BaseController from './base_controller'

// This validates a group of radio buttons, adding a valid attribute to the controller container for the group of radio buttons
// and display an error message if available.

// NOTE: In order to use, you only need to add 'required' to a single radio button in a button group, and define one radio
//       button of as a 'radioButtonTarget'. This will also work if multiple buttons are marked as required or targets
//       https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/radio#validation
//       https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/radio#attr-required
//       https://stackoverflow.com/questions/8287779/how-to-use-the-required-attribute-with-a-radio-input-field
export default class extends BaseController {
  static targets = ['radioButton', 'radioButtonGroup', 'valueMissingErrorMessage']
  static classes = ['hidden']

  // Validate radio buttons
  validate () {
    this.toggleValueMissingErrorMessage(this.radioButtonTarget.validity.valid)
    this.toggleRadioButtonGroupAttribute(this.radioButtonTarget.validity.valid)
  }

  // Validate radio buttons and Dispatch an event to alert the page that an input was updated
  validateAndDispatchUpdate () {
    this.validate()

    this.dispatch('radio-updated', { detail: { valid: this.radioButtonTarget.validity.valid } })
  }

  // Toggle the error message for the radio button group
  toggleValueMissingErrorMessage (valid) {
    if (!this.valueMissingErrorMessageTarget) return

    this.valueMissingErrorMessageTarget.classList.toggle(this.hiddenClass, valid)
  }

  // toggle the 'valid' attribute for the radio button group
  toggleRadioButtonGroupAttribute (valid) {
    if (valid) {
      this.radioButtonGroupTarget.setAttribute('valid', 'true')
    } else {
      this.radioButtonGroupTarget.setAttribute('valid', 'false')
    }
  }
}
