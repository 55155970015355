import BaseController from '../base_controller'

/**
 * Controller for showing/hiding content based on if a checkbox is checked
 */
export default class extends BaseController {
  static targets = ['container', 'checkbox']
  static classes = ['showMoreRisks']

  setVisibility () {
    if (this.checkboxTarget.checked) {
      this.containerTarget.classList.add(this.showMoreRisksClass)
    } else {
      this.containerTarget.classList.remove(this.showMoreRisksClass)
    }
  }
}
