import BaseController from '../base_controller'
import { fire } from 'lib/compatibility'

export default class extends BaseController {
  static targets = ['gvkey', 'iid', 'form', 'companyName']

  selectSecurity (event) {
    // fill in hidden submission form elements with gvkey and iid from current selection
    this.gvkeyTarget.value = event.currentTarget.dataset.gvkey
    this.iidTarget.value = event.currentTarget.dataset.iid

    // fill in search field with the name of the selected company
    this.companyNameTarget.value = event.currentTarget.dataset.companyName

    // set last valid company name to new company name so can revert to the selected company name if they search for a
    // new company/security on that row but don't select a new company/security
    this.formTarget.dataset.lastValidCompanyName = this.companyNameTarget.value

    // Set valid attribute to true to hide red outline on search input
    this.companyNameTarget.setAttribute('valid', true)

    this.submitForm()
    this.hideSearchResults()
  }

  submitForm () {
    fire(this.formTarget, 'submit')
  }

  // triggers event that tells any search result dropdowns on the page to hide
  hideSearchResults () {
    const event = new CustomEvent('hide-search-results')
    window.dispatchEvent(event)
  }

  // This sets the company/security name on the search field to the last company/security the row was assigned to
  restoreName () {
    if (this.formTarget.dataset.lastValidCompanyName === undefined) {
      this.companyNameTarget.value = ''
    } else {
      this.companyNameTarget.value = this.formTarget.dataset.lastValidCompanyName
    }
  }
}
