import BaseController from '../base_controller'

/**
 * Controller for ONLY handling hover/styling on the levels button
 * Since the interaction is too complicated to handle with only CSS
 */
export default class extends BaseController {
  static targets = ['button']
  static classes = ['inactive', 'selected']
  static values = {
    selectedLevel: Number
  }

  /**
   * Update styling based on the event.target button
   * @param {Object} event - ie: mouseenter event
   */
  changeStyleByEvent (event) {
    this.changeStyleBySelectedLevel(event.target)
  }

  /**
   * Change the ACTUAL currently selected level, as displayed by the buttons
   * @param {Object} event - ie: click event
   */
  changeSelectedLevel (event) {
    // update selected level to the clicked button's level
    this.selectedLevelValue = event.target.dataset.level
    this.changeStyleBySelectedLevel(event.target)
  }

  /** reset any styling changes, so the buttons states will match the actual selected button level */
  resetStyle () {
    // reset any styling changes from the hover interaction
    const selectedButton = this.buttonTargets.find((btn) => { return parseInt(btn.dataset.level) === this.selectedLevelValue })
    this.changeStyleBySelectedLevel(selectedButton)
  }

  /**
   * Update all button styling depending on the button passed in
   * (hover/click styling changes are too complex to address with CSS alone )
   *
   * @param {*} currButton - either a button that is either:
   * 1. corresponds to currently selected level when viewing the Company sankey
   * 2. the button that was clicked on
   * 3. the button that was hovered over
   */
  changeStyleBySelectedLevel (currButton) {
    this.buttonTargets.find((btn) => { return btn.classList.contains(this.selectedClass) })
      .classList.remove(this.selectedClass)

    // currently selected level
    const selectedLevel = currButton.dataset.level

    // apply inactive styling to inactive buttons
    const inactiveButtons = this.buttonTargets.filter((btn) => { return parseInt(btn.dataset.level) > selectedLevel })
    inactiveButtons.forEach((btn) => { btn.classList.toggle(this.inactiveClass, true) })

    // apply active styling to active buttons
    const activeButtons = this.buttonTargets.filter((btn) => { return parseInt(btn.dataset.level) <= selectedLevel })
    activeButtons.forEach((btn) => { btn.classList.toggle(this.inactiveClass, false) })

    // apply selected styling to the selected button
    currButton.classList.toggle(this.selectedClass, true)
  }
}
