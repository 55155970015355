import BaseController from './base_controller'
import { currentDataFetchControllerInstance } from '../lib/portfolios/helpers'

export default class extends BaseController {
  static targets = ['activeField']
  static values = {
    name: String, // name of the controller instance
    fieldSelector: String, // filter field selector in the filter form
    tabAffected: String, // valid values: ['all', 'currnet']. This triggers whether target filter should update the dataFetchFlag for only the 'current' tab or for 'all' tabs
    activeField: String // use when active field target element cannot have value attribute (i.e. <a>)
  }

  connect () {
    super.connect()
    global.Cog.portfolioFilterControllers ||= {}
    global.Cog.portfolioFilterControllers[this.nameValue] = this
  }

  disconnect () {
    super.disconnect()
    global.Cog.portfolioFilterControllers[this.nameValue] = null
  }

  // copies value from active field target to hidden form
  change () {
    // Fill in value in the filter form
    this.updateFilterForm()
    // Update fetchDataFlag
    this.updateFetchDataFlag(this.tabAffectedValue)

    // Get the filter form controller instance
    const formCtrlInstance = Cog.portfolioFilterFormController

    // Submit the filter form if fetchDataFlag is true
    if (this.currentFetchDataFlag()) {
      // Submit the filter form
      formCtrlInstance.submitForm()
      // Mark fetchDataFlag is false
      this.updateCurrentFetchDataFlag(false)
    } else {
      // Update the browser's URL by copying the form's values,
      // since the filter form will not submit, the browser's URL is not changed.
      const url = formCtrlInstance.url()
      /* eslint-disable */
      Turbo.navigator.history.push(new URL(url))
      /* eslint-enable */
    }
  }

  // Fill in values in the filter form
  updateFilterForm () {
    const formCtrlInstance = Cog.portfolioFilterFormController
    const fieldSelector = this.fieldSelectorValue
    const value = this.activeFieldTarget.value || this.activeFieldValue

    formCtrlInstance.setFieldValue(fieldSelector, value)
    formCtrlInstance.setFormTurboFrame()
  }

  // update fetchDataFlag(s)
  updateFetchDataFlag (tabAffected) {
    currentDataFetchControllerInstance().update(tabAffected)
  }

  // update current fetchDataFlag
  updateCurrentFetchDataFlag (boolenaValue) {
    currentDataFetchControllerInstance().updateCurrent(boolenaValue)
  }

  // find current tab fetchData flag value
  currentFetchDataFlag () {
    return currentDataFetchControllerInstance().fetchDataFlagValue
  }
}
