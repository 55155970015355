import BaseController from './acuity_base_controller'
import { drawBarCharts } from '../lib/bar_chart'

export default class extends BaseController {
  static values = {
    data: String,
    containerSelector: String // Do I need this? Defines where to add the d3 drawing
  }

  connect () {
    super.connect()
    this.renderBarGraph()
  }

  renderBarGraph () {
    const data = JSON.parse(this.dataValue)
    drawBarCharts(data, this.containerSelectorValue)
  }
}
