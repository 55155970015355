import BaseController from './base_controller'

export default class RowRangeController extends BaseController {
  static targets = ['startRange', 'endRange', 'rowRange']

  connect () {
    super.connect()
    // Call calculate range when the controller is initialized so we get the range on page load
    this.calculateRange()
  }

  /**
   * Update the rows range value to be the end value minus the start value plus 1
   */
  calculateRange () {
    const startValue = this.startRangeTarget.value
    const endValue = this.endRangeTarget.value
    const rowCount = endValue - startValue + 1
    const unit = Math.abs(rowCount) === 1 ? 'ROW' : 'ROWS'

    if (startValue.length === 0 || endValue.length === 0 || rowCount < 1) {
      this.rowRangeTarget.innerHTML = null
    } else {
      this.rowRangeTarget.innerHTML = `${rowCount} ${unit}`
    }
  }
}
