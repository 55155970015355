import BaseController from './acuity_base_controller'

// Controller for a tab menu EX: companies page
export default class extends BaseController {
  static targets = ['tab', 'tabSection']
  static classes = ['activeTab', 'hiddenTabSection']

  /**
   * Change the clicked tab to active and show the corresponding section
   * @param {Event} event - the tab that was clicked on
   */
  show (event) {
    const _this = this
    const tabSectionName = event.currentTarget.dataset.acuityTabSectionName

    _this.deactivateTabs(_this)
    _this.toggleTabSections(tabSectionName, _this)

    event.currentTarget.classList.add(_this.activeTabClass)
  }

  /**
   * Either hide the inactive tab sections or reveal the active one by removing the hidden class
   * @param {String} activeTabText - The name for the corresponding tab section
   * @param {Context} _this - the context for the controller
   */
  toggleTabSections (activeTabText, _this) {
    _this.tabSectionTargets.forEach(function (tabSection) {
      if (tabSection.dataset.acuityTabSectionName === activeTabText) {
        tabSection.classList.remove(_this.hiddenTabSectionClass)
      } else {
        tabSection.classList.add(_this.hiddenTabSectionClass)
      }
    })
  }

  /**
   * Remove the active class from each tab
   * @param {Context} _this - the context for the controller
   */
  deactivateTabs (_this) {
    _this.tabTargets.forEach(tab => tab.classList.remove(_this.activeTabClass))
  }
}
