import BaseController from './acuity_base_controller'

export default class extends BaseController {
  static targets = ['spinner', 'content']
  static classes = ['hide']

  displaySpinner () {
    this.spinnerTarget.classList.remove(this.hideClass)
    this.contentTarget.classList.add(this.hideClass)
  }

  hideSpinner () {
    this.spinnerTarget.classList.add(this.hideClass)
    this.contentTarget.classList.remove(this.hideClass)
  }
}
