// We can define a global singleton object we'll call "Acuity". This is the only
// entrypoint through we can interact with JS compiled with webpacker.
// Other included JS libraries can accept calls by registering their instances in this variable. In NodeJS
// (and testing) global is just the global scope. In the browser, global is the window object. This helps keep us
// from polluting the window scope.
global.Acuity = {}

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'media/acuity/rails.png' %>)
// or the `imagePath` JavaScript helper below.
const images = require.context('./images/acuity', true)
const imagePath = (name) => images(name, true) // eslint-disable-line no-unused-vars

// Import all.scss, then import all SCSS files
require('./stylesheets/acuity/all.scss')

// Import all.js, then import all JS files
require('./javascripts/acuity/all.js')
