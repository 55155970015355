import AcuityBaseController from './acuity_base_controller'

// Define the class in this way, so the class name will follow by the filename.
export default class extends AcuityBaseController {
  static targets = ['realDropdown', 'realOption']

  connect () {
    super.connect()
    this.resizeSelectTag(true)
  }

  /**
   * return {HTMLObject} selected real option
   */
  selectedRealOption () {
    return this.realOptionTargets.find(option => option.selected)
  }

  /**
   * param {Boolean|Event} either true (for first load) or the event that trigger the resize
   * adjusts selection box to the size of its current selection
   */
  resizeSelectTag (isFirstLoad) {
    const extraSpacing = 3
    // Create invisible select tag with 1 option
    const invisibleDropdown = document.createElement('select')
    const invisibleDropdownOption = document.createElement('option')

    // Must be visibility: hidden and position: absolute, so invisible select tag width can be measurable
    invisibleDropdown.classList.add('invisible')
    invisibleDropdown.classList.add('absolute')

    // Place selected option text in invisible option tag, so we can measure invisible select tag width
    invisibleDropdownOption.textContent = this.selectedRealOption().textContent
    invisibleDropdown.appendChild(invisibleDropdownOption)

    // Append the invisiable select tag in body tag,
    // because any display: none will on container will not able to measure the invisiable select tag width
    const body = document.querySelector('body')
    body.append(invisibleDropdown)

    // Get invisble select tag width to measure selected option text width
    let width = invisibleDropdown.getBoundingClientRect().width

    // On initial load the width attribute returns slightly shortend width so we
    // add a few px to cover the margin of error.
    if (isFirstLoad === true) { width += extraSpacing }

    this.realDropdownTarget.style.width = width + 'px'

    // Remove invisible select tag
    invisibleDropdown.remove()
  }
}
