import BaseController from './acuity_base_controller'
import { fire } from '../lib/form_events'

const escapeKey = 'Escape'
const tabKey = 'Tab'

export default class AcuityMenuController extends BaseController {
  static targets = ['menu', 'menuFilter', 'activeCategory', 'menuFilterForm',
    'menuTabsForm', 'menuTab', 'menuTabSection', 'menuAndFilter',
    'filteredItems', 'filteredItem', 'tabItemCount']

  static classes = ['active', 'hidden', 'visibleCount']

  static values = {
    multiSelectType: String,
    multiSelectContainer: String,
    preSelectedOptions: Object,
    possibleCategories: { type: Array, default: [] }
  }

  initialize () {
    this.selectedOptions = this.preSelectedOptionsValue
  }

  connect () {
    super.connect()
    this.selectedOptions = this.preSelectedOptionsValue
  }

  disconnect () {
    super.disconnect()
    this.selectedOptions = {}
  }

  get multiSelectController () {
    let multiSelectController = this.application.getControllerForElementAndIdentifier(this.menuAndFilterTarget, 'acuity-multi-select')
    if (multiSelectController == null) { // for RBR and Product Line Select
      // find the parent div where the controller is set
      const multiSelectContainer = document.querySelector(this.multiSelectContainerValue)
      multiSelectController = this.application.getControllerForElementAndIdentifier(multiSelectContainer, this.multiSelectTypeValue)
    }
    return multiSelectController
  }

  // When filteredItemsTarget is connected
  // This event will fire when the tabs checkboxes are loaded. We iterate though them and check the ones that are in
  // the appropriate selected option section.
  filteredItemsTargetConnected () {
    if (!this.hasFilteredItemTarget) { return }

    if (this.hasActiveCategoryTarget) {
      // only update selection for active category (if present)
      this.updateCheckboxesByCategory(this.activeCategoryTarget.value)
    } else if (this.possibleCategoriesValue.length > 0) {
      // update selection for ALL available categories
      this.possibleCategoriesValue.forEach((category) => {
        this.updateCheckboxesByCategory(category)
      })
    }
  }

  // Update the checkbox states that matches the given category OR the all_categories section
  // @param category [String]
  updateCheckboxesByCategory (category) {
    const items = this.filteredItemTargets
    for (let i = 0; i < items.length; i++) {
      if (this.selectedOptions[category] != null) {
        if (this.selectedOptions[category].includes(items[i].getAttribute('data-acuity-menu-id-param'))) {
          items[i].checked = true
        }
      } else if (this.selectedOptions.all_categories != null) {
        if (this.selectedOptions.all_categories.includes(items[i].getAttribute('data-acuity-menu-id-param'))) {
          items[i].checked = true
        }
      }
    }
  }

  // When menuTabSection is connected
  menuTabSectionTargetConnected () {
    if (!this.hasMenuTabTarget) { return }

    const tabs = this.menuTabTargets

    const activeTab = tabs.find(target => target.classList.contains(this.activeClass))

    if (activeTab == null) { return }

    const tabId = activeTab.getAttribute('data-acuity-menu-id-param')

    this.activeCategoryTarget.value = tabId
  }

  // When tabItemCount is connected
  tabItemCountTargetConnected () {
    if (!this.hasTabItemCountTarget) { return }

    this.tabItemCountTargets.forEach(target => {
      const section = target.getAttribute('data-acuity-menu-id-param')
      this.updateTabItemCount(section)
    })
  }

  // When the item is selected or unselected we either add it to the selected options section or remove it as a string
  toggleSelected (event) {
    const section = event.params.section
    const paramsId = event.params.id.toString()

    if (this.selectedOptions[section] == null) {
      this.selectedOptions[section] = []
    }

    // The event can be of type `click` or of type `change`. The click even will always remove the target item.
    if (event.type === 'click') {
      // We have to uncheck the target box in the UI.
      const targetItem = this.filteredItemTargets.find(target => target.getAttribute('data-acuity-menu-id-param') === paramsId)

      if (targetItem != null) { targetItem.checked = false }

      // We also remove the item from the authoritative list.
      this.selectedOptions[section] = this.selectedOptions[section].filter(item => item !== paramsId)
    } else if (event.target.checked && !this.selectedOptions[section].includes(paramsId)) {
      // In the change case we need to check if the target is checked or unchecked. If checked we are adding an item.
      this.selectedOptions[section].push(paramsId)
    } else if (!event.target.checked && this.selectedOptions[section].includes(paramsId)) {
      // If the box is unchecked we are removing that item.
      this.selectedOptions[section] = this.selectedOptions[section].filter(item => item !== paramsId)
    }

    this.updateTabItemCount(section)
  }

  // Update the count of the desired tab
  // @param section [section] the section we want to update the counter
  updateTabItemCount (section) {
    const countTarget = this.tabItemCountTargets.find(target => target.getAttribute('data-acuity-menu-id-param') === section.toString())
    if (countTarget == null) { return }

    if (this.selectedOptions[section] == null) { return }
    countTarget.innerHTML = this.selectedOptions[section].length

    if (this.selectedOptions[section].length > 0) {
      countTarget.classList.remove(this.hiddenClass)
      countTarget.classList.add(this.visibleCountClass)
    } else {
      countTarget.classList.add(this.hiddenClass)
      countTarget.classList.remove(this.visibleCountClass)
    }
  }

  filterItems () {
    fire(this.menuFilterFormTarget, 'submit')
  }

  // Reveal the menu and do first time setup if necessary
  showMenu () {
    // First time setup for the menu.
    if (!this.hasMenuTabSectionTarget && this.hasMenuTabsFormTarget) {
      fire(this.menuTabsFormTarget, 'submit')
      fire(this.menuFilterFormTarget, 'submit')
    }

    this.menuTarget.classList.remove(this.hiddenClass)
  }

  // Changes the current tab in the menu
  // @param event [Event] the event we want to check
  changeTab (event) {
    this.menuFilterTarget.focus()
    // Prevent the focus event from highlighting the text.
    this.menuFilterTarget.selectionStart = this.menuFilterTarget.selectionEnd

    // Update activeCategory value
    const rbrTaxonomyValue = event.params.id.toString()
    let sync = false
    this.activeCategoryTargets.forEach(target => {
      if (target.value !== rbrTaxonomyValue) {
        target.value = rbrTaxonomyValue
        sync = true
      }
    })
    // If the target of the event is already active we don't need to do anything
    if (!sync) { return }

    // Set the tab content section to be a spinning icon indicating the tab is loading.
    this.filteredItemsTarget.innerHTML = '<div class="text-center mt-4"><i class="fas fa-spinner fa-spin hint fa-4x"></i></div>'

    for (let i = 0; i < this.menuTabSectionTarget.children.length; i++) {
      if (this.menuTabSectionTarget.children[i].classList.contains(this.activeClass)) {
        this.menuTabSectionTarget.children[i].classList.remove(this.activeClass)
      }
    }

    event.target.classList.add(this.activeClass)
    fire(this.menuFilterFormTarget, 'submit')
  }

  // @param event [Event] the event we want to check
  // @returns [Boolean] true iff the events key was the escape key
  isEscapeKey = (event) => event.key === escapeKey

  // @param event [Event] the event we want to check
  // @returns [Boolean] true iff the events key was the tab key
  isTabKey = (event) => event.key === tabKey

  // @param event [Event] the event we want to check
  // @returns [Boolean] true iff the events type was a click and the click was on one of our list results
  isClickOutOfBounds = (event) => event.type === 'click' && !this.menuAndFilterTarget.contains(event.target)

  // Close the results box if the escape key was pressed or we clicked away.
  // @param event [Event] the event we want to check
  close (event) {
    // Check if we are tabbing to the menu input and if so do not close the menu
    if (!(this.isTabKey(event) && this.menuAndFilterTarget.contains(event.target))) {
      if (this.isEscapeKey(event) || this.isClickOutOfBounds(event) || event.key === tabKey) {
        this.menuTarget.classList.add(this.hiddenClass)
      }
    }
  }
}
