// Rails UJS isn't available to the ES6 JS and adding it will conflict with the
// ES5 version.
// What if I want to submit a form? Calling form.submit() will submit the form
// directly,regardless of whether or not it was a remote form.
// Instead call fire(form, "submit") to submit the form.
//
// Pulled from @rails/ujs.
export function fire (obj, name, data) {
  const event = new CustomEvent(name, {
    bubbles: true,
    cancelable: true,
    detail: data
  })
  obj.dispatchEvent(event)
  return !event.defaultPrevented
}
