import BaseController from './base_controller'
import { debounce } from 'lodash'
import { fire } from 'lib/compatibility'

export default class extends BaseController {
  static values = { timeout: Number, default: 500 }
  static targets = ['form']

  initialize () {
    this.submitWithDelay = debounce(this.submitWithDelay, this.timeoutValue)
  }

  submitWithDelay () {
    this.submit()
  }

  submit () {
    fire(this.formTarget, 'submit')
  }
}
