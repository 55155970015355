// find current active tab instance of PortfolioTabDataFetchController
export function currentDataFetchControllerInstance () {
  global.Cog.portfolioTabDataFetchControllers ||= {}
  const dataFetchControllers = global.Cog.portfolioTabDataFetchControllers
  let controllerInstance

  for (const name in dataFetchControllers) {
    controllerInstance = dataFetchControllers[name]
    const tab = controllerInstance.fetchDataFlagTarget

    if (tab.classList.contains('active')) {
      break
    }
  }

  return controllerInstance
}
