import BaseController from './base_controller'
import { currentDataFetchControllerInstance } from '../lib/portfolios/helpers'

export default class extends BaseController {
  static targets = ['tab', 'tabResult']
  static classes = ['active']

  // show selected tab and corresponding result
  showTab (event) {
    const selectedTab = event.currentTarget
    const tabName = selectedTab.dataset.tabName
    const activeClass = this.activeClass
    const tabContentSelector = '#' + tabName

    // Display the spinner to current tab content
    this.toggleSpinner(tabContentSelector, true)

    this.tabTargets.forEach(function (tab) {
      tab.classList.remove(activeClass)
    })
    selectedTab.classList.add(activeClass)

    this.tabResultTargets.forEach(function (result) {
      if (result.dataset.tabName === tabName) {
        result.classList.add(activeClass)
        result.hidden = false
        return
      }

      result.classList.remove(activeClass)
      result.hidden = true
    })

    // current fetch data flag is false => hide the spinner to current tab content
    // current fetch data flag is true => the spinner will be hidden when the tab content is refresh
    if (!this.currentFetchDataFlag()) {
      this.toggleSpinner(tabContentSelector, false)
    }
  }

  // Display or Hide Spinner in current viewed tab
  // @param tabContentSelector [String] tab content selector
  // @param display [Bollean] display spinner or not
  toggleSpinner (tabContentSelector, display) {
    const tabContent = document.querySelector(tabContentSelector)
    const spinner = tabContent.querySelector("[data-controller='spinner']")

    if (spinner != null) {
      const spinnerCtrl = spinner.controllers.spinner

      if (display) {
        spinnerCtrl.displaySpinner()
      } else {
        spinnerCtrl.hideSpinner()
      }
    }
  }

  // Find current tab fetch data flag value
  // @return [Boolean] current tab fetch data flag
  currentFetchDataFlag () {
    let fetchDataFlagValue = false

    if (currentDataFetchControllerInstance() != null) {
      fetchDataFlagValue = currentDataFetchControllerInstance().fetchDataFlagValue
    }

    return fetchDataFlagValue
  }
}
